import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Declaration } from '../Declaration';
import { ApiService } from '../services/api.service';
import { Authentification } from '../services/authentification';

@Component({
  selector: 'app-declaration',
  templateUrl: './declaration.component.html',
  styleUrls: ['./declaration.component.css']
})
export class DeclarationComponent implements OnInit {
declarations:any[];
filterTerm:any;
  constructor(private auth: Authentification, private router: Router,public api:ApiService,private http:HttpClient) {
    this.declarations=[];
   }

  ngOnInit(): void {
    this.get_declaration()
   

}
get_declaration(){

  this.api.taf_get("declaration/get",(reponse:any)=>{
      //when success
      if(reponse.status){
        this.declarations=reponse.data
          console.log("Opération effectuée avec succés sur la table declaration. Réponse= ",reponse);
      }else{
          console.log("L\'opération sur la table declaration a échoué. Réponse= ",reponse);
      }
  },
  (error:any)=>{
      //when error
      console.log("Erreur inconnue! ",error);
  })
  }
  delete_declaration (declaration : any){
    //transformation des parametres à envoyer
    let formdata = new FormData()
    for (const key in declaration ) {
        formdata.append(key, declaration [key])
    }

    let api_url="http://localhost/gs_back_php/declaration/delete" 
    this.http.post(api_url, formdata).subscribe((reponse: any)=>{
        //when success
        if(reponse.status){
        console.log("Opération effectuée avec succés sur la table declaration . Réponse = ",reponse)
        }else{
        console.log("L\'opération sur la table declaration  a échoué. Réponse = ",reponse)
        }
    },
    (error: any)=>{
        //when error
        console.log("Erreur inconnue! ",error)
    })
    }


    
  ajouterDeclarations(){
    this.router.navigate(['./ajouterDeclaration']);
  }
  deleteDeclaration(idDeclaration:number){
    this.auth.supprimerDeclaration(idDeclaration);
    this.router.navigate(['./declaration']);
    }
    updateDeclaration(Id_Declaration:number) {
      let url = './updateDeclaration/';
      url=url+Id_Declaration;
      this.router.navigate([url]);
  }

}
