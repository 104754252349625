<!DOCTYPE html>
<html lang="en">
<head>
  <title>Bootstrap Example</title>
  <meta charset="utf-8">
  <meta name="viewport" content="width=device-width, initial-scale=1">
  <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.6.1/dist/css/bootstrap.min.css">
  <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">
  <script src="https://cdn.jsdelivr.net/npm/jquery@3.6.0/dist/jquery.slim.min.js"></script>
  <script src="https://cdn.jsdelivr.net/npm/popper.js@1.16.1/dist/umd/popper.min.js"></script>
  <script src="https://cdn.jsdelivr.net/npm/bootstrap@4.6.1/dist/js/bootstrap.bundle.min.js"></script>
</head>
<body>
  <app-navbar></app-navbar><br/><br/><br/>
  <form (ngSubmit)=" modifierCamion()"  class="sign-in-form" #CamionForm="ngForm"class="form">
    <div class="form-group">
      <label for="exampleInputIdCamion">idCamion</label>
      <input type="number" name="idCamion" disabled="true" class="form-control" id="exampleInputidCamion" placeholder="idCamion" [(ngModel)]=cam.idCamion min="1">
    </div>
    <div class="form-group">
      <label for="exampleInputNomNavire">Matriculation</label>
      <input type="text" name ="matriculation" class="form-control" id="exampleInputMatriculation" placeholder="Matriculation"[(ngModel)]=cam.matriculation min="1">
    </div>
    <div class="form-group">
      <label for="exampleInputdateArrivee">Transporteur</label>
      <input type="text" name="transporteur" class="form-control" id="exampleInputtranspoteur" placeholder="Transporteur"[(ngModel)]=cam.transporteur min="1" >
    </div>
    <div class="form-group">
      <label for="exampleInputLargeurNavire">Chauffeur</label>
      <input type="text" name ="chauffeur" class="form-control" id="exampleInputchauffeur" placeholder="Chauffeur" [(ngModel)]=cam.chauffeur min="1" >
    </div>
    <div class="form-group">
      <label for="exampleInputNumeroPermis">Numero Permis</label>
      <input type="number" name ="numeroPermis" class="form-control" id="exampleInputnumeroPermis" placeholder="Numero Permis" [(ngModel)]=cam.numeroPermis  >
    </div>
    <div class="form-group">
      <label for="exampleInputLargeurNavire">Date Permis</label>
      <input type="date" name ="datePermis" class="form-control" id="exampleInputdatePermis" placeholder="Date Permis" [(ngModel)]=cam.datePermis min="1" >
    </div>
   

    <button type="submit" class="btn btn-primary" [disabled]="!CamionForm.form.valid">modifier</button>

</form><br/><br/>
<app-footer></app-footer>
</body>
</html>
