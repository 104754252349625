import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-fiche-sortie',
  templateUrl: './fiche-sortie.component.html',
  styleUrls: ['./fiche-sortie.component.css']
})
export class FicheSortieComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
