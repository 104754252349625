<app-navbar></app-navbar>
<br/><br/><br/><br/><br/><br/>
<div class="container">
<table class="centered highlight datatable table table-striped table-bordered" style="border: 2px solid #235f83;">
  <thead>
    <tr>
        <th>Id Mvt</th>
        <th>Date</th>
        <th>Bl Mlt</th>
        <th> Id Acheteur</th>
        <th>Bl Acheteur</th>
        <th>Quantite</th>
        <th>Poids</th>
        <th>Observation</th>
    </tr>
  </thead>
  <tbody >
    <tr *ngFor="let unMvt of mvts" >
      <td>{{unMvt.IdMvt}}</td>
      <td>{{unMvt.Date}}</td>
      <td>{{unMvt.BlMlt}}</td>
      <td>{{unMvt.Id_Acheteur}}</td>
      <td>{{unMvt.BlAcheteur}}</td>
      <td>{{unMvt.Quantite}}</td>
      <td>{{unMvt.Poids}}</td>
      <td>{{unMvt.Observation}}</td>
      <button type="submit" class="btn btn-danger" style="background-color: red;margin: 8px;font-family: initial;" (click)="delete_mvtsortie(unMvt)" > Supprimer</button>
      <button type="submit" class="btn btn-primary" style="font-family: initial;" > Modifier</button>
    </tr>

  </tbody>
</table ><br/>
<a class="waves-effect waves-light btn"  [style.margin]="' 10px'" (click)="ajouterMvtSortie()" >AjouterMvtSortie</a>
<br/><br/>
</div>
<app-footer></app-footer>