import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, EmailValidator, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from '../services/api.service';
import { Authentification } from '../services/authentification';
import { User } from '../user';

@Component({
  selector: 'app-inscription',
  templateUrl: './inscription.component.html',
  styleUrls: ['./inscription.component.css']
})
export class InscriptionComponent implements OnInit {

reactiveForm_add_user !: FormGroup;
submitted:boolean=false
loading_add_user :boolean=false
constructor(private router:Router,private formBuilder: FormBuilder,public api:ApiService, private http:HttpClient) { }

ngOnInit(): void {
    this.init_form()
}
init_form() {
    this.reactiveForm_add_user  = this.formBuilder.group({
        
email: ["", Validators.required],
Prenom: ["", Validators.required],
Nom: ["", Validators.required],
password: ["", Validators.required]
    });
}

// acces facile au champs de votre formulaire
get f(): any { return this.reactiveForm_add_user .controls; }
// validation du formulaire
onSubmit_add_user () {
    this.submitted = true;
    console.log(this.reactiveForm_add_user .value)
    // stop here if form is invalid
    if (this.reactiveForm_add_user .invalid) {
        return;
    }
    var user =this.reactiveForm_add_user .value
    this.add_user (user )
}
// vider le formulaire
onReset_add_user () {
    this.submitted = false;
    this.reactiveForm_add_user .reset();
}
connexion(){
    this.router.navigate(['./connexion']);
}
add_user(user: any) {
    this.loading_add_user = true;
    this.api.taf_post("user/add", user, (reponse: any) => {
    this.loading_add_user = false;
    if (reponse.status) {
        console.log("Opération effectuée avec succés sur la table user. Réponse= ", reponse);
        this.onReset_add_user()
        alert("Inscription effectuée avec succés")
        this.router.navigate(['./connexion'])
    } else {
        console.log("L\'opération sur la table user a échoué. Réponse= ", reponse);
        alert("L'opération a echoué")
    }
}, (error: any) => {
    this.loading_add_user = false;
})
}


}
