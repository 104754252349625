import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../services/api.service';

@Component({
  selector: 'app-acheteur',
  templateUrl: './acheteur.component.html',
  styleUrls: ['./acheteur.component.css']
})
export class AcheteurComponent implements OnInit {
acheteurs:any[];
filterTerm:any;
  constructor(private http:HttpClient, private router: Router,public api:ApiService) {
    this.acheteurs=[];
   }

  ngOnInit(): void {
    this.get_acheteur()
  }
  get_acheteur(){
    this.api.taf_get("acheteur/get",(reponse:any)=>{
        //when success
        if(reponse.status){
          this.acheteurs=reponse.data
            console.log("Opération effectuée avec succés sur la table acheteur. Réponse= ",reponse);
        }else{
            console.log("L\'opération sur la table acheteur a échoué. Réponse= ",reponse);
        }
    },
    (error:any)=>{
        //when error
        console.log("Erreur inconnue! ",error);
    })
    }

  ajouterAcheteurs(){
    this.router.navigate(['./Ajouteracheteur']);
  }

  delete_acheteur (acheteur : any){
    //transformation des parametres à envoyer
    let formdata = new FormData()
    for (const key in acheteur ) {
        formdata.append(key, acheteur [key])
    }
    let api_url="http://localhost/gs_back_php/acheteur/delete" 
    this.http.post(api_url, formdata).subscribe((reponse: any)=>{
        //when success
        if(reponse.status){
        console.log("Opération effectuée avec succés sur la table acheteur . Réponse = ",reponse)
        }else{
        console.log("L\'opération sur la table acheteur  a échoué. Réponse = ",reponse)
        }
    },
    (error: any)=>{
        //when error
        console.log("Erreur inconnue! ",error)
    })
    }
    updateAcheteur(Id_Acheteur:number) {
      let url = './updateAcheteur/';
      url=url+Id_Acheteur;
      this.router.navigate([url]);
  }
}
