import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../services/api.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

@Component({
  selector: 'app-ajouter-mvt-sortie',
  templateUrl: './ajouter-mvt-sortie.component.html',
  styleUrls: ['./ajouter-mvt-sortie.component.css']
})
export class AjouterMvtSortieComponent implements OnInit {
  reactiveForm_add_mvtsortie !: FormGroup;
  submitted:boolean=false
  loading_add_mvtsortie :boolean=false
  constructor(private formBuilder: FormBuilder,public api:ApiService, private http:HttpClient,private route:Router) { }

  ngOnInit(): void {
    this.init_form()
  }
  init_form() {
      this.reactiveForm_add_mvtsortie  = this.formBuilder.group({
          
  IdMvt: ["", Validators.required],
  Date: ["", Validators.required],
  BlMlt: ["", Validators.required],
  Id_Acheteur: ["", Validators.required],
  BlAcheteur: ["", Validators.required],
  Quantite: ["", Validators.required],
  Poids: ["", Validators.required],
  Observation: ["", Validators.required]
      });
  }

  // acces facile au champs de votre formulaire
  get f(): any { return this.reactiveForm_add_mvtsortie .controls; }
  // validation du formulaire
  onSubmit_add_mvtsortie () {
      this.submitted = true;
      console.log(this.reactiveForm_add_mvtsortie .value)
      // stop here if form is invalid
      if (this.reactiveForm_add_mvtsortie .invalid) {
          return;
      }
      var mvtsortie =this.reactiveForm_add_mvtsortie .value
      this.add_mvtsortie (mvtsortie )
  }
  add_mvtsortie(mvtsortie: any) {
    this.loading_add_mvtsortie = true;
    this.api.taf_post("mvtsortie/add", mvtsortie, (reponse: any) => {
    this.loading_add_mvtsortie = false;
    if (reponse.status) {
        console.log("Opération effectuée avec succés sur la table mvtsortie. Réponse= ", reponse);
        this.onReset_add_mvtsortie()
        alert("Contenu ajouté avec succés")
        this.route.navigate(['./MvtSortie'])
    } else {
        console.log("L\'opération sur la table mvtsortie a échoué. Réponse= ", reponse);
        alert("L'opération a echoué")
    }
}, (error: any) => {
    this.loading_add_mvtsortie = false;
})
}

  // vider le formulaire
  onReset_add_mvtsortie () {
  this.submitted = false;
  this.reactiveForm_add_mvtsortie .reset();
  }

}
