<app-navbar></app-navbar>
<br/><br/><br/><br/><br/><br/>
<div class="container">
  <div class="form-group" style="float:right">
    <input type="text" class="form-control" name="filterTerm" style="width: 250px;border: 2px solid #235f83;text-align: center;"
      [(ngModel)]="filterTerm" placeholder="Recherche"
    />
  </div>
<table class="centered highlight datatable table table-striped table-bordered" style="border: 2px solid #235f83;">
  <thead>
    <tr>
        <th>Numero Navire</th>
        <th>Nom Navire</th>
        <th>Date Arrivée</th>
        <th>Poste Aquai</th>
        <th>Mole </th>
        <th>Provenance</th>
    </tr>
  </thead>
  <tbody >
    <tr *ngFor="let unNavire of navires |filter:filterTerm">
      <td>{{unNavire.Numero_Navire}}</td>
      <td>{{unNavire.Nom_Navire}}</td>
      <td>{{unNavire.Date_Arrivee}}</td>
      <td>{{unNavire.Poste_Aquai}}</td>
      <td>{{unNavire.Mole}}</td>
      <td>{{unNavire.Provenance}}</td>

      <button type="submit" class="btn btn-danger" style="background-color: red;margin: 8px;font-family: initial;" (click)="delete_navire(unNavire)"> Supprimer</button>
      <button type="submit" class="btn btn-primary" style="font-family: initial;" (click)="updateNavire(unNavire.Numero_Navire)"> Modifier</button>
    </tr>

  </tbody>
</table ><br/>
<a class="waves-effect waves-light btn"  [style.margin]="' 10px'" (click)="ajouterNavires()">AjouterNavire</a>
<br/><br/>
</div>
<app-footer></app-footer>