<app-navbar></app-navbar>
<br><br><br><br><br>
<div class="container">
  <form  [formGroup]="reactiveForm_add_bl " (ngSubmit)="onSubmit_add_bl ()" #form_add_bl ="ngForm" class="row">
          
    <!-- Id_Bl field avec un control de validite -->
    <div class="form-group col-sm-6">
      <label >Id Bl</label>
      <input class="form-control" type="number"  formControlName="Id_Bl"  placeholder="Id Bl" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Id_Bl.errors }"/>
      <div *ngIf="submitted && f.Id_Bl.errors" class="invalid-feedback">
          <div *ngIf="f.Id_Bl.errors.required"> Id_Bl est obligatoire </div>
      </div>
    </div>		
    <!-- Date1 field avec un control de validite -->
    <div class="form-group col-sm-6">
      <label >Date1</label>
      <input class="form-control" type="date"  formControlName="Date1"  placeholder="Date1" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Date1.errors }"/>
      <div *ngIf="submitted && f.Date1.errors" class="invalid-feedback">
          <div *ngIf="f.Date1.errors.required"> Date1 est obligatoire </div>
      </div>
    </div>		
    <!-- Numero_Camion field avec un control de validite -->
    <div class="form-group col-sm-6">
      <label >Numero Camion</label>
      <input class="form-control" type="text"  formControlName="Numero_Camion"  placeholder="Numero Camion" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Numero_Camion.errors }"/>
      <div *ngIf="submitted && f.Numero_Camion.errors" class="invalid-feedback">
          <div *ngIf="f.Numero_Camion.errors.required"> Numero_Camion est obligatoire </div>
      </div>
    </div>		
    <!-- Poids_Brut field avec un control de validite -->
    <div class="form-group col-sm-6">
      <label >Poids Brut</label>
      <input class="form-control" type="number"  formControlName="Poids_Brut"  placeholder="Poids Brut" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Poids_Brut.errors }"/>
      <div *ngIf="submitted && f.Poids_Brut.errors" class="invalid-feedback">
          <div *ngIf="f.Poids_Brut.errors.required"> Poids_Brut est obligatoire </div>
      </div>
    </div>		
    <!-- Tare field avec un control de validite -->
    <div class="form-group col-sm-6">
      <label >Tare</label>
      <input class="form-control" type="number"  formControlName="Tare"  placeholder="Tare" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Tare.errors }"/>
      <div *ngIf="submitted && f.Tare.errors" class="invalid-feedback">
          <div *ngIf="f.Tare.errors.required"> Tare est obligatoire </div>
      </div>
    </div>		
    <!-- Poids_Net field avec un control de validite -->
    <div class="form-group col-sm-6">
      <label >Poids Net</label>
      <input class="form-control" type="number"  formControlName="Poids_Net"  placeholder="Poids Net" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Poids_Net.errors }"/>
      <div *ngIf="submitted && f.Poids_Net.errors" class="invalid-feedback">
          <div *ngIf="f.Poids_Net.errors.required"> Poids_Net est obligatoire </div>
      </div>
    </div>		
    <!-- Nbre_Sacs field avec un control de validite -->
    <div class="form-group col-sm-6">
      <label >Nbre Sacs</label>
      <input class="form-control" type="number"  formControlName="Nbre_Sacs"  placeholder="Nbre Sacs" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Nbre_Sacs.errors }"/>
      <div *ngIf="submitted && f.Nbre_Sacs.errors" class="invalid-feedback">
          <div *ngIf="f.Nbre_Sacs.errors.required"> Nbre_Sacs est obligatoire </div>
      </div>
    </div>		
    <!-- Transporteur field avec un control de validite -->
    <div class="form-group col-sm-6">
      <label >Transporteur</label>
      <input class="form-control" type="text"  formControlName="Transporteur"  placeholder="Transporteur" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Transporteur.errors }"/>
      <div *ngIf="submitted && f.Transporteur.errors" class="invalid-feedback">
          <div *ngIf="f.Transporteur.errors.required"> Transporteur est obligatoire </div>
      </div>
    </div>		
    <!-- Numero_Bl_Tc field avec un control de validite -->
    <div class="form-group col-sm-6">
      <label >Numero Bl Tc</label>
      <input class="form-control" type="number"  formControlName="Numero_Bl_Tc"  placeholder="Numero Bl Tc" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Numero_Bl_Tc.errors }"/>
      <div *ngIf="submitted && f.Numero_Bl_Tc.errors" class="invalid-feedback">
          <div *ngIf="f.Numero_Bl_Tc.errors.required"> Numero_Bl_Tc est obligatoire </div>
      </div>
    </div>		
    <!-- Destination field avec un control de validite -->
    <div class="form-group col-sm-6">
      <label >Destination</label>
      <input class="form-control" type="text"  formControlName="Destination"  placeholder="Destination" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Destination.errors }"/>
      <div *ngIf="submitted && f.Destination.errors" class="invalid-feedback">
          <div *ngIf="f.Destination.errors.required"> Destination est obligatoire </div>
      </div>
    </div>		
    <!-- Nom_Chauffeur field avec un control de validite -->
    <div class="form-group col-sm-6">
      <label >Nom Chauffeur</label>
      <input class="form-control" type="text"  formControlName="Nom_Chauffeur"  placeholder="Nom Chauffeur" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Nom_Chauffeur.errors }"/>
      <div *ngIf="submitted && f.Nom_Chauffeur.errors" class="invalid-feedback">
          <div *ngIf="f.Nom_Chauffeur.errors.required"> Nom_Chauffeur est obligatoire </div>
      </div>
    </div>		
    <!-- Observation field avec un control de validite -->
    <div class="form-group col-sm-6">
      <label >Observation</label>
      <input class="form-control" type="text"  formControlName="Observation"  placeholder="Observation" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Observation.errors }"/>
      <div *ngIf="submitted && f.Observation.errors" class="invalid-feedback">
          <div *ngIf="f.Observation.errors.required"> Observation est obligatoire </div>
      </div>
    </div>		
    <!-- Heure_Depart field avec un control de validite -->
    <div class="form-group col-sm-6">
      <label >Heure Depart</label>
      <input class="form-control" type="datetime-local"  formControlName="Heure_Depart"  placeholder="Heure Depart" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Heure_Depart.errors }"/>
      <div *ngIf="submitted && f.Heure_Depart.errors" class="invalid-feedback">
          <div *ngIf="f.Heure_Depart.errors.required"> Heure_Depart est obligatoire </div>
      </div>
    </div>		
    <!-- Numero_Permis field avec un control de validite -->
    <div class="form-group col-sm-6">
      <label >Numero Permis</label>
      <input class="form-control" type="number"  formControlName="Numero_Permis"  placeholder="Numero Permis" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Numero_Permis.errors }"/>
      <div *ngIf="submitted && f.Numero_Permis.errors" class="invalid-feedback">
          <div *ngIf="f.Numero_Permis.errors.required"> Numero_Permis est obligatoire </div>
      </div>
    </div>		
    <!-- Date_Permis field avec un control de validite -->
    <div class="form-group col-sm-6">
      <label >Date Permis</label>
      <input class="form-control" type="date"  formControlName="Date_Permis"  placeholder="Date Permis" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Date_Permis.errors }"/>
      <div *ngIf="submitted && f.Date_Permis.errors" class="invalid-feedback">
          <div *ngIf="f.Date_Permis.errors.required"> Date_Permis est obligatoire </div>
      </div>
    </div>		
    <!-- Cumul_Nbre field avec un control de validite -->
    <div class="form-group col-sm-6">
      <label >Cumul Nbre</label>
      <input class="form-control" type="number"  formControlName="Cumul_Nbre"  placeholder="Cumul Nbre" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Cumul_Nbre.errors }"/>
      <div *ngIf="submitted && f.Cumul_Nbre.errors" class="invalid-feedback">
          <div *ngIf="f.Cumul_Nbre.errors.required"> Cumul_Nbre est obligatoire </div>
      </div>
    </div>		
    <!-- Cumul_Poids field avec un control de validite -->
    <div class="form-group col-sm-6">
      <label >Cumul Poids</label>
      <input class="form-control" type="number"  formControlName="Cumul_Poids"  placeholder="Cumul Poids" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Cumul_Poids.errors }"/>
      <div *ngIf="submitted && f.Cumul_Poids.errors" class="invalid-feedback">
          <div *ngIf="f.Cumul_Poids.errors.required"> Cumul_Poids est obligatoire </div>
      </div>
    </div>
  </form>
  <!-- vous pouvez valider votre formulaire n\'importe ou -->

  <div class="text-center m-2">
  <button type="button" class="btn btn-primary m-2" [disabled]="loading_add_bl "
    (click)="form_add_bl.ngSubmit.emit()">{{loading_add_bl ?"En cours ...":"Ajouter"}}</button>
  <button class="btn btn-secondary m-2" type="reset" (click)="onReset_add_bl ()">Vider</button>
  </div>
</div>
<app-footer></app-footer>

