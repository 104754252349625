<app-navbar></app-navbar><br/><br/><br/><br/><br/><br/>
<div class="container"> 
<form [formGroup]="reactiveForm_edit_produit " (ngSubmit)="onSubmit_edit_produit ()" #form_edit_produit ="ngForm" class="row"> 
    <!-- Id_produit field avec un control de validite --> 
    <div class="form-group col-sm-6"> 
        <label >Id_produit</label>
         <input class="form-control" type="text" formControlName="Id_produit" placeholder="Id_produit" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Id_produit.errors }"/> 
         <div *ngIf="submitted && f.Id_produit.errors" class="invalid-feedback"> 
            <div *ngIf="f.Id_produit.errors.required"> Id_produit est obligatoire </div>
        </div> 
    </div> 
     <!-- Nom_Navire field avec un control de validite -->
     <div class="form-group col-sm-6">
        <label >Nom_Navire</label> 
        <input class="form-control" type="text" formControlName="Nom_Navire" placeholder="Nom_Navire" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Nom_Navire.errors }"/>
        <div *ngIf="submitted && f.Nom_Navire.errors" class="invalid-feedback">
         <div *ngIf="f.Nom_Navire.errors.required"> Nom_Navire est obligatoire </div> 
        </div>
    </div>
    <!-- Nature field avec un control de validite --> 
    <div class="form-group col-sm-6"> 
        <label >Nature</label> 
        <input class="form-control" type="text" formControlName="Nature" placeholder="Nature" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Nature.errors }"/> 
        <div *ngIf="submitted && f.Nature.errors" class="invalid-feedback"> 
         <div *ngIf="f.Nature.errors.required"> Nature est obligatoire </div>
        </div> 
    </div> 
    <!-- Origine field avec un control de validite -->
     <div class="form-group col-sm-6">
         <label >Origine</label>
         <input class="form-control" type="text" formControlName="Origine" placeholder="Origine" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Origine.errors }"/>
        <div *ngIf="submitted && f.Origine.errors" class="invalid-feedback"> 
         <div *ngIf="f.Origine.errors.required"> Origine est obligatoire </div> 
        </div> 
    </div> 
    <!-- Etat field avec un control de validite -->
     <div class="form-group col-sm-6"> 
        <label >Etat</label> 
        <input class="form-control" type="text" formControlName="Etat" placeholder="Etat" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Etat.errors }"/> 
        <div *ngIf="submitted && f.Etat.errors" class="invalid-feedback"> 
            <div *ngIf="f.Etat.errors.required"> Etat est obligatoire </div> 
        </div> 
    </div> 
    
    <!-- Numero_Navire field avec un control de validite --> 
    <div class="form-group col-sm-6"> 
        <label >Numero_Navire</label>
        <input class="form-control" type="text" formControlName="Numero_Navire" placeholder="Numero_Navire" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Numero_Navire.errors }"/>
        <div *ngIf="submitted && f.Numero_Navire.errors" class="invalid-feedback">
          <div *ngIf="f.Numero_Navire.errors.required"> Numero_Navire est obligatoire </div>
        </div>
    </div>
</form> 
<!-- vous pouvez valider votre formulaire n\'importe ou -->
<div class="text-center m-2">
    <button type="button" class="btn btn-primary m-2" [disabled]="loading_edit_produit "
        (click)="form_edit_produit .ngSubmit.emit()">{{loading_edit_produit ?"En cours ...":"Modifier"}}</button>
    <button class="btn btn-secondary m-2" type="reset" (click)="onReset_edit_produit ()">Vider</button>
    </div> 
</div> 
<app-footer></app-footer>

