<app-navbar></app-navbar><br>
<div class="login-container">
  <div class="form-container"><br>
    <h2 class="titre" style="margin-left: 40%;font-family: initial;">Page d'inscription</h2>
    <form style="margin-left:40%;margin-top: 2rem;"  [formGroup]="reactiveForm_add_user " (ngSubmit)="onSubmit_add_user ()" #form_add_user ="ngForm" class="row">		
     <!-- Prenom field avec un control de validite -->
      <div class="form-group col-sm-6">
        <div class="input-field">
          <i class="material-icons">person_add</i>
          <input class="form-control" type="text"  formControlName="Prenom"  placeholder="Prenom"  [ngClass]="{ 'is-invalid': submitted && f.Prenom.errors }"/>
        </div>
        <div *ngIf="submitted && f.Prenom.errors" class="invalid-feedback">
            <div *ngIf="f.Prenom.errors.required"> Prenom est obligatoire </div>
      </div>
      </div>		
      <!-- Nom field avec un control de validite -->
      <div class="form-group col-sm-6">
        <div class="input-field">
          <i class="material-icons">person_add</i>
          <input class="form-control" type="text"  formControlName="Nom"  placeholder="Nom"  [ngClass]="{ 'is-invalid': submitted && f.Nom.errors }"/>
        </div>
        <div *ngIf="submitted && f.Nom.errors" class="invalid-feedback">
            <div *ngIf="f.Nom.errors.required"> Nom est obligatoire </div>
        </div>
      </div>	
            
      <!-- email field avec un control de validite -->
      <div class="form-group col-sm-6">
        <div class="input-field">
          <i class="material-icons">mail</i>
          <input class="form-control" type="text"  formControlName="email"  placeholder="Email"  [ngClass]="{ 'is-invalid': submitted && f.email.errors }"/>
        </div>
        <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
            <div *ngIf="f.email.errors.required"> email est obligatoire </div>
        </div>
      </div>	
      <!-- password field avec un control de validite -->
      <div class="form-group col-sm-6">
        <div class="input-field">
          <i class="material-icons">lock</i>
          <input class="form-control" type="password"  formControlName="password"  placeholder="Password"  [ngClass]="{ 'is-invalid': submitted && f.password.errors }"/>
        </div>
        <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
            <div *ngIf="f.password.errors.required"> password est obligatoire </div>
        </div>
      </div>
    </form>
    <div  style="margin-left: 60%;">
      <button type="button" style="background-color: #211c52;font-family: initial;border: 2px solid #211c52;" class="btn btn-primary m-2" [disabled]="loading_add_user "
        (click)="form_add_user .ngSubmit.emit()">{{loading_add_user ?"En cours ...":"S'incrire"}}</button>
      <button class="btn btn-secondary m-2" type="reset" style="background-color: #211c52;font-family: initial;border: 2px solid #211c52;" (click)="connexion()">Se connecter</button>
      </div>
  </div>
</div>
<div class="panels-container">
  <img src="/assets/logo_bateau.png" alt="SVG" class="login-image">
</div><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br>
<app-footer ></app-footer>

<!-- vous pouvez valider votre formulaire n\'importe ou -->


