  <app-navbar></app-navbar><br/><br/><br/><br/>
 <!-- <form (ngSubmit)="AjoutDeclaration()"  class="sign-in-form" #cargaisonForm="ngForm"class="form">
      <div class="form-group">
        <label for="exampleInputIdCragaison">Id Declaration </label>
        <input type="number" name="idDeclaration"  class="form-control" id="exampleInputidDeclaration" placeholder="idDeclaration" [(ngModel)]=declaration.idDeclaration min="1">
      </div>
      <div class="form-group">
        <label for="exampleInputNumeroDeclaration">Numero Declaration</label>
        <input type="text" name ="numeroDecalaration" class="form-control" id="exampleInputNumeroDeclaration" placeholder="numeroDecalaration"[(ngModel)]=declaration.numeroDeclaration min="1">
      </div>
      <div class="form-group">
        <label for="exampleInputIdCragaison">Id Cargaison</label>
        <input type="number" name="idCargaison"  class="form-control" id="exampleInputIdCargaison" placeholder="idCargaison" [(ngModel)]=declaration.idCargaison min="1">
      </div>
      <div class="form-group">
        <label for="exampleInputdateArrivee">Quantite Débarquée</label>
        <input type="number" name="quantite" class="form-control" id="exampleInputquantite" placeholder="Quantite"[(ngModel)]=declaration.quantiteDebarquee min="1" >
      </div>
      <div class="form-group">
        <label for="exampleInputPoids">Poids Débarqué</label>
        <input type="number" name ="poids" class="form-control" id="exampleInputPoids" placeholder="Poids" [(ngModel)]=declaration.poidsDebarque min="1" >
      </div>
      <div class="form-group">
        <label for="exampleInputLargeurNavire">Quantité Restante</label>
        <input type="number" name ="quantiteRestante" class="form-control" id="exampleInputquantiteRestante" placeholder="quantiteRestante" [(ngModel)]=declaration.quantiteRestante min="1" >
      </div>
    <div class="form-group">
      <label for="exampleInputLargeurNavire">Poids Restant</label>
      <input type="number" name ="poidsRestant" class="form-control" id="exampleInputpoidsRestant" placeholder="poidsRestant" [(ngModel)]=declaration.poidsRestant min="1" >
    </div>
      <div class="form-group">
        <label for="exampleInputvolumeNavire">Date Jour</label>
        <input type="date"name ="dateJour" class="form-control" id="exampleInputdateJour" placeholder="dateJour" [(ngModel)]=declaration.dateJour min="1" >
      </div>
        <button type="submit" class="btn btn-primary" [disabled]="!cargaisonForm.form.valid">Ajouter</button>
       <input type="submit"  class="login-btn solid" value="Ajouter"  [disabled]="!navireForm.form.valid"/>
  </form>-->
 <div class="container">
  <form  [formGroup]="reactiveForm_add_declaration " (ngSubmit)="onSubmit_add_declaration ()" #form_add_declaration ="ngForm" class="row">
        
    <!-- Id_Declaration field avec un control de validite -->
    <div class="form-group col-sm-6">
      <label >Id Declaration</label>
      <input class="form-control" type="number"  formControlName="Id_Declaration"  placeholder="Id_Declaration" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Id_Declaration.errors }"/>
      <div *ngIf="submitted && f.Id_Declaration.errors" class="invalid-feedback">
          <div *ngIf="f.Id_Declaration.errors.required"> Id_Declaration est obligatoire </div>
      </div>
    </div>		
    <!-- Numero_Declaration field avec un control de validite -->
    <div class="form-group col-sm-6">
      <label >Numero Declaration</label>
      <input class="form-control" type="text"  formControlName="Numero_Declaration"  placeholder="Numero_Declaration" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Numero_Declaration.errors }"/>
      <div *ngIf="submitted && f.Numero_Declaration.errors" class="invalid-feedback">
          <div *ngIf="f.Numero_Declaration.errors.required"> Numero_Declaration est obligatoire </div>
      </div>
    </div>		
    <!-- Id_Cargaison field avec un control de validite -->
    <div class="form-group col-sm-6">
      <label >Id Cargaison</label>
      <input class="form-control" type="number"  formControlName="Id_Cargaison"  placeholder="Id Cargaison" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Id_Cargaison.errors }"/>
      <div *ngIf="submitted && f.Id_Cargaison.errors" class="invalid-feedback">
          <div *ngIf="f.Id_Cargaison.errors.required"> Id_Cargaison est obligatoire </div>
      </div>
    </div>		
    <!-- Quantite_Debarquee field avec un control de validite -->
    <div class="form-group col-sm-6">
      <label >Quantité Débarquée</label>
      <input class="form-control" type="number"  formControlName="Quantité Debarquée"  placeholder="Quantité Débarquée" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Quantite_Debarquee.errors }"/>
      <div *ngIf="submitted && f.Quantite_Debarquee.errors" class="invalid-feedback">
          <div *ngIf="f.Quantite_Debarquee.errors.required"> Quantite_Debarquee est obligatoire </div>
      </div>
    </div>		
    <!-- Poids_Debarque field avec un control de validite -->
    <div class="form-group col-sm-6">
      <label >Poids Débarqué</label>
      <input class="form-control" type="number"  formControlName="Poids_Debarque"  placeholder="Poids Débarqué" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Poids_Debarque.errors }"/>
      <div *ngIf="submitted && f.Poids_Debarque.errors" class="invalid-feedback">
          <div *ngIf="f.Poids_Debarque.errors.required"> Poids_Debarque est obligatoire </div>
      </div>
    </div>		
    <!-- Quantite_Restante field avec un control de validite -->
    <div class="form-group col-sm-6">
      <label >QuantitéRestante</label>
      <input class="form-control" type="number"  formControlName="Quantite_Restante"  placeholder="Quantité Restante" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Quantite_Restante.errors }"/>
      <div *ngIf="submitted && f.Quantite_Restante.errors" class="invalid-feedback">
          <div *ngIf="f.Quantite_Restante.errors.required"> Quantite_Restante est obligatoire </div>
      </div>
    </div>		
    <!-- Poids_Restant field avec un control de validite -->
    <div class="form-group col-sm-6">
      <label >Poids Restant</label>
      <input class="form-control" type="number"  formControlName="Poids_Restant"  placeholder="Poids Restant" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Poids_Restant.errors }"/>
      <div *ngIf="submitted && f.Poids_Restant.errors" class="invalid-feedback">
          <div *ngIf="f.Poids_Restant.errors.required"> Poids_Restant est obligatoire </div>
      </div>
    </div>		
    <!-- Date_Jour field avec un control de validite -->
    <div class="form-group col-sm-6">
      <label >Date Jour</label>
      <input class="form-control" type="date"  formControlName="Date_Jour"  placeholder="Date Jour" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Date_Jour.errors }"/>
      <div *ngIf="submitted && f.Date_Jour.errors" class="invalid-feedback">
          <div *ngIf="f.Date_Jour.errors.required"> Date_Jour est obligatoire </div>
      </div>
    </div>
</form>
<!-- vous pouvez valider votre formulaire n\'importe ou -->

<div class="text-center m-2">
<button type="button" class="btn btn-primary m-2" [disabled]="loading_add_declaration "
    (click)="form_add_declaration .ngSubmit.emit()">{{loading_add_declaration ?"En cours ...":"Ajouter"}}</button>
<button class="btn btn-secondary m-2" type="reset" (click)="onReset_add_declaration ()">Vider</button>
</div>
</div>
  <br/><br/>
  <app-footer></app-footer>
