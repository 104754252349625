<app-navbar></app-navbar>
<br />
<div>
  <div id="myCarousel" class="carousel slide" data-ride="carousel" style="margin-top:0rem">
    <div class="carousel-inner" >
      <div class="item active">
        <img src="/assets/slideshow-image3-1541602310.jpg" alt="Los Angeles"
          style="width:100%; height: 27rem;margin-top: 5rem;">
        <div class="carousel-caption" style="margin-left: -3.5rem;">
          <h1 style="color: white !important;margin-top: -18rem;font-family: initial;">Manutention Logistique Transport</h1>
          <h4 style="color:white !important;font-family: initial;margin-left: 7rem;"> MLT est une société anonyme S.A.à 100% sénégalais créée en 2004.</h4>
          <button type="button" class="btn btn-secondary"
          style="height: 52px;margin-top:1rem;">
            <a style="color:white !important;font-family: initial;"
              href="/inscription">Inscrivez-vous</a></button>
        </div>
      </div>
    </div>
  </div>
</div>
<br /> <br />
<!-- Featured ---->
<div class="row featurette container" style="margin-left: 7%">
  <div class="col-md-6 order-md-2" style=" box-shadow: #eee 2px 2px 5px 5px;">
    <div style="color: white !important">
      <h2 class="featurette-heading  " style="margin-top: 3%;font-size: 36px;color: #235f83 !important;font-family: initial !important;">
        Manutation Logistique Transport
      </h2>
      <p class="lead p2">
        <li>Son capital est de 45 000 000 F CFA à 100% sénégalais</li>
        <li>Son siège au 33 Boulevard de la libération, en face du môle 1 Port Autonome de DAKAR (Sénégal).</li>
        <li>N° Commissionnaire en Douanes : 180N° Crédit Douanes : 288</li>
        <li>N° Agrément transport DK\ 18202\ DTT</li>
        <li>Téléphone : 00 221 33 889 92 92 / Fax : 00 221 33 842 99 89</li>
        <li>Email : mltsa@mltsa.sn</li>
        <li>Site Web : www.mltsa.sn</li>

      </p>
    </div>
  </div>
  <div class="col-md-6 order-md-1">
    <img src="/assets/Capture11.PNG" alt="Bootstrap" width="100%" height="100%">
  </div>
</div>
<!-- Les services---->
<div class="container px-4 py-5" id="icon-grid">
  <div style="margin-left: 38.5%">
    <h2 class="pb-2 "
      style="text-align: center;font-weight: bold;color: #235f83;border-bottom: 4px solid black;width: 35%;font-size: 40px;font-family: initial;">
      Nos Services</h2>
  </div><br />
  <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-4 py-5"
    style="box-shadow: #eee -10px 3px 13px 19px">
    <div class="col d-flex align-items-start div1">
      <div>
        <img src="/assets/Capture13.PNG" style="margin-bottom: 1.2rem ;margin-top:0.7rem">
        <h3 class="fw-bold mb-0 fs-4 h3">Manutention Bord Terre</h3>
        <p class="p11">Nous nous occupons du chargement et de déchargement de vos conteneurs, de la cale du bateau
          jusqu'à la pose sur terre.</p>
      </div>
    </div>
    <div class="col d-flex align-items-start div1">
      <div>
        <img src="/assets/Capture12.PNG" style="margin-bottom: 1.2rem ;margin-top:0.7rem">
        <h3 class="fw-bold mb-0 fs-4 h3">Transport</h3>
        <p class="p11">Plus de 80 camions (plateau, benne, citerne) de transport de marchandises dans l'ensemble du
          Sénégal et de la sous-région.</p>
      </div>
    </div>
    <div class="col d-flex align-items-start div1">
      <div>
        <img src="/assets/Capture14.PNG  ">
        <h3 class="fw-bold mb-0 fs-4 h3"> Logistique </h3>
        <p class="p11">MLT SA gère toutes vos opérations de transport de stockage de manutention de vos produits dans
          les meilleurs délais possibles.</p>
      </div>
    </div>
    <div class="col d-flex align-items-start div1">
      <div>
        <img src="/assets/Capture15.PNG">
        <h3 class="fw-bold mb-0 fs-4 h3"> Stockage </h3>
        <p class="p11">Nous disposons de 5 entrepôts de stockage de marchandises d'une capacité de 23 000 m2 (Hann,
          Potou, Diamniadio, Cambérène).</p>
      </div>
    </div>

  </div>
</div>
<app-footer></app-footer>