  <app-navbar></app-navbar>
<br/><br/><br/><br/><br/><br/>
<div class="form-group" style="float:right">
  <input type="text" class="form-control" name="filterTerm" style="width: 250px;border: 2px solid #235f83;text-align: center;"
    [(ngModel)]="filterTerm" placeholder="Recherche"
  />
</div>
<table class="centered highlight datatable table table-striped table-bordered" style="border: 2px solid #235f83;padding-left: 2px;margin-right: 2px;">
  <thead>
    <tr>
        <th>Id Magasin</th>
        <th>Nom Magasin</th>
        <th>Adresse</th>
        <th>Contact</th>
        <th>Proprietaire</th>
        <th>Magasinien</th>
        <th>Id Navire</th>
        <th>Nom Navire</th>
        <th>Id Produit</th>
        <th>Nature Produit</th>
    </tr>
  </thead>

  <tbody >
    <tr *ngFor="let unMagasin of magasins |filter:filterTerm" >
      <td>{{unMagasin.Id_Magasin}}</td>
      <td>{{unMagasin.Nom_Magasin}}</td>
      <td>{{unMagasin.Adresse}}</td>
      <td>{{unMagasin.Contact}}</td>
      <td>{{unMagasin.Proprietaire}}</td>
      <td>{{unMagasin.Magasinien}}</td>
      <td>{{unMagasin.Numero_Navire}}</td>
      <td>{{unMagasin.Nom_Navire}}</td>
      <td>{{unMagasin.Id_Produit}}</td>
      <td>{{unMagasin.Nature_Produit}}</td>
      <div style="display: flex;">
      <div><button type="submit" class="btn btn-danger" style="background-color: red;margin: 8px;font-family:initial ;font-size: 11px;"(click)="delete_magasin(unMagasin)"> Supprimer</button>
      <button type="submit" class="btn btn-primary" style="font-family: initial;font-size: 11px;" (click)="updateMagasin(unMagasin.Id_Magasin)" > Modifier</button>
    </div>
      <div class="dropdown" style="margin-left: 1rem;margin-top:0.5rem">
        <button class="btn btn-primary dropdown-toggle" type="button" data-toggle="dropdown" style="font-size:11px;font-family: initial;">Infos</button>
        <ul class="dropdown-menu"  >
          <li><a href="./MvtSortie" style="font-size: 16px;">Mvt Sortie</a></li>
          <li><a href="./FicheSortie" style="font-size: 16px;">Fiche Sortie</a></li>
          <li><a href="./RecapLivraison" style="font-size: 16px;">RecapLivraison</a></li>
        </ul>
      </div>
    </div>
    </tr>

  </tbody>
</table ><br/>
<a class="waves-effect waves-light btn"  [style.margin]="' 10px'" (click)="ajouterMagasins()" >AjouterMagasin</a>
<br/><br/>
<app-footer></app-footer>
