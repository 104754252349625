<app-navbar></app-navbar>
<br/><br/><br/><br/><br/><br/>
<div class="container">
    <form  [formGroup]="reactiveForm_add_mvtsortie " (ngSubmit)="onSubmit_add_mvtsortie ()" #form_add_mvtsortie ="ngForm" class="row">
        
        <!-- IdMvt field avec un control de validite -->
        <div class="form-group col-sm-6">
          <label >Id Mvt</label>
          <input class="form-control" type="number"  formControlName="IdMvt"  placeholder="Id Mvt" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.IdMvt.errors }"/>
          <div *ngIf="submitted && f.IdMvt.errors" class="invalid-feedback">
              <div *ngIf="f.IdMvt.errors.required"> IdMvt est obligatoire </div>
          </div>
        </div>		
        <!-- Date field avec un control de validite -->
        <div class="form-group col-sm-6">
          <label >Date</label>
          <input class="form-control" type="Date"  formControlName="Date"  placeholder="Date" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Date.errors }"/>
          <div *ngIf="submitted && f.Date.errors" class="invalid-feedback">
              <div *ngIf="f.Date.errors.required"> Date est obligatoire </div>
          </div>
        </div>		
        <!-- BlMlt field avec un control de validite -->
        <div class="form-group col-sm-6">
          <label >Bl Mlt</label>
          <input class="form-control" type="text"  formControlName="BlMlt"  placeholder="BlMlt" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.BlMlt.errors }"/>
          <div *ngIf="submitted && f.BlMlt.errors" class="invalid-feedback">
              <div *ngIf="f.BlMlt.errors.required"> BlMlt est obligatoire </div>
          </div>
        </div>		
        <!-- Id_Acheteur field avec un control de validite -->
        <div class="form-group col-sm-6">
          <label >Id Acheteur</label>
          <input class="form-control" type="number"  formControlName="Id_Acheteur"  placeholder="Id Acheteur" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Id_Acheteur.errors }"/>
          <div *ngIf="submitted && f.Id_Acheteur.errors" class="invalid-feedback">
              <div *ngIf="f.Id_Acheteur.errors.required"> Id_Acheteur est obligatoire </div>
          </div>
        </div>		
        <!-- BlAcheteur field avec un control de validite -->
        <div class="form-group col-sm-6">
          <label >Bl Acheteur</label>
          <input class="form-control" type="text"  formControlName="BlAcheteur"  placeholder="Bl Acheteur" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.BlAcheteur.errors }"/>
          <div *ngIf="submitted && f.BlAcheteur.errors" class="invalid-feedback">
              <div *ngIf="f.BlAcheteur.errors.required"> BlAcheteur est obligatoire </div>
          </div>
        </div>		
        <!-- Quantite field avec un control de validite -->
        <div class="form-group col-sm-6">
          <label >Quantite</label>
          <input class="form-control" type="number"  formControlName="Quantite"  placeholder="Quantite" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Quantite.errors }"/>
          <div *ngIf="submitted && f.Quantite.errors" class="invalid-feedback">
              <div *ngIf="f.Quantite.errors.required"> Quantite est obligatoire </div>
          </div>
        </div>		
        <!-- Poids field avec un control de validite -->
        <div class="form-group col-sm-6">
          <label >Poids</label>
          <input class="form-control" type="number"  formControlName="Poids"  placeholder="Poids" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Poids.errors }"/>
          <div *ngIf="submitted && f.Poids.errors" class="invalid-feedback">
              <div *ngIf="f.Poids.errors.required"> Poids est obligatoire </div>
          </div>
        </div>		
        <!-- Observation field avec un control de validite -->
        <div class="form-group col-sm-6">
          <label >Observation</label>
          <input class="form-control" type="text"  formControlName="Observation"  placeholder="Observation" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Observation.errors }"/>
          <div *ngIf="submitted && f.Observation.errors" class="invalid-feedback">
              <div *ngIf="f.Observation.errors.required"> Observation est obligatoire </div>
          </div>
        </div>
</form>
<!-- vous pouvez valider votre formulaire n\'importe ou -->

<div class="text-center m-2">
    <button type="button" class="btn btn-primary m-2" [disabled]="loading_add_mvtsortie "
        (click)="form_add_mvtsortie .ngSubmit.emit()">{{loading_add_mvtsortie ?"En cours ...":"Valider"}}</button>
    <button class="btn btn-secondary m-2" type="reset" (click)="onReset_add_mvtsortie ()">Vider</button>
</div>
<br>
</div>
<app-footer></app-footer>