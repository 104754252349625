<app-navbar></app-navbar>
<br/><br/><br/><br/><br/><br/>
<div class="form-group" style="float:right">
  <input type="text" class="form-control" name="filterTerm" style="width: 250px;border: 2px solid #235f83;text-align: center;"
    [(ngModel)]="filterTerm" placeholder="Recherche"
  />
</div>
    <table class="centered highlight datatable table table-striped table-bordered" style="border: 2px solid #235f83;">
      <thead>
        <tr>
            <th>Id Acheteur</th>
            <th>Nom Acheteur</th>
            <th>Bl Acheteur</th>
            <th>Numero Navire</th>
            <th>Nom Navire</th>
            <th>Id Magasin</th>
            <th>Nom Magasin</th>
            <th>Quantite</th>
            <th>Poids</th>
            <th>Variete</th>
        </tr>
      </thead>
      <tbody >
        <tr *ngFor="let unAcheteur of acheteurs |filter:filterTerm" >
          <td>{{unAcheteur.Id_Acheteur}}</td>
          <td>{{unAcheteur.Nom_Acheteur}}</td>
          <td>{{unAcheteur.Bl_Acheteur}}</td>
          <td>{{unAcheteur.Numero_Navire}}</td>
          <td>{{unAcheteur.Nom_Navire}}</td>
          <td>{{unAcheteur.Id_Magasin}}</td>
          <td>{{unAcheteur.Nom_Magasin}}</td>
          <td>{{unAcheteur.Quantite}}</td>
          <td>{{unAcheteur.Poids}}</td>
          <td>{{unAcheteur.Variete}}</td>
          <button type="submit" class="btn btn-danger" style="background-color: red;margin: 8px;font-family: initial;" (click)="delete_acheteur(unAcheteur)" > Supprimer</button>
          <button type="submit" class="btn btn-primary" style="font-family: initial;" (click)="updateAcheteur(unAcheteur.Id_Acheteur)"> Modifier</button>
        </tr>
    
      </tbody>
    </table ><br/>
    <a class="waves-effect waves-light btn"  [style.margin]="' 10px'" (click)="ajouterAcheteurs()">AjouterAcheteur</a>
    <br/><br/>
    <app-footer></app-footer>
