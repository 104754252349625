<!DOCTYPE html>
<html lang="en">
    <head>
        <title>GestionStock</title>
        <meta charset="utf-8">
        <meta name="viewport" content="width=device-width, initial-scale=1">
        <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.6.1/dist/css/bootstrap.min.css">
        <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">
        <script src="https://cdn.jsdelivr.net/npm/jquery@3.6.0/dist/jquery.slim.min.js"></script>
        <script src="https://cdn.jsdelivr.net/npm/popper.js@1.16.1/dist/umd/popper.min.js"></script>
        <script src="https://cdn.jsdelivr.net/npm/bootstrap@4.6.1/dist/js/bootstrap.bundle.min.js"></script>
      </head>
<body>
    <nav class="navbar navbar-expand-sm  navbar-dark fixed-top nav" >
        <!-- Brand/logo -->
        <a class="navbar-brand" style="margin-left:1%;" [routerLink]="['/AccueilApp']"><img class="imgnav" src="/assets/Capture1.PNG"   /></a>
        
        <!-- Links -->
        <ul class="navbar-nav" style="margin-left: -1rem;"   >
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/AccueilApp']">Accueil</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/bl']">Bl</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/navire']">Navire</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/client']">Client</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/produit']">Produit</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/acheteur']">Acheteur</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/magasin']">Magasin</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/cargaison']">Cargaison</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/declaration']">Déclaration</a>
          </li>
        </ul>
        <a class="nav-link" [routerLink]="['/inscription']"><button class="btn btn-outline-sucess btn1" type="submit">S'inscrire</button></a>
        <a class="nav-link" [routerLink]="['/connexion']"><button class="btn btn-outline-sucess bnt2" type="submit">Se connecter</button></a>
    </nav>
</body>
</html>
