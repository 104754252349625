import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Declaration } from '../Declaration';
import { ApiService } from '../services/api.service';
import { Authentification } from '../services/authentification';

@Component({
  selector: 'app-ajouter-declaration',
  templateUrl: './ajouter-declaration.component.html',
  styleUrls: ['./ajouter-declaration.component.css']
})
export class AjouterDeclarationComponent implements OnInit {

  reactiveForm_add_declaration !: FormGroup;
  submitted:boolean=false
  loading_add_declaration :boolean=false
  constructor(private formBuilder:FormBuilder,private http:HttpClient,public api:ApiService, private router: Router) {
   }

  ngOnInit(): void {
    this.init_form()
  }
  init_form() {
      this.reactiveForm_add_declaration  = this.formBuilder.group({
          
      Id_Declaration: ["", Validators.required],
      Numero_Declaration: ["", Validators.required],
      Id_Cargaison: ["", Validators.required],
      Quantite_Debarquee: ["", Validators.required],
      Poids_Debarque: ["", Validators.required],
      Quantite_Restante: ["", Validators.required],
      Poids_Restant: ["", Validators.required],
      Date_Jour: ["", Validators.required]
          });
  }

  // acces facile au champs de votre formulaire
  get f(): any { return this.reactiveForm_add_declaration .controls; }
  // validation du formulaire
  onSubmit_add_declaration () {
      this.submitted = true;
      console.log(this.reactiveForm_add_declaration .value)
      // stop here if form is invalid
      if (this.reactiveForm_add_declaration .invalid) {
          return;
      }
      var declaration =this.reactiveForm_add_declaration .value
      this.add_declaration (declaration )
  }
  add_declaration(declaration: any) {
    this.loading_add_declaration = true;
    this.api.taf_post("declaration/add", declaration, (reponse: any) => {
    this.loading_add_declaration = false;
    if (reponse.status) {
        console.log("Opération effectuée avec succés sur la table declaration. Réponse= ", reponse);
        this.onReset_add_declaration()
        alert("Contenu ajouté avec succés")
        this.router.navigate(['./declaration']);
    } else {
        console.log("L\'opération sur la table declaration a échoué. Réponse= ", reponse);
        alert("L'opération a echoué")
    }
}, (error: any) => {
    this.loading_add_declaration = false;
})
}
  // vider le formulaire
  onReset_add_declaration () {
  this.submitted = false;
  this.reactiveForm_add_declaration .reset();
  }


}
