import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from '../services/api.service';
import { Authentification } from '../services/authentification';


@Component({
  selector: 'app-connexion',
  templateUrl: './connexion.component.html',
  styleUrls: ['./connexion.component.css']
})
export class ConnexionComponent implements OnInit {

  reactiveForm_add_user !: FormGroup;
  submitted: boolean = false
  loading_add_user: boolean = false
  constructor(private formBuilder: FormBuilder, public api: ApiService, private http: HttpClient,private route:Router) { }

  ngOnInit(): void {
    this.init_form()
  }
  init_form() {
    this.reactiveForm_add_user = this.formBuilder.group({

      email: ["", Validators.required],
      password: ["", Validators.required]
    });
  }

  // acces facile au champs de votre formulaire
  get f(): any { return this.reactiveForm_add_user.controls; }
  // validation du formulaire
  onSubmit_add_user() {
    this.submitted = true;
    console.log(this.reactiveForm_add_user.value)
    // stop here if form is invalid
    if (this.reactiveForm_add_user.invalid) {
      return;
    }
    var user = this.reactiveForm_add_user.value
    this.add_user(user)
  }
  // vider le formulaire
  onReset_add_user() {
    this.submitted = false;
    this.reactiveForm_add_user.reset();
  }
  add_user(user: any) {
    this.loading_add_user = true;
    this.api.taf_post("user/connexion", user, (reponse: any) => {
      this.loading_add_user = false;
      if (reponse.status) {
        console.log("Opération effectuée avec succés sur la table user. Réponse= ", reponse);
        this.onReset_add_user()
        alert("Connexion réussie avec succés")
        this.route.navigate(['./bl'])
      } else {
        console.log("L\'opération sur la table user a échoué. Réponse= ", reponse);
        alert("L'opération a echoué")
      }
    }, (error: any) => {
      this.loading_add_user = false;
    })
  }



}
