import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Authentification } from '../services/authentification';
import { Navire } from '../navire';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../services/api.service';
import { HttpClient } from '@angular/common/http';
@Component({
  selector: 'app-navires',
  templateUrl: './navires.component.html',
  styleUrls: ['./navires.component.css']
})
export class NaviresComponent implements OnInit {
  reactiveForm_add_navire !: FormGroup;
  submitted:boolean=false
  loading_add_navire :boolean=false
  constructor(private router:Router,private Auth:Authentification,private formBuilder: FormBuilder,public api:ApiService, private http:HttpClient) {
  }
  ngOnInit(): void {
    this.init_form()
  }
  init_form() {
      this.reactiveForm_add_navire  = this.formBuilder.group({
          
  Numero_Navire: ["", Validators.required],
  Nom_Navire: ["", Validators.required],
  Date_Arrivee: ["", Validators.required],
  Poste_Aquai: ["", Validators.required],
  Mole: ["", Validators.required],
  Provenance: ["", Validators.required]
      });
  }

  // acces facile au champs de votre formulaire
  get f(): any { return this.reactiveForm_add_navire .controls; }
  // validation du formulaire
  onSubmit_add_navire () {
      this.submitted = true;
      console.log(this.reactiveForm_add_navire .value)
      // stop here if form is invalid
      if (this.reactiveForm_add_navire .invalid) {
          return;
      }
      var navire =this.reactiveForm_add_navire .value
      this.add_navire (navire )
  }
  add_navire(navire: any) {
    this.loading_add_navire = true;
    this.api.taf_post("navire/add", navire, (reponse: any) => {
    this.loading_add_navire = false;
    if (reponse.status) {
        console.log("Opération effectuée avec succés sur la table navire. Réponse= ", reponse);
        this.onReset_add_navire()
        alert("Operation ajoutée avec succés")
        this.router.navigate(['./navire']);
    } else {
        console.log("L\'opération sur la table navire a échoué. Réponse= ", reponse);
        alert("L'opération a echoué")
    }
}, (error: any) => {
    this.loading_add_navire = false;
})
}
  // vider le formulaire
  onReset_add_navire () {
  this.submitted = false;
  this.reactiveForm_add_navire .reset();
  }
  
 
}
