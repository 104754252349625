 <app-navbar></app-navbar><br/><br/><br/><br/>
  <!--<form (ngSubmit)="AjoutCargaison()"  class="sign-in-form" #cargaisonForm="ngForm"class="form">
      <div class="form-group">
        <label for="exampleInputIdCragaison">Id Cargaison</label>
        <input type="number" name="idCargaison"  class="form-control" id="exampleInputIdCargaison" placeholder="idCargaison" [(ngModel)]=cargaison.idCargaison min="1">
      </div>
      <div class="form-group">
        <label for="exampleInputNumeroDeclaration">Numero Declaration</label>
        <input type="text" name ="numeroDecalaration" class="form-control" id="exampleInputNumeroDeclaration" placeholder="numeroDecalaration"[(ngModel)]=cargaison.numeroDeclaration min="1">
      </div>
      <div class="form-group">
        <label for="exampleInputdateArrivee">Quantite</label>
        <input type="number" name="quantite" class="form-control" id="exampleInputquantite" placeholder="Quantite"[(ngModel)]=cargaison.quantite min="1" >
      </div>
      <div class="form-group">
        <label for="exampleInputPoids">Poids</label>
        <input type="number" name ="poids" class="form-control" id="exampleInputPoids" placeholder="Poids" [(ngModel)]=cargaison.poids min="1" >
      </div>
      <div class="form-group">
        <label for="exampleInputvolumeNavire">Date Declaration</label>
        <input type="date"name ="date" class="form-control" id="exampleInputdate" placeholder="Date" [(ngModel)]=cargaison.dateDeclaration min="1" >
      </div>
      <div class="form-group">
        <label for="exampleInputLargeurNavire">Numero Navire</label>
        <input type="text" name ="numeroNavire" class="form-control" id="exampleInputnumeroNavire" placeholder="numeroNavire" [(ngModel)]=cargaison.numeroNavire min="1" >
      </div>
    <div class="form-group">
      <label for="exampleInputLargeurNavire">Nom navire</label>
      <input type="text" name ="nomNavire" class="form-control" id="exampleInputnomNavire" placeholder="nomNavire" [(ngModel)]=cargaison.nomNavire min="1" >
    </div>
    <div class="form-group">
        <label for="exampleInputLargeurNavire">Id Produit</label>
        <input type="number" name ="idProduit" class="form-control" id="exampleInputidProduit" placeholder="idProduit" [(ngModel)]=cargaison.idProduit min="1" >
      </div>
  <div class="form-group">
    <label for="exampleInputLargeurNavire">Nature Produit</label>
    <input type="text" name ="nomNavire" class="form-control" id="exampleInputnomNavire" placeholder="natutreProduit" [(ngModel)]=cargaison.natureProduit min="1" >
  </div>
        <button type="submit" class="btn btn-primary" [disabled]="!cargaisonForm.form.valid">Ajouter</button>
       
  </form>-->
  <div class="container">
    <form  [formGroup]="reactiveForm_add_cargaison " (ngSubmit)="onSubmit_add_cargaison ()" #form_add_cargaison ="ngForm" class="row">
        
      <!-- Id_Cargaison field avec un control de validite -->
      <div class="form-group col-sm-6">
        <label >Id Cargaison</label>
        <input class="form-control" type="number"  formControlName="Id_Cargaison"  placeholder="Id Cargaison" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Id_Cargaison.errors }"/>
        <div *ngIf="submitted && f.Id_Cargaison.errors" class="invalid-feedback">
            <div *ngIf="f.Id_Cargaison.errors.required"> Id_Cargaison est obligatoire </div>
        </div>
      </div>		
      <!-- Numero_Declaration field avec un control de validite -->
      <div class="form-group col-sm-6">
        <label >Numero Declaration</label>
        <input class="form-control" type="text"  formControlName="Numero_Declaration"  placeholder="Numero Declaration" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Numero_Declaration.errors }"/>
        <div *ngIf="submitted && f.Numero_Declaration.errors" class="invalid-feedback">
            <div *ngIf="f.Numero_Declaration.errors.required"> Numero_Declaration est obligatoire </div>
        </div>
      </div>		
      <!-- Quantite field avec un control de validite -->
      <div class="form-group col-sm-6">
        <label >Quantite</label>
        <input class="form-control" type="number"  formControlName="Quantite"  placeholder="Quantite" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Quantite.errors }"/>
        <div *ngIf="submitted && f.Quantite.errors" class="invalid-feedback">
            <div *ngIf="f.Quantite.errors.required"> Quantite est obligatoire </div>
        </div>
      </div>		
      <!-- Poids field avec un control de validite -->
      <div class="form-group col-sm-6">
        <label >Poids</label>
        <input class="form-control" type="number"  formControlName="Poids"  placeholder="Poids" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Poids.errors }"/>
        <div *ngIf="submitted && f.Poids.errors" class="invalid-feedback">
            <div *ngIf="f.Poids.errors.required"> Poids est obligatoire </div>
        </div>
      </div>		
      <!-- Date_Declaration field avec un control de validite -->
      <div class="form-group col-sm-6">
        <label >Date Declaration</label>
        <input class="form-control" type="date"  formControlName="Date_Declaration"  placeholder="Date Declaration" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Date_Declaration.errors }"/>
        <div *ngIf="submitted && f.Date_Declaration.errors" class="invalid-feedback">
            <div *ngIf="f.Date_Declaration.errors.required"> Date_Declaration est obligatoire </div>
        </div>
      </div>		
      <!-- Numero_Navire field avec un control de validite -->
      <div class="form-group col-sm-6">
        <label >Numero Navire</label>
        <input class="form-control" type="text"  formControlName="Numero_Navire"  placeholder="Numero Navire" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Numero_Navire.errors }"/>
        <div *ngIf="submitted && f.Numero_Navire.errors" class="invalid-feedback">
            <div *ngIf="f.Numero_Navire.errors.required"> Numero_Navire est obligatoire </div>
        </div>
      </div>		
      <!-- Nom_Navire field avec un control de validite -->
      <div class="form-group col-sm-6">
        <label >Nom Navire</label>
        <input class="form-control" type="text"  formControlName="Nom_Navire"  placeholder="Nom Navire" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Nom_Navire.errors }"/>
        <div *ngIf="submitted && f.Nom_Navire.errors" class="invalid-feedback">
            <div *ngIf="f.Nom_Navire.errors.required"> Nom_Navire est obligatoire </div>
        </div>
      </div>		
      <!-- Id_Produit field avec un control de validite -->
      <div class="form-group col-sm-6">
        <label >Id Produit</label>
        <input class="form-control" type="text"  formControlName="Id_Produit"  placeholder="Id Produit" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Id_Produit.errors }"/>
        <div *ngIf="submitted && f.Id_Produit.errors" class="invalid-feedback">
            <div *ngIf="f.Id_Produit.errors.required"> Id_Produit est obligatoire </div>
        </div>
      </div>		
      <!-- Nature_Produit field avec un control de validite -->
      <div class="form-group col-sm-6">
        <label >Nature Produit</label>
        <input class="form-control" type="text"  formControlName="Nature_Produit"  placeholder="Nature Produit" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Nature_Produit.errors }"/>
        <div *ngIf="submitted && f.Nature_Produit.errors" class="invalid-feedback">
            <div *ngIf="f.Nature_Produit.errors.required"> Nature_Produit est obligatoire </div>
        </div>
      </div>
</form>
<!-- vous pouvez valider votre formulaire n\'importe ou -->

<div class="text-center m-2">
  <button type="button" class="btn btn-primary m-2" [disabled]="loading_add_cargaison "
      (click)="form_add_cargaison .ngSubmit.emit()">{{loading_add_cargaison ?"En cours ...":"Ajouter"}}</button>
  <button class="btn btn-secondary m-2" type="reset" (click)="onReset_add_cargaison ()">Vider</button>
</div>

  </div>
  <br/><br/>
  <app-footer></app-footer>
