  <app-navbar></app-navbar><br/><br/><br/><br/><br/>
  <div class="form-group" style="float:right">
    <input type="text" class="form-control" name="filterTerm" style="width: 250px;border: 2px solid #235f83;text-align: center;"
      [(ngModel)]="filterTerm" placeholder="Recherche"
    />
  </div>
<br/><br/><br/>
<table class="centered highlight datatable table table-striped table-bordered" style="border: 2px solid #235f83;" >
  <thead>
    <tr>
        <th>Id Bl</th>
        <th>Date</th>
        <th>NCamion</th>
        <th>Poids Brut</th>
        <th>Tare</th>
        <th>Poids Net</th>
        <th>Nbre Sacs</th>
        <th>Transporteur</th>
        <th>Numero BlTC</th>
        <th>Destination</th>
        <th>Nom Chauffeur</th>
        <th>Observation</th>
        <th>Heure Depart</th>
        <th>NPermis</th>
        <th>Date Permis</th>
        <th>Cumul Nbre</th>
        <th>Cumul Poids</th>
    </tr>
  </thead>

  <tbody >
    <tr *ngFor="let unbl of bls |filter:filterTerm">
      <td>{{unbl.Id_Bl}}</td>
      <td>{{unbl.Date1}}</td>
      <td>{{unbl.Numero_Camion}}</td>
      <td>{{unbl.Poids_Brut}}</td>
      <td>{{unbl.Tare}}</td>
      <td>{{unbl.Poids_Net}}</td>
      <td>{{unbl.Nbre_Sacs}}</td>
      <td>{{unbl.Transporteur}}</td>
      <td>{{unbl.Numero_Bl_Tc}}</td>
      <td>{{unbl.Destination}}</td>
      <td>{{unbl.Nom_Chauffeur}}</td>
      <td>{{unbl.Observation}}</td>
      <td>{{unbl.Heure_Depart}}</td>
      <td>{{unbl.Numero_Permis}}</td>
      <td>{{unbl.Date_Permis}}</td>
      <td>{{unbl.Cumul_Nbre}}</td>
      <td>{{unbl.Cumul_Poids}}</td>
      <button type="submit" class="btn btn-danger" style="background-color: red;margin: 8px;width: 45% !important;font-size: 10px;font-family: initial;" (click)="delete_bl(unbl)"> Supprimer</button>
      <button type="submit" class="btn btn-primary" style="width: 40% !important;font-size: 10px;font-family: initial;" (click)="updateBl(unbl.Id_Bl)"> Modifier</button>
    </tr>

  </tbody>
</table ><br/>
<a class="waves-effect waves-light btn"  [style.margin]="' 10px'" (click)="ajouterBl()" >AjouterBl</a>
<!--<ngb-pagination 
  [collectionSize]="40" 
  [(page)]="page" 
  [boundaryLinks]="true" >
</ngb-pagination>-->
<!--<form class="d-flex" role="search" style="float: right;">
  <input class="form-control me-2" type="search" placeholder= "Cumul Nbre" aria-label="Cumul" style="width:35%;">
  <button class="btn btn-outline-secondaire" type="submit" style="margin-top:0.5rem ;font-family: initial;text-transform: initial;font-size: 18px;color: black; ">Cumul Nbre</button>
</form>
<form class="d-flex" role="search" style="float: right;">
  <input class="form-control me-2" type="search" placeholder= "Cumul Poids" aria-label="Cumul" style="width:35%;">
  <button class="btn btn-outline-secondaire" type="submit" style="margin-top:0.5rem ;font-family: initial;text-transform: initial;font-size: 18px;color: black; ">Cumul Poids</button>
</form>-->
<br><br><br>
<!--<nav aria-label="..." style="background: none;width: 20%;">
  <ul class="pagination">
    <li class="page-item ">
      <a class="page-link" href=gbg"./bl" tabindex="-1" aria-disabled="true">Previous</a>
    </li>
    <li class="page-item active"><a class="page-link" href="./bl">1</a></li>
    <li class="page-item " aria-current="page">
      <a class="page-link" href="./bl">2</a>
    </li>
    <li class="page-item"><a class="page-link" href="./bl">3</a></li>
    <li class="page-item">
      <a class="page-link" href="./bl">Next</a>
    </li>
  </ul>
</nav><br>-->
<app-footer></app-footer>