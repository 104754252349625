import { Component, OnInit } from '@angular/core';
import { Produit } from '../produit';
import { Authentification } from '../services/authentification';
import { Route, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../services/api.service';
import { HttpClient } from '@angular/common/http';
@Component({
  selector: 'app-ajouter-produit',
  templateUrl: './ajouter-produit.component.html',
  styleUrls: ['./ajouter-produit.component.css']
})
export class AjouterProduitComponent implements OnInit {
  reactiveForm_add_produit !: FormGroup;
  submitted:boolean=false
  loading_add_produit :boolean=false
  constructor(private router:Router,private formBuilder: FormBuilder,public api:ApiService, private http:HttpClient) { 

  }

  ngOnInit(): void {
    this.init_form()
  }
  init_form() {
    this.reactiveForm_add_produit  = this.formBuilder.group({
            
    Id_produit: ["", Validators.required],
    Nature: ["", Validators.required],
    Origine: ["", Validators.required],
    Etat: ["", Validators.required],
    Nom_Navire: ["", Validators.required],
    Numero_Navire: ["", Validators.required]
        });
}
get f(): any { return this.reactiveForm_add_produit .controls; }
            // validation du formulaire
            onSubmit_add_produit () {
                this.submitted = true;
                console.log(this.reactiveForm_add_produit .value)
                // stop here if form is invalid
                if (this.reactiveForm_add_produit .invalid) {
                    return;
                }
                var produit =this.reactiveForm_add_produit .value
                this.add_produit (produit )
            }
            add_produit(produit: any) {
              this.loading_add_produit = true;
              this.api.taf_post("produit/add", produit, (reponse: any) => {
              this.loading_add_produit = false;
              if (reponse.status) {
                  console.log("Opération effectuée avec succés sur la table produit. Réponse= ", reponse);
                  this.onReset_add_produit()
                  alert("Operation ajoutée avec succés")
                  this.router.navigate(['./produit']);
              } else {
                  console.log("L\'opération sur la table produit a échoué. Réponse= ", reponse);
                  alert("L'opération a echoué")
              }
          }, (error: any) => {
              this.loading_add_produit = false;
          })
      }
            // vider le formulaire
            onReset_add_produit () {
            this.submitted = false;
            this.reactiveForm_add_produit .reset();
          }

/*  AjoutProduit() {
    console.log('Produit', this.produit);
    this.Auth.ajouterProduit(this.produit);
    this.router.navigate(['./produit']);
  }*/

}
