import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../services/api.service';
import { HttpClient } from '@angular/common/http';
@Component({
  selector: 'app-mvt-sortie',
  templateUrl: './mvt-sortie.component.html',
  styleUrls: ['./mvt-sortie.component.css']
})
export class MvtSortieComponent implements OnInit {
mvts:any[];
  constructor(private http:HttpClient, private router: Router,public api:ApiService) { }

  ngOnInit(): void {
    this.get_mvtsortie()
  }
  get_mvtsortie(){
        this.api.taf_get("mvtsortie/get",(reponse:any)=>{
        if(reponse.status){
          this.mvts=reponse.data;
            console.log("Opération effectuée avec succés sur la table mvtsortie. Réponse= ",reponse);
        }else{
            console.log("L\'opération sur la table mvtsortie a échoué. Réponse= ",reponse);
        }
    },
    (error:any)=>{
        //when error
        console.log("Erreur inconnue! ",error);
    })
    }
    ajouterMvtSortie(){
      this.router.navigate(['./ajouterMvtSortie']);
    }
    delete_mvtsortie (mvtsortie : any){
      //transformation des parametres à envoyer
      let formdata = new FormData()
      for (const key in mvtsortie ) {
          formdata.append(key, mvtsortie [key])
      }
  
      let api_url="http://localhost/gs_back_php/mvtsortie/delete" 
      this.http.post(api_url, formdata).subscribe((reponse: any)=>{
          //when success
          if(reponse.status){
          console.log("Opération effectuée avec succés sur la table mvtsortie . Réponse = ",reponse)
          }else{
          console.log("L\'opération sur la table mvtsortie  a échoué. Réponse = ",reponse)
          }
      },
      (error: any)=>{
          //when error
          console.log("Erreur inconnue! ",error)
      })
      }
  
}
