<app-navbar></app-navbar><br/><br/><br/><br/><br/>
<div class="container"> 
  <form [formGroup]="reactiveForm_edit_cargaison " (ngSubmit)="onSubmit_edit_cargaison ()" #form_edit_cargaison ="ngForm" class="row">
    <!-- Id_Cargaison field avec un control de validite -->
    <div class="form-group col-sm-6">
      <label >Id_Cargaison</label>
      <input class="form-control" type="text" formControlName="Id_Cargaison" placeholder="Id_Cargaison" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Id_Cargaison.errors }"/>
      <div *ngIf="submitted && f.Id_Cargaison.errors" class="invalid-feedback">
        <div *ngIf="f.Id_Cargaison.errors.required"> Id_Cargaison est obligatoire </div>
      </div>
    </div>
    <!-- Numero_Declaration field avec un control de validite -->
    <div class="form-group col-sm-6">
      <label >Numero_Declaration</label>
      <input class="form-control" type="text" formControlName="Numero_Declaration" placeholder="Numero_Declaration" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Numero_Declaration.errors }"/>
      <div *ngIf="submitted && f.Numero_Declaration.errors" class="invalid-feedback">
        <div *ngIf="f.Numero_Declaration.errors.required"> Numero_Declaration est obligatoire </div>
      </div>
    </div>
    <!-- Quantite field avec un control de validite -->
    <div class="form-group col-sm-6">
      <label >Quantite</label>
      <input class="form-control" type="text" formControlName="Quantite" placeholder="Quantite" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Quantite.errors }"/>
      <div *ngIf="submitted && f.Quantite.errors" class="invalid-feedback">
        <div *ngIf="f.Quantite.errors.required"> Quantite est obligatoire </div>
      </div>
    </div>
    <!-- Poids field avec un control de validite -->
    <div class="form-group col-sm-6">
      <label >Poids</label>
      <input class="form-control" type="text" formControlName="Poids" placeholder="Poids" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Poids.errors }"/>
      <div *ngIf="submitted && f.Poids.errors" class="invalid-feedback">
        <div *ngIf="f.Poids.errors.required"> Poids est obligatoire </div>
      </div>
    </div>
    <!-- Date_Declaration field avec un control de validite -->
    <div class="form-group col-sm-6">
      <label >Date_Declaration</label>
      <input class="form-control" type="text" formControlName="Date_Declaration" placeholder="Date_Declaration" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Date_Declaration.errors }"/>
      <div *ngIf="submitted && f.Date_Declaration.errors" class="invalid-feedback">
        <div *ngIf="f.Date_Declaration.errors.required"> Date_Declaration est obligatoire </div>
      </div>
    </div>
    <!-- Numero_Navire field avec un control de validite -->
    <div class="form-group col-sm-6">
      <label >Numero_Navire</label>
      <input class="form-control" type="text" formControlName="Numero_Navire" placeholder="Numero_Navire" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Numero_Navire.errors }"/>
      <div *ngIf="submitted && f.Numero_Navire.errors" class="invalid-feedback">
        <div *ngIf="f.Numero_Navire.errors.required"> Numero_Navire est obligatoire </div>
      </div>
    </div>
    <!-- Nom_Navire field avec un control de validite -->
    <div class="form-group col-sm-6">
      <label >Nom_Navire</label>
      <input class="form-control" type="text" formControlName="Nom_Navire" placeholder="Nom_Navire" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Nom_Navire.errors }"/>
      <div *ngIf="submitted && f.Nom_Navire.errors" class="invalid-feedback">
        <div *ngIf="f.Nom_Navire.errors.required"> Nom_Navire est obligatoire </div>
      </div>
    </div>
    <!-- Id_Produit field avec un control de validite -->
    <div class="form-group col-sm-6">
      <label >Id_Produit</label>
      <input class="form-control" type="text" formControlName="Id_Produit" placeholder="Id_Produit" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Id_Produit.errors }"/>
      <div *ngIf="submitted && f.Id_Produit.errors" class="invalid-feedback">
        <div *ngIf="f.Id_Produit.errors.required"> Id_Produit est obligatoire </div>
      </div>
    </div>
    <!-- Nature_Produit field avec un control de validite -->
    <div class="form-group col-sm-6">
      <label >Nature_Produit</label>
      <input class="form-control" type="text" formControlName="Nature_Produit" placeholder="Nature_Produit" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Nature_Produit.errors }"/>
      <div *ngIf="submitted && f.Nature_Produit.errors" class="invalid-feedback">
        <div *ngIf="f.Nature_Produit.errors.required"> Nature_Produit est obligatoire </div>
      </div>
    </div>
  </form>
  <!-- vous pouvez valider votre formulaire n\'importe ou -->
  <div class="text-center m-2">
    <button type="button" class="btn btn-primary m-2" [disabled]="loading_edit_cargaison " (click)="form_edit_cargaison .ngSubmit.emit()">{{loading_edit_cargaison ?"En cours ...":"Modifier"}}</button>
    <button class="btn btn-secondary m-2" type="reset" (click)="onReset_edit_cargaison ()">Vider</button>
  </div>
</div> 
  <br/><br/>
  <app-footer></app-footer>

