import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from '../services/api.service';

@Component({
  selector: 'app-ajouter-acheteur',
  templateUrl: './ajouter-acheteur.component.html',
  styleUrls: ['./ajouter-acheteur.component.css']
})
export class AjouterAcheteurComponent implements OnInit {
  reactiveForm_add_acheteur !: FormGroup;
  submitted:boolean=false
  loading_add_acheteur :boolean=false

  constructor(private formBuilder: FormBuilder,public api:ApiService, private http:HttpClient,private router:Router) { }

  ngOnInit(): void { this.init_form()
  }
  init_form() {
      this.reactiveForm_add_acheteur  = this.formBuilder.group({
          
  Id_Acheteur: ["", Validators.required],
  Nom_Acheteur: ["", Validators.required],
  Bl_Acheteur: ["", Validators.required],
  Numero_Navire: ["", Validators.required],
  Nom_Navire: ["", Validators.required],
  Id_Magasin: ["", Validators.required],
  Nom_Magasin: ["", Validators.required],
  Quantite: ["", Validators.required],
  Poids: ["", Validators.required],
  Variete: ["", Validators.required]
      });
  }

  // acces facile au champs de votre formulaire
  get f(): any { return this.reactiveForm_add_acheteur .controls; }
  // validation du formulaire
  onSubmit_add_acheteur () {
      this.submitted = true;
      console.log(this.reactiveForm_add_acheteur .value)
      // stop here if form is invalid
      if (this.reactiveForm_add_acheteur .invalid) {
          return;
      }
      var acheteur =this.reactiveForm_add_acheteur .value
      this.add_acheteur (acheteur )
  }

  add_acheteur(acheteur: any) {
    this.loading_add_acheteur = true;
    this.api.taf_post("acheteur/add", acheteur, (reponse: any) => {
    this.loading_add_acheteur = false;
    if (reponse.status) {
        console.log("Opération effectuée avec succés sur la table acheteur. Réponse= ", reponse);
        this.onReset_add_acheteur()
        alert("Contenu ajouté avec succés")
        this.router.navigate(['./acheteur'])
    } else {
        console.log("L\'opération sur la table acheteur a échoué. Réponse= ", reponse);
        alert("L'opération a echoué")
    }
}, (error: any) => {
    this.loading_add_acheteur = false;
})
}  
  // vider le formulaire
  onReset_add_acheteur () {
  this.submitted = false;
  this.reactiveForm_add_acheteur .reset();
  }

}
