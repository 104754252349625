import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-accueil-app',
  templateUrl: './accueil-app.component.html',
  styleUrls: ['./accueil-app.component.css']
})
export class AccueilAppComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
