<app-navbar></app-navbar><br/><br/><br/>
<div class="container">
<!--<form (ngSubmit)=" ModifierAcheteur()"  class="sign-in-form" #AcheteurForm="ngForm"class="form">
    <div class="form-group col-sm-6">
      <label for="exampleInputIdAcheteur">Id Acheteur</label>
      <input type="number" name="idAcheteur" [disabled]="true" class="form-control" id="exampleInputidAcheteur" placeholder="idAcheteur" [(ngModel)]=ache.idAcheteur min="1">
    </div>
    <div class="form-group col-sm-6">
      <label for="exampleInputNomAcheteur">Nom Acheteur</label>
      <input type="text" name ="nomAcheteur" class="form-control" id="exampleInputnomAcheteur" placeholder="Nom Acheteur"[(ngModel)]=ache.nomAcheteur min="1">
    </div>
    <div class="form-group col-sm-6">
      <label for="exampleInputblAcheteur">Bl Acheteur</label>
      <input type="text" name="blAcheteur" class="form-control" id="exampleInputblAcheteur" placeholder="blAcheteur"[(ngModel)]=ache.blAcheteur min="1" >
    </div>
    <div class="form-group col-sm-6">
        <label for="exampleInputnumeroNavire">Numero Navire</label>
        <input type="text" name ="numeroNavire" [disabled]="true" class="form-control" id="exampleInputnumeroNavire" placeholder="numeroNavire" [(ngModel)]=ache.numeroNavire min="1" >
      </div>
    <div class="form-group col-sm-6">
      <label for="exampleInputnomNavire">Nom Navire</label>
      <input type="text" name ="nomNavire" class="form-control" id="exampleInputnomNavire" placeholder="nomNavire" [(ngModel)]=ache.nomNavire min="1" >
    </div>
    <div class="form-group col-sm-6">
      <label for="exampleInputidMagasin">Id Magasin</label>
      <input type="number" name ="idMagasin" [disabled]="true" class="form-control" id="exampleInputidMagasin" placeholder="Id Magasin" [(ngModel)]=ache.idMagasin min="1" >
    </div>
  <div class="form-group col-sm-6">
    <label for="exampleInputnomMagasin">Nom Magasin</label>
    <input type="text" name ="nomMagasin" class="form-control" id="exampleInputnomMagasin" placeholder="nomMagasin" [(ngModel)]=ache.nomMagasin min="1" >
  </div>
  <div class="form-group col-sm-6">
    <label for="exampleInputQuantite">Quantite</label>
    <input type="number" name ="quantite" class="form-control" id="exampleInputquantite" placeholder="quantite" [(ngModel)]=ache.quantite min="1" >
  </div>
  <div class="form-group col-sm-6">
    <label for="exampleInputPoids">Poids</label>
    <input type="number" name ="poids" class="form-control" id="exampleInputPoids" placeholder="poids" [(ngModel)]=ache.poids min="1" >
  </div>
  <div class="form-group col-sm-6">
    <label for="exampleInputvariete">Variete</label>
    <input type="text" name ="variete" class="form-control" id="exampleInputvariete" placeholder="Variete" [(ngModel)]=ache.variete min="1" >
  </div>
  <div class="text-center m-2">
    <button  type="submit" class="btn btn-primary" [disabled]="!AcheteurForm.form.valid" >modifier</button>
  </div>
</form>-->
<form [formGroup]="reactiveForm_edit_acheteur " (ngSubmit)="onSubmit_edit_acheteur ()" #form_edit_acheteur ="ngForm" class="row">
	<!-- Id_Acheteur field avec un control de validite -->
	<div class="form-group col-sm-6">
		<label >Id_Acheteur</label>
		<input class="form-control" type="text" formControlName="Id_Acheteur" placeholder="Id_Acheteur" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Id_Acheteur.errors }"/>
		<div *ngIf="submitted && f.Id_Acheteur.errors" class="invalid-feedback">
			<div *ngIf="f.Id_Acheteur.errors.required"> Id_Acheteur est obligatoire </div>
		</div>
	</div>
	<!-- Nom_Acheteur field avec un control de validite -->
	<div class="form-group col-sm-6">
		<label >Nom_Acheteur</label>
		<input class="form-control" type="text" formControlName="Nom_Acheteur" placeholder="Nom_Acheteur" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Nom_Acheteur.errors }"/>
		<div *ngIf="submitted && f.Nom_Acheteur.errors" class="invalid-feedback">
			<div *ngIf="f.Nom_Acheteur.errors.required"> Nom_Acheteur est obligatoire </div>
		</div>
	</div>
	<!-- Bl_Acheteur field avec un control de validite -->
	<div class="form-group col-sm-6">
		<label >Bl_Acheteur</label>
		<input class="form-control" type="text" formControlName="Bl_Acheteur" placeholder="Bl_Acheteur" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Bl_Acheteur.errors }"/>
		<div *ngIf="submitted && f.Bl_Acheteur.errors" class="invalid-feedback">
			<div *ngIf="f.Bl_Acheteur.errors.required"> Bl_Acheteur est obligatoire </div>
		</div>
	</div>
	<!-- Numero_Navire field avec un control de validite -->
	<div class="form-group col-sm-6">
		<label >Numero_Navire</label>
		<input class="form-control" type="text" formControlName="Numero_Navire" placeholder="Numero_Navire" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Numero_Navire.errors }"/>
		<div *ngIf="submitted && f.Numero_Navire.errors" class="invalid-feedback">
			<div *ngIf="f.Numero_Navire.errors.required"> Numero_Navire est obligatoire </div>
		</div>
	</div>
	<!-- Nom_Navire field avec un control de validite -->
	<div class="form-group col-sm-6">
		<label >Nom_Navire</label>
		<input class="form-control" type="text" formControlName="Nom_Navire" placeholder="Nom_Navire" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Nom_Navire.errors }"/>
		<div *ngIf="submitted && f.Nom_Navire.errors" class="invalid-feedback">
			<div *ngIf="f.Nom_Navire.errors.required"> Nom_Navire est obligatoire </div>
		</div>
	</div>
	<!-- Id_Magasin field avec un control de validite -->
	<div class="form-group col-sm-6">
		<label >Id_Magasin</label>
		<input class="form-control" type="text" formControlName="Id_Magasin" placeholder="Id_Magasin" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Id_Magasin.errors }"/>
		<div *ngIf="submitted && f.Id_Magasin.errors" class="invalid-feedback">
			<div *ngIf="f.Id_Magasin.errors.required"> Id_Magasin est obligatoire </div>
		</div>
	</div>
	<!-- Nom_Magasin field avec un control de validite -->
	<div class="form-group col-sm-6">
		<label >Nom_Magasin</label>
		<input class="form-control" type="text" formControlName="Nom_Magasin" placeholder="Nom_Magasin" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Nom_Magasin.errors }"/>
		<div *ngIf="submitted && f.Nom_Magasin.errors" class="invalid-feedback">
			<div *ngIf="f.Nom_Magasin.errors.required"> Nom_Magasin est obligatoire </div>
		</div>
	</div>
	<!-- Quantite field avec un control de validite -->
	<div class="form-group col-sm-6">
		<label >Quantite</label>
		<input class="form-control" type="text" formControlName="Quantite" placeholder="Quantite" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Quantite.errors }"/>
		<div *ngIf="submitted && f.Quantite.errors" class="invalid-feedback">
			<div *ngIf="f.Quantite.errors.required"> Quantite est obligatoire </div>
		</div>
	</div>
	<!-- Poids field avec un control de validite -->
	<div class="form-group col-sm-6">
		<label >Poids</label>
		<input class="form-control" type="text" formControlName="Poids" placeholder="Poids" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Poids.errors }"/>
		<div *ngIf="submitted && f.Poids.errors" class="invalid-feedback">
			<div *ngIf="f.Poids.errors.required"> Poids est obligatoire </div>
		</div>
	</div>
	<!-- Variete field avec un control de validite -->
	<div class="form-group col-sm-6">
		<label >Variete</label>
		<input class="form-control" type="text" formControlName="Variete" placeholder="Variete" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Variete.errors }"/>
		<div *ngIf="submitted && f.Variete.errors" class="invalid-feedback">
			<div *ngIf="f.Variete.errors.required"> Variete est obligatoire </div>
		</div>
	</div>
</form> 
<!-- vous pouvez valider votre formulaire n\'importe ou -->
<div class="text-center m-2">
	<button type="button" class="btn btn-primary m-2" [disabled]="loading_edit_acheteur " (click)="form_edit_acheteur .ngSubmit.emit()">{{loading_edit_acheteur ?"En cours ...":"Modifier"}}</button>
	<button class="btn btn-secondary m-2" type="reset" (click)="onReset_edit_acheteur ()">Vider</button>
</div>
<br/><br/>
</div> 
<app-footer></app-footer>

