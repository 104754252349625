import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Magasin } from '../magasin';
import { ApiService } from '../services/api.service';
import { Authentification } from '../services/authentification';

@Component({
  selector: 'app-magasin',
  templateUrl: './magasin.component.html',
  styleUrls: ['./magasin.component.css']
})
export class MagasinComponent implements OnInit {
magasins:any[];
filterTerm:any;
  constructor(private http:HttpClient, private router: Router,public api:ApiService) {
    this.magasins=[];
   }

  ngOnInit(): void {
    this.get_magasin()
  }
  get_magasin(){

    this.api.taf_get("magasin/get",(reponse:any)=>{
        //when success
        if(reponse.status){
          this.magasins=reponse.data
            console.log("Opération effectuée avec succés sur la table magasin. Réponse= ",reponse);
        }else{
            console.log("L\'opération sur la table magasin a échoué. Réponse= ",reponse);
        }
    },
    (error:any)=>{
        //when error
        console.log("Erreur inconnue! ",error);
    })
    }

  ajouterMagasins(){
    this.router.navigate(['./ajouterMagasin']);
  }
  
    delete_magasin (magasin : any){
      //transformation des parametres à envoyer
      let formdata = new FormData()
      for (const key in magasin ) {
          formdata.append(key, magasin [key])
      }
  
      let api_url="http://localhost/gs_back_php/magasin/delete" 
      this.http.post(api_url, formdata).subscribe((reponse: any)=>{
          //when success
          if(reponse.status){
          console.log("Opération effectuée avec succés sur la table magasin . Réponse = ",reponse)
          this.router.navigate(['./magasin']);
        }
          else{
          console.log("L\'opération sur la table magasin  a échoué. Réponse = ",reponse)
          }
      },
      (error: any)=>{
          //when error
          console.log("Erreur inconnue! ",error)
      })
      }
  
    updateMagasin(Id_Magasin:number) {
      let url = './updateMagasin/';
      url=url+Id_Magasin;
      this.router.navigate([url]);
  }
}
