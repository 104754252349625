  <app-navbar></app-navbar><br/><br/><br/><br/>
  <!--<form (ngSubmit)=" AjoutClient()"  class="sign-in-form" #ClientForm="ngForm"class="form">
    <div class="form-group">
      <label for="exampleInputIdProduit">IdClient</label>
      <input type="number" name="idClient"  class="form-control" id="exampleInputidClient" placeholder="idClient" [(ngModel)]=client.idClient min="1">
    </div>
    <div class="form-group">
      <label for="exampleInputNomNavire">Nom Client</label>
      <input type="text" name ="nomClient" class="form-control" id="exampleInputnomClient" placeholder="Nom Client"[(ngModel)]=client.nomClient min="1">
    </div>
    <div class="form-group">
      <label for="exampleInputAdresse">Adresse</label>
      <input type="text" name="adresse" class="form-control" id="exampleInputadresse" placeholder="Adresse"[(ngModel)]=client.adresse min="1" >
    </div>
    <div class="form-group">
        <label for="exampleInputLargeurNavire">Numero Navire</label>
        <input type="text" name ="numeroNavire" class="form-control" id="exampleInputnumeroNavire" placeholder="numeroNavire" [(ngModel)]=client.numeroNavire min="1" >
      </div>
    <div class="form-group">
      <label for="exampleInputLargeurNavire">Nom navire</label>
      <input type="text" name ="nomNavire" class="form-control" id="exampleInputnomNavire" placeholder="nomNavire" [(ngModel)]=client.nomNavire min="1" >
    </div>
    <div class="form-group">
      <label for="exampleInputLargeurNavire">Id Produit</label>
      <input type="text" name ="numeroNavire" class="form-control" id="exampleInputnumeroNavire" placeholder="idProduit" [(ngModel)]=client.idProduit min="1" >
    </div>
  <div class="form-group">
    <label for="exampleInputLargeurNavire">Nature Produit</label>
    <input type="text" name ="nomNavire" class="form-control" id="exampleInputnomNavire" placeholder="natutreProduit" [(ngModel)]=client.natureProduit min="1" >
  </div>
  <div class="form-group">
    <label for="exampleInputTelephone">Telephone</label>
    <input type="text" name ="telephone" class="form-control" id="exampleInputtelephone" placeholder="Telephone" [(ngModel)]=client.telephone min="1" >
  </div>
    <button type="submit" class="btn btn-primary" [disabled]="!ClientForm.form.valid">Ajouter</button>

</form><br/><br/>-->
<div class="container">
<form  [formGroup]="reactiveForm_add_client " (ngSubmit)="onSubmit_add_client ()" #form_add_client ="ngForm" class="row">
        
  <!-- Id_Client field avec un control de validite -->
  <div class="form-group col-sm-6">
    <label >Id Client</label>
    <input class="form-control" type="number"  formControlName="Id_Client"  placeholder="Id Client" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Id_Client.errors }"/>
    <div *ngIf="submitted && f.Id_Client.errors" class="invalid-feedback">
        <div *ngIf="f.Id_Client.errors.required"> Id_Client est obligatoire </div>
    </div>
  </div>		
  <!-- Nom_Client field avec un control de validite -->
  <div class="form-group col-sm-6">
    <label >Nom Client</label>
    <input class="form-control" type="text"  formControlName="Nom_Client"  placeholder="Nom Client" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Nom_Client.errors }"/>
    <div *ngIf="submitted && f.Nom_Client.errors" class="invalid-feedback">
        <div *ngIf="f.Nom_Client.errors.required"> Nom_Client est obligatoire </div>
    </div>
  </div>		
  <!-- Adresse field avec un control de validite -->
  <div class="form-group col-sm-6">
    <label >Adresse</label>
    <input class="form-control" type="text"  formControlName="Adresse"  placeholder="Adresse" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Adresse.errors }"/>
    <div *ngIf="submitted && f.Adresse.errors" class="invalid-feedback">
        <div *ngIf="f.Adresse.errors.required"> Adresse est obligatoire </div>
    </div>
  </div>		
  <!-- Numero_Navire field avec un control de validite -->
  <div class="form-group col-sm-6">
    <label >Numero Navire</label>
    <input class="form-control" type="number"  formControlName="Numero_Navire"  placeholder="Numero Navire" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Numero_Navire.errors }"/>
    <div *ngIf="submitted && f.Numero_Navire.errors" class="invalid-feedback">
        <div *ngIf="f.Numero_Navire.errors.required"> Numero_Navire est obligatoire </div>
    </div>
  </div>		
  <!-- Nom_Navire field avec un control de validite -->
  <div class="form-group col-sm-6">
    <label >Nom Navire</label>
    <input class="form-control" type="text"  formControlName="Nom_Navire"  placeholder="Nom Navire" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Nom_Navire.errors }"/>
    <div *ngIf="submitted && f.Nom_Navire.errors" class="invalid-feedback">
        <div *ngIf="f.Nom_Navire.errors.required"> Nom_Navire est obligatoire </div>
    </div>
  </div>		
  <!-- Id_Produit field avec un control de validite -->
  <div class="form-group col-sm-6">
    <label >Id Produit</label>
    <input class="form-control" type="number"  formControlName="Id_Produit"  placeholder="Id Produit" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Id_Produit.errors }"/>
    <div *ngIf="submitted && f.Id_Produit.errors" class="invalid-feedback">
        <div *ngIf="f.Id_Produit.errors.required"> Id_Produit est obligatoire </div>
    </div>
  </div>		
  <!-- Nature_Produit field avec un control de validite -->
  <div class="form-group col-sm-6">
    <label >Nature Produit</label>
    <input class="form-control" type="text"  formControlName="Nature_Produit"  placeholder="Nature Produit" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Nature_Produit.errors }"/>
    <div *ngIf="submitted && f.Nature_Produit.errors" class="invalid-feedback">
        <div *ngIf="f.Nature_Produit.errors.required"> Nature_Produit est obligatoire </div>
    </div>
  </div>		
  <!-- Telephone field avec un control de validite -->
  <div class="form-group col-sm-6">
    <label >Telephone</label>
    <input class="form-control" type="number"  formControlName="Telephone"  placeholder="Telephone" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Telephone.errors }"/>
    <div *ngIf="submitted && f.Telephone.errors" class="invalid-feedback">
        <div *ngIf="f.Telephone.errors.required"> Telephone est obligatoire </div>
    </div>
  </div>
</form>
<!-- vous pouvez valider votre formulaire n\'importe ou -->

<div class="text-center m-2">
<button type="button" class="btn btn-primary m-2" [disabled]="loading_add_client "
  (click)="form_add_client .ngSubmit.emit()">{{loading_add_client ?"En cours ...":"Ajouter"}}</button>
<button class="btn btn-secondary m-2" type="reset" (click)="onReset_add_client ()">Vider</button>
</div>
</div>
<app-footer></app-footer>