import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { ApiService } from '../services/api.service';
import { Authentification } from '../services/authentification';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-update-produit',
  templateUrl: './update-produit.component.html',
  styleUrls: ['./update-produit.component.css']
})
export class UpdateProduitComponent implements OnInit {
  reactiveForm_edit_produit !: FormGroup; 
  submitted: boolean = false 
  loading_edit_produit: boolean = false
  Id_produit:number=0;
  produit_to_edit: any = {} 
  constructor(private formBuilder: FormBuilder,private route:Router, public api: ApiService, private http: HttpClient,private a_route:ActivatedRoute) {
     // récupération des paramètres depuis l'URL 
     a_route.params.subscribe((params:any)=>{ 
      if (params["Id_produit"]) {
      // si le prametre id_produit est présent sur l'URL 
      this.Id_produit=params["Id_produit"] 
      this.get_produit(this.Id_produit) } 
      else {// en cas d'absence 
      alert("L'URL est incorrect. Il manque l'information 'id_produit'") } }) }
      ngOnInit(): void { this.init_form() }
      // initialisation du formulaire
      init_form() { this.reactiveForm_edit_produit = this.formBuilder.group( { 
      Id_produit: ["", Validators.required],
      Nature: ["", Validators.required],
      Origine: ["", Validators.required], 
      Etat: ["", Validators.required], 
      Nom_Navire: ["", Validators.required], 
      Numero_Navire: ["", Validators.required] });
       } 
      // récupération des details de l'élément à modifier 
      get_produit(Id_produit: number) { 
      this.api.taf_get("produit/get?Id_produit=" + Id_produit, (reponse: any) => { 
        //when success 
        if (reponse.status) { 
          console.log("Opération effectuée avec succés sur la table produit. Réponse= ", reponse); 
          if (reponse.data.length>0)
            { this.produit_to_edit = reponse.data[0] 
          this.update_form(this.produit_to_edit) } 
          else { 
            alert("Détails de l'élément à modifier introuvable") } } 
          else { console.log("L\'opération sur la table produit a échoué. Réponse= ", reponse); } },
              (error: any) => { 
      //when error 
      console.log("Erreur inconnue! ", error); }) } 
      // mise à jour du formulaire
      update_form(produit_to_edit:any) {
        this.reactiveForm_edit_produit = this.formBuilder.group({ 
        Id_produit: [produit_to_edit.Id_produit, Validators.required], 
        Nature: [produit_to_edit.Nature, Validators.required], 
        Origine: [produit_to_edit.Origine,  Validators.required],
        Etat: [produit_to_edit.Etat, Validators.required],
        Nom_Navire: [produit_to_edit.Nom_Navire, Validators.required], 
        Numero_Navire: [produit_to_edit.Numero_Navire,Validators.required] }); }
            // acces facile au champs de votre formulaire
        get f(): any { return this.reactiveForm_edit_produit.controls; } 
        // validation du formulaire
          onSubmit_edit_produit() { 
          this.submitted = true; 
          console.log(this.reactiveForm_edit_produit.value)
            // stop here if form is invalid
            if (this.reactiveForm_edit_produit.invalid) { return; }
             var produit = this.reactiveForm_edit_produit.value 
            this.edit_produit({ condition:JSON.stringify({id_produit:this.Id_produit}), 
            data:JSON.stringify(produit) }) 
        } 
        // vider le formulaire
        onReset_edit_produit() { 
                  this.submitted = false; this.reactiveForm_edit_produit.reset(); 
                }
        edit_produit(produit: any) {
          this.loading_edit_produit = true;
          this.api.taf_post("produit/edit", produit, (reponse: any) => { 
            this.loading_edit_produit = false;
            if (reponse.status) {
              console.log("Opération effectuée avec succés sur la table produit. Réponse= ", reponse); 
              this.onReset_edit_produit() 
              alert("Contenu modifié avec succés") 
              this.route.navigate(['./produit'])
            } 
            else {
              console.log("L\'opération sur la table produit a échoué. Réponse= ", reponse);
              alert("L'opération a echoué") } },
              (error: any) => { this.loading_edit_produit = false; }) 
            }
  }
