<app-navbar></app-navbar><br/><br/><br/><br/>
  <!--<form (ngSubmit)=" AjoutMagasin()"  class="sign-in-form" #MagasinForm="ngForm"class="form">
    <div class="form-group">
      <label for="exampleInputIdProduit">IdMagasin</label>
      <input type="number" name="idMagasin"  class="form-control" id="exampleInputidMagasin" placeholder="idMagasin" [(ngModel)]=magasin.idMagasin min="1">
    </div>
    <div class="form-group">
      <label for="exampleInputNomNavire">Nom Magasin</label>
      <input type="text" name ="nomMagasin" class="form-control" id="exampleInputnomMagasin" placeholder="nomMagasin"[(ngModel)]=magasin.nomMagasin min="1">
    </div>
    <div class="form-group">
      <label for="exampleInputAdresse">Adresse</label>
      <input type="text" name="adresse" class="form-control" id="exampleInputadresse" placeholder="Adresse"[(ngModel)]=magasin.adresse min="1" >
    </div>
    <div class="form-group">
        <label for="exampleInputTelephone">Contact</label>
        <input type="text" name ="contact" class="form-control" id="exampleInputcontact" placeholder="contact" [(ngModel)]=magasin.contact min="1" >
    </div>
    <div class="form-group">
        <label for="exampleInputTelephone">Proprietaire</label>
        <input type="text" name ="proprietaire" class="form-control" id="exampleInputproprietaire" placeholder="proprietaire" [(ngModel)]=magasin.proprietaire min="1" >
    </div>
    <div class="form-group">
        <label for="exampleInputLargeurNavire">Magasinien</label>
        <input type="text" name ="magasinien" class="form-control" id="exampleInputmagasinien" placeholder="magasinien" [(ngModel)]=magasin.magasinien min="1" >
      </div>
      <div class="form-group">
        <label for="exampleInputLargeurNavire">Numero Navire</label>
        <input type="text" name ="numeroNavire" class="form-control" id="exampleInputnumeroNavire" placeholder="numeroNavire" [(ngModel)]=magasin.numeroNavire min="1" >
      </div>
    <div class="form-group">
      <label for="exampleInputLargeurNavire">Nom navire</label>
      <input type="text" name ="nomNavire" class="form-control" id="exampleInputnomNavire" placeholder="nomNavire" [(ngModel)]=magasin.nomNavire min="1" >
    </div>
    <div class="form-group">
        <label for="exampleInputLargeurNavire">Id Produit</label>
        <input type="number" name ="idProduit" class="form-control" id="exampleInputidProduit" placeholder="idProduit" [(ngModel)]=magasin.idProduit min="1" >
      </div>
  <div class="form-group">
    <label for="exampleInputLargeurNavire">Nature Produit</label>
    <input type="text" name ="nomNavire" class="form-control" id="exampleInputnomNavire" placeholder="natutreProduit" [(ngModel)]=magasin.natureProduit min="1" >
  </div>
    <button type="submit" class="btn btn-primary" [disabled]="!MagasinForm.form.valid">Ajouter</button>

</form><br/><br/>-->
<div class="container">
<form  [formGroup]="reactiveForm_add_magasin " (ngSubmit)="onSubmit_add_magasin ()" #form_add_magasin ="ngForm" class="row">
        
  <!-- Id_Magasin field avec un control de validite -->
  <div class="form-group col-sm-6">
    <label >Id Magasin</label>
    <input class="form-control" type="text"  formControlName="Id_Magasin"  placeholder="Id Magasin" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Id_Magasin.errors }"/>
    <div *ngIf="submitted && f.Id_Magasin.errors" class="invalid-feedback">
        <div *ngIf="f.Id_Magasin.errors.required"> Id_Magasin est obligatoire </div>
    </div>
  </div>		
  <!-- Nom_Magasin field avec un control de validite -->
  <div class="form-group col-sm-6">
    <label >Nom Magasin</label>
    <input class="form-control" type="text"  formControlName="Nom_Magasin"  placeholder="Nom Magasin" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Nom_Magasin.errors }"/>
    <div *ngIf="submitted && f.Nom_Magasin.errors" class="invalid-feedback">
        <div *ngIf="f.Nom_Magasin.errors.required"> Nom_Magasin est obligatoire </div>
    </div>
  </div>		
  <!-- Adresse field avec un control de validite -->
  <div class="form-group col-sm-6">
    <label >Adresse</label>
    <input class="form-control" type="text"  formControlName="Adresse"  placeholder="Adresse" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Adresse.errors }"/>
    <div *ngIf="submitted && f.Adresse.errors" class="invalid-feedback">
        <div *ngIf="f.Adresse.errors.required"> Adresse est obligatoire </div>
    </div>
  </div>		
  <!-- Contact field avec un control de validite -->
  <div class="form-group col-sm-6">
    <label >Contact</label>
    <input class="form-control" type="text"  formControlName="Contact"  placeholder="Contact" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Contact.errors }"/>
    <div *ngIf="submitted && f.Contact.errors" class="invalid-feedback">
        <div *ngIf="f.Contact.errors.required"> Contact est obligatoire </div>
    </div>
  </div>		
  <!-- Proprietaire field avec un control de validite -->
  <div class="form-group col-sm-6">
    <label >Proprietaire</label>
    <input class="form-control" type="text"  formControlName="Proprietaire"  placeholder="Proprietaire" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Proprietaire.errors }"/>
    <div *ngIf="submitted && f.Proprietaire.errors" class="invalid-feedback">
        <div *ngIf="f.Proprietaire.errors.required"> Proprietaire est obligatoire </div>
    </div>
  </div>		
  <!-- Magasinien field avec un control de validite -->
  <div class="form-group col-sm-6">
    <label >Magasinien</label>
    <input class="form-control" type="text"  formControlName="Magasinien"  placeholder="Magasinien" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Magasinien.errors }"/>
    <div *ngIf="submitted && f.Magasinien.errors" class="invalid-feedback">
        <div *ngIf="f.Magasinien.errors.required"> Magasinien est obligatoire </div>
    </div>
  </div>		
  <!-- Numero_Navire field avec un control de validite -->
  <div class="form-group col-sm-6">
    <label >Numero Navire</label>
    <input class="form-control" type="text"  formControlName="Numero_Navire"  placeholder="Numero Navire" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Numero_Navire.errors }"/>
    <div *ngIf="submitted && f.Numero_Navire.errors" class="invalid-feedback">
        <div *ngIf="f.Numero_Navire.errors.required"> Numero_Navire est obligatoire </div>
    </div>
  </div>		
  <!-- Nom_Navire field avec un control de validite -->
  <div class="form-group col-sm-6">
    <label >Nom Navire</label>
    <input class="form-control" type="text"  formControlName="Nom_Navire"  placeholder="Nom Navire" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Nom_Navire.errors }"/>
    <div *ngIf="submitted && f.Nom_Navire.errors" class="invalid-feedback">
        <div *ngIf="f.Nom_Navire.errors.required"> Nom_Navire est obligatoire </div>
    </div>
  </div>		
  <!-- Id_Produit field avec un control de validite -->
  <div class="form-group col-sm-6">
    <label >Id Produit</label>
    <input class="form-control" type="text"  formControlName="Id_Produit"  placeholder="Id Produit" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Id_Produit.errors }"/>
    <div *ngIf="submitted && f.Id_Produit.errors" class="invalid-feedback">
        <div *ngIf="f.Id_Produit.errors.required"> Id_Produit est obligatoire </div>
    </div>
  </div>		
  <!-- Nature_Produit field avec un control de validite -->
  <div class="form-group col-sm-6">
    <label >Nature Produit</label>
    <input class="form-control" type="text"  formControlName="Nature_Produit"  placeholder="Nature Produit" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Nature_Produit.errors }"/>
    <div *ngIf="submitted && f.Nature_Produit.errors" class="invalid-feedback">
        <div *ngIf="f.Nature_Produit.errors.required"> Nature_Produit est obligatoire </div>
    </div>
  </div>
</form>
<!-- vous pouvez valider votre formulaire n\'importe ou -->

<div class="text-center m-2">
<button type="button" class="btn btn-primary m-2" [disabled]="loading_add_magasin "
  (click)="form_add_magasin .ngSubmit.emit()">{{loading_add_magasin ?"En cours ...":"Ajouter"}}</button>
<button class="btn btn-secondary m-2" type="reset" (click)="onReset_add_magasin ()">Vider</button>
</div>
</div>
<app-footer></app-footer>

