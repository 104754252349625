<app-navbar></app-navbar>
<br/><br/><br/><br/><br/><br/>
<div class="form-group" style="float:right">
  <input type="text" class="form-control" name="filterTerm" style="width: 250px;border: 2px solid #235f83;text-align: center;"
    [(ngModel)]="filterTerm" placeholder="Recherche"
  />
</div>
<table class="centered highlight datatable table table-striped table-bordered" style="border: 2px solid #235f83;padding-left: 2px;margin-right: 2px;">
    <thead>
      <tr>
          <th>Id Cargaison</th>
          <th>Numero Declaration</th>
          <th>Quantite Déclarée</th>
          <th>Poids Déclaré</th>
          <th>Date Declaration</th>
          <th>Numero Navire</th>
          <th>Nom Navire</th>
          <th>Id Produit</th>
          <th>Nature Produit</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let unCargaison of cargaisons |filter:filterTerm " >
        <td>{{unCargaison.Id_Cargaison}}</td>
        <td>{{unCargaison.Numero_Declaration}}</td>
        <td>{{unCargaison.Quantite}}</td>
        <td>{{unCargaison.Poids}}</td>
        <td>{{unCargaison.Date_Declaration}}</td>
        <td>{{unCargaison.Numero_Navire}}</td>
        <td>{{unCargaison.Nom_Navire}}</td>
        <td>{{unCargaison.Id_Produit}}</td>
        <td>{{unCargaison.Nature_Produit}}</td>
        <button type="submit" class="btn btn-danger" style="background-color: red;margin: 8px;font-family: initial;" (click)="delete_cargaison(unCargaison)" > Supprimer</button>
        <button type="submit" class="btn btn-primary" style="font-family: initial;" (click)="updateCargaison(unCargaison.Id_Cargaison)" > Modifier</button>
      </tr>
    </tbody>
  </table ><br/>
  <a class="waves-effect waves-light btn"  [style.margin]="' 10px'" (click)="ajouterCargaison()" >AjouterCargaison</a>
<br/><br/>
<app-footer></app-footer>