import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  local_storage_prefixe = "jknjnjnuhhuiuiifjuijiejiojz";
  taf_base_url = "https://stock.mltsa.sn/taf/";
  // taf_base_url = "http://localhost/gs_back_php/";
  network: any = {
    status: true,
    message: "Aucun probléme détecté",
  }
  token: any = {
    token_key: null,
    token_decoded: null,
    user_connected: null,
    is_expired: null,
    date_expiration: null
  }
  les_bl:any=[];
  les_navires:any=[];
  les_produits:any[];
  les_clients:any[];
  les_magasins:any[];

  constructor(private http: HttpClient, private route: Router) { }
  get_from_local_storage(key: string): any {
    let u: any = localStorage.getItem(this.local_storage_prefixe + key);
    return JSON.parse(u)
  }
  save_on_local_storage(key: string, value: any): void {
    localStorage.setItem(this.local_storage_prefixe + key, JSON.stringify(value));
  }
  delete_from_local_storage(key: string) {
    localStorage.setItem(this.local_storage_prefixe + key, 'null');
  }
  async taf_get(path: string, on_success: Function, on_error: Function) {
    let api_url = this.taf_base_url + path;
    // const httpOptions = {
    //   headers: new HttpHeaders({
    //     Authorization: "Bearer " + await this.get_token(),
    //   })
    // };

    this.http.get(api_url).subscribe(
      (reponse: any) => {// on success
        on_success(reponse)
      },
      (error: any) => {// on error
        this.on_taf_get_error(error, path)
      }
    )
  }
  on_taf_get_error(error: any, path: string) {
    this.network.status = false;
    this.network.message = error
    alert("Vérifiez votre connexion")
  }
  async taf_post(path: string, params: any, on_success: Function, on_error: Function) {
    let api_url = this.taf_base_url + path;
    let formdata = new FormData()
    for (const key in params) {
      formdata.append(key, params[key])
    }
    // const httpOptions = {
    //   headers: new HttpHeaders({
    //     Authorization: "Bearer " + await this.get_token(),
    //   })
    // };
    this.http.post(api_url, formdata).subscribe(
      (reponse: any) => {// on success
        on_success(reponse)
      },
      (error: any) => {// on error
        this.on_taf_post_error(error, path, on_error)
      }
    )
  }
  on_taf_post_error(error: any, path: string, on_error: any) {
    this.network.status = false;
    this.network.message = error
    on_error()
    alert("Vérifiez votre connexion")
  }
  
}