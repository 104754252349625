<!DOCTYPE html>
<html lang="en">
    <head>
        <title>GestionStock</title>
        <meta charset="utf-8">
        <meta name="viewport" content="width=device-width, initial-scale=1">
        <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.6.1/dist/css/bootstrap.min.css">
        <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">
        <script src="https://cdn.jsdelivr.net/npm/jquery@3.6.0/dist/jquery.slim.min.js"></script>
        <script src="https://cdn.jsdelivr.net/npm/popper.js@1.16.1/dist/umd/popper.min.js"></script>
        <script src="https://cdn.jsdelivr.net/npm/bootstrap@4.6.1/dist/js/bootstrap.bundle.min.js"></script>
        <script src="https://code.iconify.design/iconify-icon/1.0.0/iconify-icon.min.js"></script>
      </head>
<body>
      <!-- Footer -->
<footer class="text-center text-lg-start text-muted" style="background: #235f83;">
    <!-- Section: Social media -->
    <section class="d-flex justify-content-center justify-content-lg-between p-4 border-bottom">
      <div>
        <a href="" class="me-4 link-secondary">
          <i class="fab fa-facebook-f"></i>
        </a>
        <a href="" class="me-4 link-secondary">
          <i class="fab fa-twitter"></i>
        </a>
        <a href="" class="me-4 link-secondary">
          <i class="fab fa-google"></i>
        </a>
        <a href="" class="me-4 link-secondary">
          <i class="fab fa-instagram"></i>
        </a>
        <a href="" class="me-4 link-secondary">
          <i class="fab fa-linkedin"></i>
        </a>
        <a href="" class="me-4 link-secondary">
          <i class="fab fa-github"></i>
        </a>
      </div>
      <!-- Right -->
    </section>
    <!-- Section: Social media -->
  
    <!-- Section: Links  -->
    <section class="">
      <div class="container text-center text-md-start mt-5">
        <!-- Grid row -->
        <div class="row mt-3">
          <!-- Grid column -->
          <div class="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
            <!-- Content -->
            <a  href="/AccueilApp" class="text-reset alink"><img src="/assets/Capture1.PNG" width="210px" height="110px;" style="margin-left:-3.5rem;"/></a><br/><br/>
            <p style="color: white!important;font-size: 15px;text-align: justify;">
              La  Manutation Logistique Transport SA (MLT SA) est une société anonyme S.A. créée en 2004
            </p>
          </div>
          <!-- Grid column -->
  
          <!-- Grid column -->
          <div class="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
            <!-- Links -->
            <h6 class="text-uppercase fw-bold mb-4 h6">
              <img src="/assets/icons8-instagram-check-mark-48 (2).png" width="30px" height="30px"/>
                <a  href="/AccueilApp" class="text-reset alink">Accueil</a>
            </h6>
   
            <h6 class="text-uppercase fw-bold mb-4 h6">
              <img src="/assets/icons8-instagram-check-mark-48 (2).png" width="30px" height="30px"/>
              <a  href="/produit" class="text-reset alink">Produit</a>
          </h6>
          <h6 class="text-uppercase fw-bold mb-4 h6">
            <img src="/assets/icons8-instagram-check-mark-48 (2).png" width="30px" height="30px"/>
            <a  href="/clientAcheteur" class="text-reset alink">ClientAcheteur</a>
        </h6>
          </div>
          <!-- Grid column -->
  
          <!-- Grid column -->
          <div class="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
            <!-- Links -->
            <h6 class="text-uppercase fw-bold mb-4 h6">
              <img src="/assets/icons8-instagram-check-mark-48 (2).png" width="30px" height="30px"/>
                <a href="/client" class="text-reset alink">Client</a>
            </h6>
            <h6 class="text-uppercase fw-bold mb-4 h6"  >
              <img src="/assets/icons8-instagram-check-mark-48 (2).png" width="30px" height="30px" />
              <a href="/declaration" class="text-reset alink">Déclaration</a>
          </h6>
          <h6 class="text-uppercase fw-bold mb-4 h6">
            <img src="/assets/icons8-instagram-check-mark-48 (2).png" width="30px" height="30px"/>
            <a href="/magasin" class="text-reset alink">Magasin</a>
          </h6>
         
          </div>
          <div class="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
            <h6 class="text-uppercase fw-bold mb-4 h6" >
              <img src="/assets/icons8-instagram-check-mark-48 (2).png" width="30px" height="30px"/>
              <a  href="/cargaison" class="text-reset alink">Cargaison</a>
          </h6>
          <h6 class="text-uppercase fw-bold mb-4 h6">
            <img src="/assets/icons8-instagram-check-mark-48 (2).png" width="30px" height="30px"/>
              <a   href="/navire" class="text-reset alink">Navire</a>
          </h6>
          <h6 class="text-uppercase fw-bold mb-4 h6">
            <img src="/assets/icons8-instagram-check-mark-48 (2).png" width="30px" height="30px"/>
            <a  href="/bl" class="text-reset alink">BL</a>
        </h6>
          </div>
          <!-- Grid column -->
  
          <!-- Grid column -->
          <div class="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
            <!-- Links -->
            <h6 class="text-uppercase fw-bold mb-4 " style="color: White !important;font-weight:bold;font-size:15px;text-align: start;">Contact</h6>
            <p class="p1">
              <img src="/assets/icons8-location-64.png" height="24px" width="24px"/>
              MLT SA, 33 Bd de la Libération en face Môle 1 Port Autonome de Dakar</p>
            <p class="p1">
             <img src="/assets/icons8-mail-48.png " width="24px" height="24px"/>
              contact@mltsa.sn
            </p>
            <p class="p1"><img src="/assets/icons8-call-24.png"/> + (221) 33 889 92 92</p>
          </div>
          <!-- Grid column -->
        </div>
        <!-- Grid row -->
      </div>
    </section>

</footer>

</body>
</html>
