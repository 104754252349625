
  <app-navbar></app-navbar>
  <br/><br/><br/><br/><br/><br/>
  <div class="form-group" style="float:right">
    <input type="text" class="form-control" name="filterTerm" style="width: 250px;border: 2px solid #235f83;text-align: center;"
      [(ngModel)]="filterTerm" placeholder="Recherche"
    />
  </div>
    <table class="centered highlight datatable table table-striped table-bordered" style="border: 2px solid #235f83;padding-left: 2px;margin-right: 2px;">
      <thead>
        <tr>
            <th>Id Declaration</th>   
            <th>Numero Declaration</th>
            <th>Id Cargaison</th>
            <th>Quantite Débarquée</th>
            <th>Poids Débarqué</th>
            <th>Quantite Restante</th>
            <th>Poids Restant</th>
            <th>Date Jour</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let unDeclaration of declarations |filter:filterTerm" >
          <td>{{unDeclaration.Id_Declaration}}</td>
          <td>{{unDeclaration.Numero_Declaration}}</td>
          <td>{{unDeclaration.Id_Cargaison}}</td>
          <td>{{unDeclaration.Quantite_Debarquee}}</td>
          <td>{{unDeclaration.Poids_Debarque}}</td>
          <td>{{unDeclaration.Quantite_Restante}}</td>
          <td>{{unDeclaration.Poids_Restant}}</td>
          <td>{{unDeclaration.Date_Jour}}</td>
          <button type="submit" class="btn btn-danger" style="background-color: red;margin: 8px;font-family: initial;" (click)="delete_declaration(unDeclaration)"  > Supprimer</button>
          <button type="submit" class="btn btn-primary" style="font-family: initial;" (click)="updateDeclaration(unDeclaration.Id_Declaration)"  > Modifier</button>
        </tr>
      </tbody>
    </table ><br/>
    <a class="waves-effect waves-light btn"  [style.margin]="' 10px'"  (click)="ajouterDeclarations()" >AjouterDéclaration</a>
<br/><br/>
<app-footer></app-footer>