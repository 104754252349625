import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Camion } from '../camion';
import { Authentification } from '../services/authentification';

@Component({
  selector: 'app-ajouter-camion',
  templateUrl: './ajouter-camion.component.html',
  styleUrls: ['./ajouter-camion.component.css']
})
export class AjouterCamionComponent implements OnInit {
camion:any;
  constructor(private Auth:Authentification,private router:Router) {
    this.camion=new Camion();
   }

  ngOnInit(): void {
  }
  AjoutCamion() {
    console.log('Camion', this.camion);
    this.Auth.ajouterCamion(this.camion);
    this.router.navigate(['./camion']);
  }

}
