  <app-navbar></app-navbar><br/><br/><br/>
  <!--<form (ngSubmit)=" AjoutProduit()"  class="sign-in-form" #ProduitForm="ngForm"class="form">
    <div class="form-group">
      <label for="exampleInputIdProduit">IdProduit</label>
      <input type="number" name="idProduit"  class="form-control" id="exampleInputidProduit" placeholder="idProduit" [(ngModel)]=produit.idProduit min="1">
    </div>
    <div class="form-group">
      <label for="exampleInputNomNavire">Nature</label>
      <input type="text" name ="Nature" class="form-control" id="exampleInputNature" placeholder="nature"[(ngModel)]=produit.nature min="1">
    </div>
    <div class="form-group">
      <label for="exampleInputdateArrivee">Origine</label>
      <input type="text" name="origine" class="form-control" id="exampleInputorigine" placeholder="origine"[(ngModel)]=produit.origine min="1" >
    </div>
    <div class="form-group">
      <label for="exampleInputLargeurNavire">Etat</label>
      <input type="text" name ="etat" class="form-control" id="exampleInputetat" placeholder="etat" [(ngModel)]=produit.etat min="1" >
    </div>
    <div class="form-group">
      <label for="exampleInputLargeurNavire">Nom navire</label>
      <input type="text" name ="nomNavire" class="form-control" id="exampleInputnomNavire" placeholder="nomNavire" [(ngModel)]=produit.nomNavire min="1" >
    </div>
    <div class="form-group">
      <label for="exampleInputLargeurNavire">Numero Navire</label>
      <input type="text" name ="numeroNavire" class="form-control" id="exampleInputnumeroNavire" placeholder="numeroNavire" [(ngModel)]=produit.numeroNavire min="1" >
    </div>

    <button type="submit" class="btn btn-primary" [disabled]="!ProduitForm.form.valid">Ajouter</button>

 </form>--><br/><br/>
 <div class="container">
 <form  [formGroup]="reactiveForm_add_produit " (ngSubmit)="onSubmit_add_produit ()" #form_add_produit ="ngForm" class="row">
        
  <!-- Id_produit field avec un control de validite -->
  <div class="form-group col-sm-6">
    <label >Id produit</label>
    <input class="form-control" type="number"  formControlName="Id_produit"  placeholder="Id_produit" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Id_produit.errors }"/>
    <div *ngIf="submitted && f.Id_produit.errors" class="invalid-feedback">
        <div *ngIf="f.Id_produit.errors.required"> Id_produit est obligatoire </div>
    </div>
  </div>		
  <!-- Nature field avec un control de validite -->
  <div class="form-group col-sm-6">
    <label >Nature</label>
    <input class="form-control" type="text"  formControlName="Nature"  placeholder="Nature" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Nature.errors }"/>
    <div *ngIf="submitted && f.Nature.errors" class="invalid-feedback">
        <div *ngIf="f.Nature.errors.required"> Nature est obligatoire </div>
    </div>
  </div>		
  <!-- Origine field avec un control de validite -->
  <div class="form-group col-sm-6">
    <label >Origine</label>
    <input class="form-control" type="text"  formControlName="Origine"  placeholder="Origine" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Origine.errors }"/>
    <div *ngIf="submitted && f.Origine.errors" class="invalid-feedback">
        <div *ngIf="f.Origine.errors.required"> Origine est obligatoire </div>
    </div>
  </div>		
  <!-- Etat field avec un control de validite -->
  <div class="form-group col-sm-6">
    <label >Etat</label>
    <input class="form-control" type="text"  formControlName="Etat"  placeholder="Etat" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Etat.errors }"/>
    <div *ngIf="submitted && f.Etat.errors" class="invalid-feedback">
        <div *ngIf="f.Etat.errors.required"> Etat est obligatoire </div>
    </div>
  </div>		
  <!-- Nom_Navire field avec un control de validite -->
  <div class="form-group col-sm-6">
    <label >Nom Navire</label>
    <input class="form-control" type="text"  formControlName="Nom_Navire"  placeholder="Nom Navire" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Nom_Navire.errors }"/>
    <div *ngIf="submitted && f.Nom_Navire.errors" class="invalid-feedback">
        <div *ngIf="f.Nom_Navire.errors.required"> Nom_Navire est obligatoire </div>
    </div>
  </div>		
  <!-- Numero_Navire field avec un control de validite -->
  <div class="form-group col-sm-6">
    <label >Numero Navire</label>
    <input class="form-control" type="number"  formControlName="Numero_Navire"  placeholder="Numero Navire" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Numero_Navire.errors }"/>
    <div *ngIf="submitted && f.Numero_Navire.errors" class="invalid-feedback">
        <div *ngIf="f.Numero_Navire.errors.required"> Numero_Navire est obligatoire </div>
    </div>
  </div>
</form>
<!-- vous pouvez valider votre formulaire n\'importe ou -->

<div class="text-center m-2">
<button type="button" class="btn btn-primary m-2" [disabled]="loading_add_produit "
  (click)="form_add_produit .ngSubmit.emit()">{{loading_add_produit ?"En cours ...":"Ajouter"}}</button>
<button class="btn btn-secondary m-2" type="reset" (click)="onReset_add_produit ()">Vider</button>
</div>
</div>
 <app-footer></app-footer>
