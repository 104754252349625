
  <app-navbar></app-navbar><br/><br/><br/><br/><br/><br/>
  <div class="container">
    <div class="form-group" style="float:right">
      <input type="text" class="form-control" name="filterTerm" style="width: 250px;border: 2px solid #235f83;text-align: center;"
        [(ngModel)]="filterTerm" placeholder="Recherche"
      />
    </div>
  <table class="centered highlight datatable table table-striped table-bordered" style="border: 2px solid #235f83;">
    <thead>
      <tr>
          <th>Id Produit</th>
          <th>Nature</th>
          <th>Origine</th>
          <th>Etat</th>
          <th>Nom Navire</th>
          <th>Numero Navire</th>
      </tr>
    </thead>
    <tbody >
      <tr *ngFor="let unproduit of produits |filter:filterTerm" >
        <td>{{unproduit.Id_produit}}</td>
        <td>{{unproduit.Nature}}</td>
        <td>{{unproduit.Origine}}</td>
        <td>{{unproduit.Etat}}</td>
        <td>{{unproduit.Nom_Navire}}</td>
        <td>{{unproduit.Numero_Navire}}</td>
        <button type="submit" class="btn btn-danger" style="background-color: red;margin: 8px;font-family: initial;" (click)="delete_produit(unproduit)" > Supprimer</button>
        <button type="submit" class="btn btn-primary" style="font-family: initial;" (click)="updateProduit(unproduit.Id_produit)" > Modifier</button>
      </tr>
    </tbody>
  </table ><br/>
  <a class="waves-effect waves-light btn"  [style.margin]="' 10px'" (click)="ajouterProduits()">AjouterProduit</a>
  <br/><br/>
</div>
  <app-footer></app-footer>
