  <app-navbar></app-navbar>
<br/><br/><br/><br/><br/><br/>
<table class="centered highlight">
  <thead>
    <tr>
        <th>Id Camion</th>
        <th>Matriculation</th>
        <th>Transporteur</th>
        <th>Chauffeur</th>
        <th>Numero Permis</th> 
        <th>Date Permis</th>
    </tr>
  </thead>

  <tbody >
    <tr *ngFor="let unCamion of camions" >
      <td>{{unCamion.idCamion}}</td>
      <td>{{unCamion.matriculation}}</td>
      <td>{{unCamion.transporteur}}</td>
      <td>{{unCamion.chauffeur}}</td>
      <td>{{unCamion.numeroPermis}}</td>
      <td>{{unCamion.datePermis}}</td>
      <button type="submit" class="btn btn-danger" style="background-color: red;margin: 8px;" (click)="deleteCamion(unCamion.idCamion)" > Supprimer</button>
      <button type="submit" class="btn btn-primary" (click)="updateCamion(unCamion.idCamion)" > Modifier</button>
    </tr>

  </tbody>
</table ><br/>
<a class="waves-effect waves-light btn"  [style.margin]="' 10px'" (click)="ajouterCamion()" >AjouterCamion</a>
<br/><br/>
<app-footer></app-footer>
