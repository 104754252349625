import { Component, OnInit } from '@angular/core';
import {Navire} from '../navire';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import {Authentification} from '../services/authentification';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../services/api.service';
import { HttpClient } from '@angular/common/http';


@Component({
  selector: 'app-update-navire',
  templateUrl: './update-navire.component.html',
  styleUrls: ['./update-navire.component.css']
})
export class UpdateNavireComponent implements OnInit {
  reactiveForm_edit_navire !: FormGroup;
  submitted: boolean = false
  loading_edit_navire: boolean = false
  Numero_Navire:number=0;
  navire_to_edit: any = {}
  constructor(private formBuilder: FormBuilder, public api: ApiService, private http: HttpClient,private a_route:ActivatedRoute,private route:Router) { 
   // this.api.taf_base_url="http://localhost/gs_back_php/"
    // récupération des paramètres depuis l'URL
    a_route.params.subscribe((params:any)=>{
      if (params["Numero_Navire"]) {// si le parametre Numero_Navire est présent sur l'URL
        this.Numero_Navire=params["Numero_Navire"]
        this.get_navire(this.Numero_Navire)
      }
       else {// en cas d'absence
        alert("L'URL est incorrect. Il manque l'information 'Numero_Navire'")
     }
    })
  }

  ngOnInit(): void {
    this.init_form()
  }
  // initialisation du formulaire
  init_form() {
    this.reactiveForm_edit_navire = this.formBuilder.group({
      Numero_Navire:["", Validators.required],
      Nom_Navire: ["", Validators.required],
      Date_Arrivee: ["", Validators.required],
      Poste_Aquai: ["", Validators.required],
      Mole: ["", Validators.required],
      Provenance: ["", Validators.required]
    });
  }
  // récupération des details de l'élément à modifier
  get_navire(Numero_Navire: number) {
    this.api.taf_get("navire/get?Numero_Navire=" + Numero_Navire, (reponse: any) => {
      //when success
      if (reponse.status) {
        console.log("Opération effectuée avec succés sur la table navire. Réponse= ", reponse);
        if (reponse.data.length>0) {
          this.navire_to_edit = reponse.data[0]
          this.update_form(this.navire_to_edit)
        } else {
          alert("Détails de l'élément à modifier introuvable")
        }
      } else {
        console.log("L\'opération sur la table navire a échoué. Réponse= ", reponse);
      }
    },
      (error: any) => {
        //when error
        console.log("Erreur inconnue! ", error);
      })
  }
  // mise à jour du formulaire
  update_form(navire_to_edit:any) {
    this.reactiveForm_edit_navire = this.formBuilder.group({
      Numero_Navire: [navire_to_edit.Numero_Navire, Validators.required],
      Nom_Navire: [navire_to_edit.Nom_Navire, Validators.required],
      Date_Arrivee: [navire_to_edit.Date_Arrivee, Validators.required],
      Poste_Aquai: [navire_to_edit.Poste_Aquai, Validators.required],
      Mole: [navire_to_edit.Mole, Validators.required],
      Provenance: [navire_to_edit.Provenance, Validators.required]
    });
  }

  // acces facile au champs de votre formulaire
  get f(): any { return this.reactiveForm_edit_navire.controls; }
  // validation du formulaire
  onSubmit_edit_navire() {
    this.submitted = true;
    console.log(this.reactiveForm_edit_navire.value)
    // stop here if form is invalid
    if (this.reactiveForm_edit_navire.invalid) {
      return;
    }
    var navire = this.reactiveForm_edit_navire.value
    this.edit_navire({
      condition:JSON.stringify({Numero_Navire:this.Numero_Navire}),
      data:JSON.stringify(navire)
    })
  }
  // vider le formulaire
  onReset_edit_navire() {
    this.submitted = false;
    this.reactiveForm_edit_navire.reset();
  }
  edit_navire(navire: any) {
    this.loading_edit_navire = true;
    this.api.taf_post("navire/edit", navire, (reponse: any) => {
      this.loading_edit_navire = false;
      if (reponse.status) {
        console.log("Opération effectuée avec succés sur la table navire. Réponse= ", reponse);
        this.onReset_edit_navire()
        alert("Contenu modifié avec succés")
        this.route.navigate(['./navire'])
        
      } else {
        console.log("L\'opération sur la table navire a échoué. Réponse= ", reponse);
        alert("L'opération a echouée")
      }
    }, (error: any) => {
      this.loading_edit_navire = false;
    })
  }


}
