import { Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Navire } from '../navire';
import { User } from '../user';
import { Observable } from 'rxjs';
import {  HttpHeaders, HttpClientModule } from '@angular/common/http';
import { Produit } from '../produit';
import { Client } from '../client';
import { Camion } from '../camion';
import { Cargaison } from '../Cargaison';
import { Magasin } from '../magasin';
import { Declaration } from '../Declaration';
import { Bl } from '../Bl';
import { Acheteur } from '../Acheteur';

@Injectable({providedIn:"root"})

export class Authentification {

    private navireUrl: string;
    private ajouterUserUrl: string;
    private connexionUserUrl: string;
    private UserUrl: string;
    private ajouternavireUrl: string;
    private ajouterclientUrl: string;
    private modifiernavireUrl: string;
    private deletenavireUrl:string;
    private produitUrl:string;
    private ajoutproduitUrl:string;
    private deleteproduitUrl:string;
    private modifierproduitUrl: string;
    private clientUrl: string;
    private deleteclientUrl:string; 
    private modifierclientUrl: string;
    private camionUrl:string;
    private ajoutercamionUrl:string;
    private deletecamionUrl:string;
    private modifiercamionUrl: string;
    private cargaisonUrl: string;
    private ajoutercargaisonUrl:string;
    private deletecargaisonUrl:string;
    private modifiercargaisonUrl:string;
    private magasinUrl:string;
    private ajoutermagasinUrl:string;
    private deletemagasinUrl:string;
    private modifiermagasinUrl:string;
    private declarationUrl:string;
    private ajouterdeclarationUrl:string;
    private deletedeclarationUrl:string;
    private modifierdeclarationUrl:string;
    private blUrl:string;
    private ajouterblUrl:string;
    private deleteBLUrl:string;
    private modifierblUrl:string;
    private acheteuUrl:string;
    private modifierAcheteurUrl:string;
    constructor(private http: HttpClient) {
        this.navireUrl = 'http://localhost:8088/Navire';
        this.produitUrl = 'http://localhost:8088/Produits';
        this.clientUrl = 'http://localhost:8088/Client';
        this.UserUrl = 'http://localhost:8088/listerUser';
        this.connexionUserUrl = 'http://localhost:8088/Connexion';
        this.ajouternavireUrl = 'http://localhost:8088/ajouterNavire';
        this.ajouterUserUrl = 'http://localhost:8088/ajoutUser';
        this.ajoutproduitUrl = 'http://localhost:8088/ajouterProduit';
        this.ajouterclientUrl='http://localhost:8088/ajouterClient';
        this.modifiernavireUrl='http://localhost:8088/Navires/';
        this.deletenavireUrl='http://localhost:8088/deleteNavire/';
        this.deleteproduitUrl='http://localhost:8088/deleteProduit/';
        this.modifierproduitUrl='http://localhost:8088/Produit/';
        this.modifierclientUrl='http://localhost:8088/Clients/';
        this.deleteclientUrl='http://localhost:8088/deleteClient/';
        this.camionUrl='http://localhost:8088/Camion';
        this.ajoutercamionUrl='http://localhost:8088/ajouterCamion';
        this.deletecamionUrl='http://localhost:8088/deleteCamion/';
        this.modifiercamionUrl='http://localhost:8088/Camions/';
        this.cargaisonUrl='http://localhost:8088/Cargaison/';
        this.ajoutercargaisonUrl='http://localhost:8088/ajouterCargaison/';
        this.deletecargaisonUrl='http://localhost:8088/deleteCargaison/';
        this.modifiercargaisonUrl='http://localhost:8088/Cargaisons/'
        this.magasinUrl='http://localhost:8088/Magasin';
        this.ajoutermagasinUrl='http://localhost:8088/ajouterMagasin'
        this.deletemagasinUrl='http://localhost:8088/deleteMagasin/'
        this.modifiermagasinUrl='http://localhost:8088/Magasins/';
        this.declarationUrl='http://localhost:8088/Declaration';
        this.ajouterdeclarationUrl='http://localhost:8088/ajouterDeclaration';
        this.deletedeclarationUrl='http://localhost:8088/deleteDeclaration/';
        this.modifierdeclarationUrl='http://localhost:8088/Declarations/';
        this.blUrl='http://localhost:8088/Bl';
        this.ajouterblUrl='http://localhost:8088/ajouterBl';
        this.deleteBLUrl='http://localhost:8088/deleteBl/';
        this.modifierblUrl='http://localhost:8088/Bls/';
        this.acheteuUrl='http://localhost:8088/Acheteur';
        this.modifierAcheteurUrl='http://localhost:8088/Acheteurs/';
    }
  //  --------------------------------- Connexion ---------------------------------------
 //ajouter un utilisateur
      public ajouterUser(user: any) {
        //console.log(user)
        let formData = new FormData();
        formData.append('email',user.email);
        formData.append('Nom',user.Nom);
        formData.append('Prenom',user.Prenom);
        formData.append('password',user.password);
        this.http.post<User>(this.ajouterUserUrl, formData)
        .subscribe(data => {
            console.log(data);
            console.log("inscription reussie");
        });
      }
//authentification
    testerConnexion(email:string,password:string) {
        this.http.post<any>(this.connexionUserUrl,
        {"email":email,"password":password})
        .subscribe(data => {
            console.log(data);
            console.log("connnexion reussie");
        })
    }

//-----------------------Liste des elements de la BD ------------------------------

//lister les navires
  public listerNavire(): Observable<Navire[]> {
  return this.http.get<Navire[]>(this.navireUrl);
  }
  // lister les produits
  public listerProduit(): Observable<Produit[]> {
    return this.http.get<Produit[]>(this.produitUrl);
    }
    // lister les clients
  public listerClient(): Observable<Client[]> {
    return this.http.get<Client[]>(this.clientUrl);
    }
    //lister les camions
  public listerCamion(): Observable<Camion[]> {
    return this.http.get<Camion[]>(this.camionUrl);
    }
    //lister les cargaisons
  public listerCargaison(): Observable<Cargaison[]> {
    return this.http.get<Cargaison[]>(this.cargaisonUrl);
    }
    //lister les magasins
  public listerMagasin(): Observable<Magasin[]> {
    return this.http.get<Magasin[]>(this.magasinUrl);
    }
    //lister les declarations
  public listerDeclaration(): Observable<Declaration[]> {
    return this.http.get<Declaration[]>(this.declarationUrl);
    }
    // lister les BL
    public listerBl(): Observable<Bl[]> {
      return this.http.get<Bl[]>(this.blUrl);
      }

//------------------------- Lister par un seul element --------------------------------

//lister par un seul navire
  public getNavireById(num:any): Observable<Navire> {
    return this.http.get<Navire>(this.navireUrl+"/"+num);
  }
  //lister par un seul produit
  public getProduitById(idProduit:any): Observable<Produit> {
    return this.http.get<Produit>(this.produitUrl+"/"+idProduit);
  }
  //lister par un seul client
  public getClientById(idClient:any): Observable<Client> {
    return this.http.get<Client>(this.clientUrl+"/"+idClient);
  }
    //lister par un seul camion
    public getCamionById(idCamion:any): Observable<Camion> {
    return this.http.get<Camion>(this.camionUrl+"/"+idCamion);
  }
    //lister par un seul cargaison
    public getCargaisonById(idCargaison:any): Observable<Cargaison> {
    return this.http.get<Cargaison>(this.cargaisonUrl+"/"+idCargaison);
  }
  //lister par un seul magasin
   public getMagasinById(idMagasin:any): Observable<Magasin> {
  return this.http.get<Magasin>(this.magasinUrl+"/"+idMagasin);
  }
   //lister par un seul declaration
   public getDeclarationById(idDeclaration:any): Observable<Declaration> {
    return this.http.get<Declaration>(this.declarationUrl+"/"+idDeclaration);
  }
  //lister par un seul bl
  public getBlById(idBl:any): Observable<Bl> {
    return this.http.get<Bl>(this.blUrl+"/"+idBl);
  }
  //lister par un seul Acheteur
  public getAcheteurById(idAcheteur:any): Observable<Acheteur> {
  return this.http.get<Acheteur>(this.acheteuUrl+"/"+idAcheteur);
}
//---------------------------- ajout d'un element-----------------------------

//ajouter navire
  public ajouterNavire(navire: Navire) {
    this.http.post<Navire>(this.ajouternavireUrl, navire)
    .subscribe(data => {
        console.log('Ca a marcher');
    });
  }
  //ajouter produit
  public ajouterProduit(produit: Produit) {
    this.http.post<Produit>(this.ajoutproduitUrl, produit)
    .subscribe(data => {
        console.log('Ca a marcher');
    });
  }
    //ajouter client
    public ajouterClient(client: Client) {
      this.http.post<Client>(this.ajouterclientUrl, client)
      .subscribe(data => {
          console.log('ça à marcher');
      });
    }
    //ajouter camion
    public ajouterCamion(camion: Camion) {
      this.http.post<Camion>(this.ajoutercamionUrl, camion)
      .subscribe(data => {
          console.log('ça à marcher');
      });
    }
    //ajouter cargaison
    public ajouterCargaison(cargaison: Cargaison) {
      this.http.post<Cargaison>(this.ajoutercargaisonUrl, cargaison)
      .subscribe(data => {
          console.log('ça à marcher');
      });
    }
    //ajouter Magasin
    public ajouterMagasin(magasin: Magasin) {
      this.http.post<Magasin>(this.ajoutermagasinUrl, magasin)
      .subscribe(data => {
          console.log('ça à marcher');
      });
    }
    //ajouter Declaration
    public ajouterDeclaration(declaration: Declaration) {
    this.http.post<Declaration>(this.ajouterdeclarationUrl, declaration)
    .subscribe(data => {
        console.log('ça à marcher');
    });
  }
     //ajouter BL
     public ajouterBl(BL: Bl) {
      this.http.post<Bl>(this.ajouterblUrl, BL)
      .subscribe(data => {
          console.log('ça à marcher');
      });
    }

//-------------------- Modification d'un element--------------------

//modifier navire
    public modifierNavire(navire:Navire,numeroNavire:number){
      this.http.put<Navire>(this.modifiernavireUrl+"/"+numeroNavire, navire)
        .subscribe(data => {
          console.log(data);
        });
    
    }
    //modifier produit
    public modifierProduit(produit:Produit,idProduit:number){
      this.http.put<Produit>(this.modifierproduitUrl+"/"+idProduit, produit)
        .subscribe(data => {
          console.log(data);
        });
    
    }
    //modifier client
    public modifierClient(client:Client,idClient:number){
      this.http.put<Client>(this.modifierclientUrl+"/"+idClient, client)
        .subscribe(data => {
          console.log(data);
        });
    
    }
      //modifier camion
      public modifierCamion(camion:Camion,idCamion:number){
      this.http.put<Camion>(this.modifiercamionUrl+"/"+idCamion, camion)
        .subscribe(data => {
          console.log(data);
        });
    
    }
    //modifier cargaison
    public modifierCargaison(cargaison:Cargaison,idCargaison:number){
      this.http.put<Cargaison>(this.modifiercargaisonUrl+"/"+idCargaison,cargaison)
        .subscribe(data => {
          console.log(data);
        });
    
    }
  //modifier magasin
  public modifierMagasin(magasin:Magasin,idMagasin:number){
    this.http.put<Magasin>(this.modifiermagasinUrl+"/"+idMagasin,magasin)
      .subscribe(data => {
        console.log(data);
      });
  
  }
   //modifier declaration
   public modifierDeclaration(declaration:Declaration,idDeclaration:number){
    this.http.put<Declaration>(this.modifierdeclarationUrl+"/"+idDeclaration,declaration)
      .subscribe(data => {
        console.log(data);
      });
  
  }
  //modifier bl
  public modifierBL(BL:Bl,idBl:number){
  this.http.put<Bl>(this.modifierblUrl+"/"+idBl,BL)
    .subscribe(data => {
      console.log(data);
    });
 }
   //modifier Acheteur
   public modifierAcheteur(acheteur:Acheteur,idAcheteur:number){
    this.http.put<Acheteur>(this.modifierAcheteurUrl+"/"+idAcheteur,acheteur)
      .subscribe(data => {
        console.log(data);
      });
  
   }

//------------------------ Suppression d'un element -----------------

// supprimer navire
  supprimerNavire(numeroNavire:number){
      this.http.delete<void> (this.deletenavireUrl+'/'+numeroNavire).subscribe(
        ()=>{
          //console.log('ca a marcher')
        }
      )
  }
       // supprimer produit
       supprimerProduit(idProduit:number){
        this.http.delete<void> (this.deleteproduitUrl+'/'+idProduit).subscribe(
          ()=>{
            //console.log('ca a marcher')
          }
        )
    }
    // supprimer client
        supprimerClient(idClient:number){
        this.http.delete<void> (this.deleteclientUrl+'/'+idClient).subscribe(
          ()=>{
            //console.log('ca a marcher')
          }
        )
    }
    // supprimer camion
    supprimerCamion(idCamion:number){
      this.http.delete<void> (this.deletecamionUrl+'/'+idCamion).subscribe(
        ()=>{
          //console.log('ca a marcher')
        }
      )
  }
    // supprimer cargaison
    supprimerCargaison(idCargaison:number){
      this.http.delete<void> (this.deletecargaisonUrl+'/'+idCargaison).subscribe(
        ()=>{
          //console.log('ca a marcher')
        }
      )
  }
  // supprimer Magasin
    supprimerMagasin(idMagasin:number){
      this.http.delete<void> (this.deletemagasinUrl+'/'+idMagasin).subscribe(
        ()=>{
          //console.log('ca a marcher')
        }
      )
  }
     // supprimer Declaration
     supprimerDeclaration(idDeclaration:number){
      this.http.delete<void> (this.deletedeclarationUrl+'/'+idDeclaration).subscribe(
        ()=>{
          //console.log('ca a marcher')
        }
      )
  }
      // supprimer BL
      supprimerBl(idBl:number){
        this.http.delete<void> (this.deleteBLUrl+'/'+idBl).subscribe(
          ()=>{
            //console.log('ca a marcher')
          }
        )
    }
}

