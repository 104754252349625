import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../services/api.service';
import { Authentification } from '../services/authentification';
import { HttpClient } from '@angular/common/http';
@Component({
  selector: 'app-update-acheteur',
  templateUrl: './update-acheteur.component.html',
  styleUrls: ['./update-acheteur.component.css']
})
export class UpdateAcheteurComponent implements OnInit {
 /* acheteur: any;
  ache:any;
  private id: number;
  constructor(private auth:Authentification,private router:Router,private route: ActivatedRoute) { }

  ngOnInit(): void {
 this.acheteur=+this.route.snapshot.params['acheteur']
    console.log(this.acheteur);
    this.getAcheteurById(this.acheteur);
  }
  getAcheteurById(id:any){
    this.auth.getAcheteurById(id).subscribe(data=> {
      this.ache = data;
      console.log(this.ache);
  });
}
ModifierAcheteur(){
  console.log("nouveau",this.ache)
  this.auth.modifierAcheteur(this.ache,this.acheteur);
  this.router.navigate(['./acheteur'])
  }
*/
reactiveForm_edit_acheteur !: FormGroup;
submitted: boolean = false
loading_edit_acheteur: boolean = false
Id_Acheteur:number=0; 
acheteur_to_edit: any = {} 
constructor(private formBuilder: FormBuilder, public api: ApiService, private http: HttpClient,private a_route:ActivatedRoute,private route:Router) {
// récupération des paramètres depuis l'URL 
a_route.params.subscribe((params:any)=>{
if (params["Id_Acheteur"]) {
// si le prametre id_acheteur est présent sur l'URL 
this.Id_Acheteur=params["Id_Acheteur"] 
this.get_acheteur(this.Id_Acheteur) } 
else {
// en cas d'absence 
alert("L'URL est incorrect. Il manque l'information 'id_acheteur'") } }) 
} 
ngOnInit(): void { this.init_form() } 
init_form() { 
this.reactiveForm_edit_acheteur = this.formBuilder.group({ 
Id_Acheteur: ["", Validators.required],
Nom_Acheteur: ["", Validators.required],
Bl_Acheteur: ["", Validators.required],
Numero_Navire: ["", Validators.required],
Nom_Navire: ["", Validators.required],
Id_Magasin: ["", Validators.required],
Nom_Magasin: ["", Validators.required],
Quantite: ["", Validators.required],
Poids: ["", Validators.required],
Variete: ["", Validators.required] }); 
} // récupération des details de l'élément à modifier 
get_acheteur(Id_Acheteur: number) {
this.api.taf_get("acheteur/get?Id_Acheteur=" + Id_Acheteur, (reponse: any) => { 
//when success 
if (reponse.status) {
console.log("Opération effectuée avec succés sur la table acheteur. Réponse= ", reponse);
if (reponse.data.length>0) {
this.acheteur_to_edit = reponse.data[0]
this.update_form(this.acheteur_to_edit) }
else {
 alert("Détails de l'élément à modifier introuvable") 
 } } 
 else {
 console.log("L\'opération sur la table acheteur a échoué. Réponse= ", reponse); } }, (error: any) => {
  //when error 
  console.log("Erreur inconnue! ", error); }) } 
  // mise à jour du formulaire 
  update_form(acheteur_to_edit:any) { this.reactiveForm_edit_acheteur = this.formBuilder.group({ 
  Id_Acheteur: [acheteur_to_edit.Id_Acheteur, Validators.required],
  Nom_Acheteur: [acheteur_to_edit.Nom_Acheteur, Validators.required],
  Bl_Acheteur: [acheteur_to_edit.Bl_Acheteur, Validators.required],
  Numero_Navire: [acheteur_to_edit.Numero_Navire, Validators.required],
  Nom_Navire: [acheteur_to_edit.Nom_Navire, Validators.required],
  Id_Magasin: [acheteur_to_edit.Id_Magasin, Validators.required],
  Nom_Magasin: [acheteur_to_edit.Nom_Magasin, Validators.required],
  Quantite: [acheteur_to_edit.Quantite, Validators.required],
  Poids: [acheteur_to_edit.Poids, Validators.required],
  Variete: [acheteur_to_edit.Variete, Validators.required] }); }
  // acces facile au champs de votre formulaire 
  get f(): any { return this.reactiveForm_edit_acheteur .controls; } 
  // validation du formulaire 
  onSubmit_edit_acheteur() { 
  this.submitted = true;
  console.log(this.reactiveForm_edit_acheteur.value)
   // stop here if form is invalid 
   if (this.reactiveForm_edit_acheteur.invalid) { return; }
   var acheteur = this.reactiveForm_edit_acheteur.value 
   this.edit_acheteur({ condition:JSON.stringify({id_acheteur:this.Id_Acheteur}),
   data:JSON.stringify(acheteur) }) } 
   // vider le formulaire 
   onReset_edit_acheteur() { 
   	this.submitted = false;
   	this.reactiveForm_edit_acheteur.reset(); } 
   	edit_acheteur(acheteur: any) {
   		this.loading_edit_acheteur = true;
   		this.api.taf_post("acheteur/edit", acheteur, (reponse: any) => {
   			this.loading_edit_acheteur = false;
   			if (reponse.status) {
   				console.log("Opération effectuée avec succés sur la table acheteur. Réponse= ", reponse);
   				this.onReset_edit_acheteur()
           alert("Contenu modifié avec succés sur la table acheteur") 
           this.route.navigate(['./acheteur'])
          } 
   				else {
   					console.log("L\'opération sur la table acheteur a échoué. Réponse= ", reponse);
   					alert("L'opération a echoué") } }, (error: any) => {
   						this.loading_edit_acheteur = false; }) 
   	}
}
