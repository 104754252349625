import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Navire } from '../navire';
import { ApiService } from '../services/api.service';
import { Authentification } from '../services/authentification';

@Component({
  selector: 'app-accueil',
  templateUrl: './accueil.component.html',
  styleUrls: ['./accueil.component.css']
})
export class AccueilComponent implements OnInit {
  //navires: Navire[];
  navires:any=[];
  filterTerm:any;
  constructor(private http:HttpClient, private router: Router,public api:ApiService) {
    this.navires = [];
  }

  // tslint:disable-next-line:typedef
  ngOnInit(){
    this.get_navire()

  }
  get_navire(){
    this.api.taf_get("navire/get",(reponse:any)=>{
        //when success
        if(reponse.status){
          this.navires=reponse.data
            console.log("Opération effectuée avec succés sur la table navire. Réponse= ",reponse);
        }else{
            console.log("L\'opération sur la table navire a échoué. Réponse= ",reponse);
        }
    },
    (error:any)=>{
        //when error
        console.log("Erreur inconnue! ",error);
    })
    }
  // tslint:disable-next-line:typedef
  ajouterNavires(){
    this.router.navigate(['./ajouterNavires']);
  }

  delete_navire (navire : any){
    //transformation des parametres à envoyer
    let formdata = new FormData()
    for (const key in navire ) {
        formdata.append(key, navire [key])
    }
  
    let api_url="http://localhost/gs_back_php/navire/delete" 
    this.http.post(api_url, formdata).subscribe((reponse: any)=>{
        //when success
        
        if(reponse.status){
        console.log("Opération effectuée avec succés sur la table navire . Réponse = ",reponse)
        this.router.navigate(['./navire']);
      }
        else{
        console.log("L\'opération sur la table navire  a échoué. Réponse = ",reponse)
        }
    },
    (error: any)=>{
        //when error
        console.log("Erreur inconnue! ",error)
    })
    } 
  // tslint:disable-next-line:typedef
  updateNavire(Numero_Navire:number) {
    let url = './updateNavire/';
    url=url+Numero_Navire;
    this.router.navigate([url]);
}
}
