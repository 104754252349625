import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../services/api.service';
import { Authentification } from '../services/authentification';
import { HttpClient } from '@angular/common/http';
@Component({
  selector: 'app-updatemagasin',
  templateUrl: './updatemagasin.component.html',
  styleUrls: ['./updatemagasin.component.css']
})
export class UpdatemagasinComponent implements OnInit {
reactiveForm_edit_magasin !: FormGroup; 
submitted: boolean = false 
loading_edit_magasin: boolean = false 
Id_Magasin:number=0;
magasin_to_edit: any = {} 
 constructor(private formBuilder: FormBuilder, public api: ApiService, private http: HttpClient,private a_route:ActivatedRoute,private route:Router) {
 // récupération des paramètres depuis l'URL 
 	a_route.params.subscribe((params:any)=>{
 		if (params["Id_Magasin"]) {
 		// si le prametre id_magasin est présent sur l'URL 
 		this.Id_Magasin=params["Id_Magasin"]
 		this.get_magasin(this.Id_Magasin)
 		}
 		else {
 		// en cas d'absence 
 		 alert("L'URL est incorrect. Il manque l'information 'id_magasin'") }
 		 }) 
 		}
 		ngOnInit(): void {
 		  this.init_form()
 		}
 		// initialisation du formulaire 
 		init_form() {
 		this.reactiveForm_edit_magasin = this.formBuilder.group({
     Id_Magasin: ["", Validators.required], 
 		 Nom_Magasin: ["", Validators.required],
 		 Adresse: ["", Validators.required],
 		 Contact: ["", Validators.required],
 		 Proprietaire: ["", Validators.required],
 		 Magasinien: ["", Validators.required] , 
 		 Numero_Navire: ["", Validators.required],
 		 Nom_Navire: ["", Validators.required],
 		 Id_Produit: ["", Validators.required],
 		 Nature_Produit: ["", Validators.required]}); 
 		 } 
 		 // récupération des details de l'élément à modifier 
 		 get_magasin(Id_Magasin: number) {
 		 this.api.taf_get("magasin/get?Id_Magasin=" + Id_Magasin, (reponse: any) => {
 		  //when success 
 		  if (reponse.status) {
 		  console.log("Opération effectuée avec succés sur la table magasin. Réponse= ", reponse);
 		  if (reponse.data.length>0) { 
 		  this.magasin_to_edit = reponse.data[0]
 		  this.update_form(this.magasin_to_edit)
 		  }
 		  else { 
 		  alert("Détails de l'élément à modifier introuvable") 
 		  }
 		  }
 		  else { 
 		   console.log("L\'opération sur la table magasin a échoué. Réponse= ", reponse);
 		  } 
 		  },
 		  (error: any) => {
 		   //when error 
 		   console.log("Erreur inconnue! ", error); })
 		   } 
 		   // mise à jour du formulaire 
 		   update_form(magasin_to_edit:any) { 
 		   this.reactiveForm_edit_magasin = this.formBuilder.group({
        Id_Magasin: [magasin_to_edit.Id_Magasin, Validators.required],
 		    Nom_Magasin: [magasin_to_edit.Nom_Magasin, Validators.required], 
 		    Adresse: [magasin_to_edit.Adresse, Validators.required],
 		    Contact: [magasin_to_edit.Contact, Validators.required],
 		    Proprietaire: [magasin_to_edit.Proprietaire, Validators.required],
 		    Magasinien: [magasin_to_edit.Magasinien, Validators.required] ,
 		    Numero_Navire: [magasin_to_edit.Numero_Navire, Validators.required], 
 		    Nom_Navire: [magasin_to_edit.Nom_Navire, Validators.required],
 		    Id_Produit: [magasin_to_edit.Id_Produit, Validators.required],
 		    Nature_Produit: [magasin_to_edit.Nature_Produit, Validators.required]});
 		    }
 		     // acces facile au champs de votre formulaire 
 		     get f(): any { return this.reactiveForm_edit_magasin.controls; } 
 		     // validation du formulaire 
 		     onSubmit_edit_magasin() { 
 		     this.submitted = true; console.log(this.reactiveForm_edit_magasin.value) 
 		     // stop here if form is invalid 
 		     if (this.reactiveForm_edit_magasin.invalid) { return; } 
 		     var magasin = this.reactiveForm_edit_magasin.value 
         this.edit_magasin({ 
 		     condition:JSON.stringify({id_magasin:this.Id_Magasin}),
 		     data:JSON.stringify(magasin) }) }
 		      // vider le formulaire 
 		     onReset_edit_magasin() {
 		     	this.submitted = false;
 		     	this.reactiveForm_edit_magasin.reset();
 		     }
 		     edit_magasin(magasin: any) {
 		     	this.loading_edit_magasin = true;
 		     	this.api.taf_post("magasin/edit", magasin, (reponse: any) => {
 		     	this.loading_edit_magasin = false;
 		     	if (reponse.status) {
 		     		console.log("Opération effectuée avec succés sur la table magasin. Réponse= ", reponse);
 		     		this.onReset_edit_magasin() 
            alert("Contenu modifié avec succés")
            this.route.navigate(['./magasin'])
           }
 		     		else { console.log("L\'opération sur la table magasin a échoué. Réponse= ", reponse);
 		     		alert("L'opération a echoué") } 
 		     	},
 		     	(error: any) => {
 		     		this.loading_edit_magasin = false; 
 		     	}) 
 		     }
}
