import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ConnexionComponent } from './connexion/connexion.component';
import { AccueilComponent } from './accueil/accueil.component';
import { Routes,RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InscriptionComponent } from './inscription/inscription.component';
import { Authentification } from './services/authentification';
import { HttpClientModule } from '@angular/common/http';
import { NaviresComponent } from './navires/navires.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { UpdateNavireComponent } from './update-navire/update-navire.component';
import { AccueilAppComponent } from './accueil-app/accueil-app.component';
import { NavbarComponent } from './navbar/navbar.component';
import { FooterComponent } from './footer/footer.component';
import { ClientComponent } from './client/client.component';
import { ProduitComponent } from './produit/produit.component';
import { CamionComponent } from './camion/camion.component';
import { AjouterClientComponent } from './ajouter-client/ajouter-client.component';
import { AjouterProduitComponent } from './ajouter-produit/ajouter-produit.component';
import { AjouterCamionComponent } from './ajouter-camion/ajouter-camion.component';
import { UpdateCamionComponent } from './update-camion/update-camion.component';
import { UpdateClientComponent } from './update-client/update-client.component';
import { UpdateProduitComponent } from './update-produit/update-produit.component';
import { CargaisonComponent } from './cargaison/cargaison.component';
import { DeclarationComponent } from './declaration/declaration.component';
import { AjouterCargaisonComponent } from './ajouter-cargaison/ajouter-cargaison.component';
import { AjouterDeclarationComponent } from './ajouter-declaration/ajouter-declaration.component';
import { UpdateCargaisonComponent } from './update-cargaison/update-cargaison.component';
import { UpdateDeclarationComponent } from './update-declaration/update-declaration.component';
import { BLComponent } from './bl/bl.component';
import { MagasinComponent } from './magasin/magasin.component';
import { AjoutermagasinComponent } from './ajoutermagasin/ajoutermagasin.component';
import { UpdatemagasinComponent } from './updatemagasin/updatemagasin.component';
import { AjouterBLComponent } from './ajouter-bl/ajouter-bl.component';
import { UpdateBLComponent } from './update-bl/update-bl.component';
import { AcheteurComponent } from './acheteur/acheteur.component';
import { AjouterAcheteurComponent } from './ajouter-acheteur/ajouter-acheteur.component';
import { UpdateAcheteurComponent } from './update-acheteur/update-acheteur.component';
import { MvtSortieComponent } from './mvt-sortie/mvt-sortie.component';
import { RecapLivraisonComponent } from './recap-livraison/recap-livraison.component';
import { FicheSortieComponent } from './fiche-sortie/fiche-sortie.component';
import { AjouterMvtSortieComponent } from './ajouter-mvt-sortie/ajouter-mvt-sortie.component';
import { Ng2SearchPipe } from 'ng2-search-filter';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
//import { PaginationModule,PaginationConfig } from 'ngx-bootstrap/pagination';


const routes: Routes = [
  {
    path:'',redirectTo:"AccueilApp",pathMatch:"full"
  },

  { path: 'AccueilApp',
  component: AccueilAppComponent
 },
  { path: 'navire',
   component: AccueilComponent
  },
  {
    path:'connexion',
    component:ConnexionComponent
  },

  {
    path:'ajouterNavires',
    component:NaviresComponent
  },
  {
    path:'ajouterProduits',
    component:AjouterProduitComponent
  },
  {
    path:'ajouterClients',
    component:AjouterClientComponent
  },

  {
    path:'ajouterCamions',
    component:AjouterCamionComponent
  },
  {
    path:'ajouterCargaison',
    component:AjouterCargaisonComponent
  },
  {
    path:'ajouterMagasin',
    component:AjoutermagasinComponent
  },
  {
    path:'ajouterDeclaration',
    component:AjouterDeclarationComponent
  },
  {
    path:'ajouterBl',
    component:AjouterBLComponent
  },
  {
    path:'updateNavire',
    component:NaviresComponent
  },
  {
    path:'inscription',
    component:InscriptionComponent
  },
  {
    path:'listerNavires',
    component:NaviresComponent
  },
  {
    path:'produit',
    component:ProduitComponent
  },
  {
    path:'camion',
    component:CamionComponent
  },
  {
    path:'cargaison',
    component:CargaisonComponent
  },
  {
    path:'MvtSortie',
    component:MvtSortieComponent
  },
  {
    path:'FicheSortie',
    component:FicheSortieComponent
  },
  {
    path:'RecapLivraison',
    component:RecapLivraisonComponent
  },
  {
    path:'updateNavire/:Numero_Navire',
    component:UpdateNavireComponent
  },
  {
    path:'updateProduit/:Id_produit',
    component:UpdateProduitComponent
  },
  {
    path:'updateClient/:Id_Client',
    component:UpdateClientComponent
  },
  {
    path:'updateCamion/:camion',
    component:UpdateCamionComponent
  },
  {
    path:'updateCargaison/:Id_Cargaison',
    component:UpdateCargaisonComponent
  },
  {
    path:'updateMagasin/:Id_Magasin',
    component:UpdatemagasinComponent
  },
  {
    path:'updateDeclaration/:Id_Declaration',
    component:UpdateDeclarationComponent
  },
  {
    path:'updateBl/:Id_Bl',
    component:UpdateBLComponent
  },
  {
    path:'updateAcheteur/:Id_Acheteur',
    component:UpdateAcheteurComponent
  },
  {
    path:'client',
    component:ClientComponent
  },
  {
    path:'magasin',
    component:MagasinComponent
  },
  {
    path:'bl',
    component:BLComponent
  },
  {
    path:'declaration',
    component:DeclarationComponent
  },
  {
    path:'acheteur',
    component:AcheteurComponent
  },
  {
    path:'Ajouteracheteur',
    component:AjouterAcheteurComponent
  },
  {
    path:'ajouterMvtSortie',
    component:AjouterMvtSortieComponent
  },

];

@NgModule({
  declarations: [
    AppComponent,
    ConnexionComponent,
    AccueilComponent,
    InscriptionComponent,
    NaviresComponent,
    UpdateNavireComponent,
    AccueilAppComponent,
    NavbarComponent,
    FooterComponent,
    ClientComponent,
    ProduitComponent,
    CamionComponent,
    AjouterClientComponent,
    AjouterProduitComponent,
    AjouterCamionComponent,
    UpdateCamionComponent,
    UpdateClientComponent,
    UpdateProduitComponent,
    CargaisonComponent,
    DeclarationComponent,
    AjouterCargaisonComponent,
    AjouterDeclarationComponent,
    UpdateCargaisonComponent,
    UpdateDeclarationComponent,
    BLComponent,
    MagasinComponent,
    AjoutermagasinComponent,
    UpdatemagasinComponent,
    AjouterBLComponent,
    UpdateBLComponent,
    AcheteurComponent,
    AjouterAcheteurComponent,
    UpdateAcheteurComponent,
    MvtSortieComponent,
    RecapLivraisonComponent,
    FicheSortieComponent,
    AjouterMvtSortieComponent
    
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule.forRoot(routes,{onSameUrlNavigation:'reload'}),
    NgbModule,
   Ng2SearchPipeModule
  ],
  providers: [
    Authentification,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
