import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Bl } from '../Bl';
import { ApiService } from '../services/api.service';
import { Authentification } from '../services/authentification';

@Component({
  selector: 'app-bl',
  templateUrl: './bl.component.html',
  styleUrls: ['./bl.component.css']
})
export class BLComponent implements OnInit {
bls: any=[];
filterTerm:any;
//pages: number = 1;
//dataset: any[] = ['1','2','3','4','5','6','7','8','9','10'];
//page=4;
 constructor(private http:HttpClient, private router: Router,public api:ApiService) {
    this.bls=[];

  }

  ngOnInit(): void {
    this.get_bl()
  }
  get_bl(){
    this.api.taf_get("bl/get",(reponse:any)=>{
        //when success
        if(reponse.status){
          this.bls=reponse.data
            console.log("Opération effectuée avec succés sur la table bl. Réponse= ",reponse);
        }else{
            console.log("L\'opération sur la table bl a échoué. Réponse= ",reponse);
        }
    },
    (error:any)=>{
        //when error
        console.log("Erreur inconnue! ",error);
    })
    }
  ajouterBl(){
    this.router.navigate(['./ajouterBl']);
  }

  delete_bl (bl : any){
    //transformation des parametres à envoyer
    let formdata = new FormData()
    for (const key in bl ) {
        formdata.append(key, bl [key])
    }

    let api_url="http://localhost/gs_back_php/bl/delete" 
    this.http.post(api_url, formdata).subscribe((reponse: any)=>{
        //when success
        if(reponse.status){
        console.log("Opération effectuée avec succés sur la table bl . Réponse = ",reponse)
        this.router.navigate(['./bl']);
      }
        else{
        console.log("L\'opération sur la table bl  a échoué. Réponse = ",reponse)
        }
    },
    (error: any)=>{
        //when error
        console.log("Erreur inconnue! ",error)
    })
    }
    updateBl(Id_Bl:number) {
      let url = './updateBl/';
      url=url+Id_Bl;
      this.router.navigate([url]);
  }  
  cumulSacs(Nbre_Sacs:any){
    

  }

}
