<app-navbar></app-navbar><br/><br/><br/><br/>
<div class="container">
  <form [formGroup]="reactiveForm_edit_declaration " (ngSubmit)="onSubmit_edit_declaration ()" #form_edit_declaration ="ngForm" class="row">
    <!-- Id_Declaration field avec un control de validite -->
    <div class="form-group col-sm-6">
      <label >Id_Declaration</label>
      <input class="form-control" type="text" formControlName="Id_Declaration" placeholder="Id_Declaration" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Id_Declaration.errors }"/>
      <div *ngIf="submitted && f.Id_Declaration.errors" class="invalid-feedback">
        <div *ngIf="f.Id_Declaration.errors.required"> Id_Declaration est obligatoire </div>
      </div>
    </div>
    <!-- Numero_Declaration field avec un control de validite -->
    <div class="form-group col-sm-6">
      <label >Numero_Declaration</label>
      <input class="form-control" type="text" formControlName="Numero_Declaration" placeholder="Numero_Declaration" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Numero_Declaration.errors }"/>
      <div *ngIf="submitted && f.Numero_Declaration.errors" class="invalid-feedback">
        <div *ngIf="f.Numero_Declaration.errors.required"> Numero_Declaration est obligatoire </div>
      </div>
    </div>
    <!-- Id_Cargaison field avec un control de validite -->
    <div class="form-group col-sm-6">
      <label >Id_Cargaison</label>
      <input class="form-control" type="text" formControlName="Id_Cargaison" placeholder="Id_Cargaison" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Id_Cargaison.errors }"/>
      <div *ngIf="submitted && f.Id_Cargaison.errors" class="invalid-feedback">
        <div *ngIf="f.Id_Cargaison.errors.required"> Id_Cargaison est obligatoire </div>
      </div>
    </div>
    <!-- Quantite_Debarquee field avec un control de validite -->
    <div class="form-group col-sm-6">
      <label >Quantite_Debarquee</label>
      <input class="form-control" type="text" formControlName="Quantite_Debarquee" placeholder="Quantite_Debarquee" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Quantite_Debarquee.errors }"/>
      <div *ngIf="submitted && f.Quantite_Debarquee.errors" class="invalid-feedback">
        <div *ngIf="f.Quantite_Debarquee.errors.required"> Quantite_Debarquee est obligatoire </div>
      </div>
    </div>
    <!-- Poids_Debarque field avec un control de validite -->
    <div class="form-group col-sm-6">
      <label >Poids_Debarque</label>
      <input class="form-control" type="text" formControlName="Poids_Debarque" placeholder="Poids_Debarque" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Poids_Debarque.errors }"/>
      <div *ngIf="submitted && f.Poids_Debarque.errors" class="invalid-feedback">
        <div *ngIf="f.Poids_Debarque.errors.required"> Poids_Debarque est obligatoire </div>
      </div>
    </div>
    <!-- Quantite_Restante field avec un control de validite -->
    <div class="form-group col-sm-6">
      <label >Quantite_Restante</label>
      <input class="form-control" type="text" formControlName="Quantite_Restante" placeholder="Quantite_Restante" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Quantite_Restante.errors }"/>
      <div *ngIf="submitted && f.Quantite_Restante.errors" class="invalid-feedback">
        <div *ngIf="f.Quantite_Restante.errors.required"> Quantite_Restante est obligatoire </div>
      </div>
    </div>
    <!-- Poids_Restant field avec un control de validite -->
    <div class="form-group col-sm-6">
      <label >Poids_Restant</label>
      <input class="form-control" type="text" formControlName="Poids_Restant" placeholder="Poids_Restant" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Poids_Restant.errors }"/>
      <div *ngIf="submitted && f.Poids_Restant.errors" class="invalid-feedback">
        <div *ngIf="f.Poids_Restant.errors.required"> Poids_Restant est obligatoire </div>
        </div>
      </div>
      <!-- Date_Jour field avec un control de validite -->
      <div class="form-group col-sm-6">
        <label >Date_Jour</label>
        <input class="form-control" type="text" formControlName="Date_Jour" placeholder="Date_Jour" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Date_Jour.errors }"/>
        <div *ngIf="submitted && f.Date_Jour.errors" class="invalid-feedback">
          <div *ngIf="f.Date_Jour.errors.required"> Date_Jour est obligatoire </div>
        </div>
      </div>
  </form>
  <!-- vous pouvez valider votre formulaire n\'importe ou -->
  <div class="text-center m-2">
    <button type="button" class="btn btn-primary m-2" [disabled]="loading_edit_declaration " (click)="form_edit_declaration .ngSubmit.emit()">{{loading_edit_declaration ?"En cours ...":"Modifier"}}</button>
    <button class="btn btn-secondary m-2" type="reset" (click)="onReset_edit_declaration ()">Vider</button>
  </div>
</div> 
  <br/><br/>
<app-footer></app-footer>

