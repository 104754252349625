import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Route, Router } from '@angular/router';
import { Magasin } from '../magasin';
import { ApiService } from '../services/api.service';
import { Authentification } from '../services/authentification';

@Component({
  selector: 'app-ajoutermagasin',
  templateUrl: './ajoutermagasin.component.html',
  styleUrls: ['./ajoutermagasin.component.css']
})
export class AjoutermagasinComponent implements OnInit {
  reactiveForm_add_magasin !:FormGroup;
  submitted:boolean=false
  loading_add_magasin :boolean=false
  constructor(private formBuilder:FormBuilder,private router:Router,private http:HttpClient,public api:ApiService) { 
  }

  ngOnInit(): void {
    this.init_form()
}
init_form() {
    this.reactiveForm_add_magasin  = this.formBuilder.group({
        
Id_Magasin: ["", Validators.required],
Nom_Magasin: ["", Validators.required],
Adresse: ["", Validators.required],
Contact: ["", Validators.required],
Proprietaire: ["", Validators.required],
Magasinien: ["", Validators.required],
Numero_Navire: ["", Validators.required],
Nom_Navire: ["", Validators.required],
Id_Produit: ["", Validators.required],
Nature_Produit: ["", Validators.required]
    });
}
get f(): any { return this.reactiveForm_add_magasin .controls; }
// validation du formulaire
onSubmit_add_magasin () {
    this.submitted = true;
    console.log(this.reactiveForm_add_magasin .value)
    // stop here if form is invalid
    if (this.reactiveForm_add_magasin .invalid) {
        return;
    }
    var magasin =this.reactiveForm_add_magasin .value
    this.add_magasin (magasin )
}
add_magasin(magasin: any) {
  this.loading_add_magasin = true;
  this.api.taf_post("magasin/add", magasin, (reponse: any) => {
  this.loading_add_magasin = false;
  if (reponse.status) {
      console.log("Opération effectuée avec succés sur la table magasin. Réponse= ", reponse);
      this.onReset_add_magasin()
      alert("Contenu ajouté avec succés")
      this.router.navigate(['./magasin']);
  } else {
      console.log("L\'opération sur la table magasin a échoué. Réponse= ", reponse);
      alert("L'opération a echoué")
  }
}, (error: any) => {
  this.loading_add_magasin = false;
})
}
// vider le formulaire
onReset_add_magasin () {
this.submitted = false;
this.reactiveForm_add_magasin .reset();
}
 
}
