
  <app-navbar></app-navbar>
<br/><br/><br/><br/><br/><br/>
<div class="container">
  <div class="form-group" style="float:right">
    <input type="text" class="form-control" name="filterTerm" style="width: 250px;border: 2px solid #235f83;text-align: center;"
      [(ngModel)]="filterTerm" placeholder="Recherche"
    />
  </div>
<table class="centered highlight datatable table table-striped table-bordered" style="border: 2px solid #235f83;">
    <thead>
      <tr>
          <th>Id Client</th>
          <th>Nom Client</th>
          <th>Adresse</th>
          <th>Numero Navire</th>
          <th>Nom Navire</th>
          <th>Id Produit</th>
          <th>Nature Produit</th>
          <th>Telephone</th>
      </tr>
    </thead>
  
    <tbody >
      <tr *ngFor="let unClient of clients |filter:filterTerm" >
        <td>{{unClient.Id_Client  }}</td>
        <td>{{unClient.Nom_Client}}</td>
        <td>{{unClient.Adresse}}</td>
        <td>{{unClient.Numero_Navire}}</td>
        <td>{{unClient.Nom_Navire}}</td>
        <td>{{unClient.Id_Produit}}</td>
        <td>{{unClient.Nature_Produit}}</td>
        <td>{{unClient.Telephone}}</td>
        <button type="submit" class="btn btn-danger" style="background-color: red;margin: 8px;font-family: initial;" (click)="delete_client(unClient)" > Supprimer</button>
        <button type="submit" class="btn btn-primary" style="font-family: initial;" (click)="updateClient(unClient.Id_Client)"> Modifier</button>
      </tr>
  
    </tbody>
  </table ><br/>
  <a class="waves-effect waves-light btn" [style.margin]="' 10px'" (click)="ajouterClients()" >AjouterClient</a>
  <br/><br/>
</div>
  <app-footer></app-footer>

