import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Cargaison } from '../Cargaison';
import { ApiService } from '../services/api.service';
import { Authentification } from '../services/authentification';

@Component({
  selector: 'app-ajouter-cargaison',
  templateUrl: './ajouter-cargaison.component.html',
  styleUrls: ['./ajouter-cargaison.component.css']
})
export class AjouterCargaisonComponent implements OnInit {
  reactiveForm_add_cargaison !: FormGroup;
  submitted:boolean=false
  loading_add_cargaison :boolean=false
  constructor(private formBuilder:FormBuilder, public api:ApiService,private http:HttpClient,private router: Router) {
    
   }

  ngOnInit(): void {
    this.init_form()
  }
  init_form() {
      this.reactiveForm_add_cargaison  = this.formBuilder.group({
          
      Id_Cargaison: ["", Validators.required],
      Numero_Declaration: ["", Validators.required],
      Quantite: ["", Validators.required],
      Poids: ["", Validators.required],
      Date_Declaration: ["", Validators.required],
      Numero_Navire: ["", Validators.required],
      Nom_Navire: ["", Validators.required],
      Id_Produit: ["", Validators.required],
      Nature_Produit: ["", Validators.required]
          });
  }

  // acces facile au champs de votre formulaire
  get f(): any { return this.reactiveForm_add_cargaison .controls; }
  // validation du formulaire
  onSubmit_add_cargaison () {
      this.submitted = true;
      console.log(this.reactiveForm_add_cargaison .value)
      // stop here if form is invalid
      if (this.reactiveForm_add_cargaison .invalid) {
          return;
      }
      var cargaison =this.reactiveForm_add_cargaison .value
      this.add_cargaison (cargaison )
  }
  add_cargaison(cargaison: any) {
    this.loading_add_cargaison = true;
    this.api.taf_post("cargaison/add", cargaison, (reponse: any) => {
    this.loading_add_cargaison = false;
    if (reponse.status) {
        console.log("Opération effectuée avec succés sur la table cargaison. Réponse= ", reponse);
        this.onReset_add_cargaison()
        alert("Contenu ajouté avec succés")
        this.router.navigate(['./cargaison'])
    } else {
        console.log("L\'opération sur la table cargaison a échoué. Réponse= ", reponse);
        alert("L'opération a echoué")
    }
}, (error: any) => {
    this.loading_add_cargaison = false;
})
}

  // vider le formulaire
  onReset_add_cargaison () {
  this.submitted = false;
  this.reactiveForm_add_cargaison .reset();
  }

 
}
