import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Bl } from '../Bl';
import { ApiService } from '../services/api.service';
import { Authentification } from '../services/authentification';

@Component({
  selector: 'app-ajouter-bl',
  templateUrl: './ajouter-bl.component.html',
  styleUrls: ['./ajouter-bl.component.css']
})
export class AjouterBLComponent implements OnInit {
reactiveForm_add_bl !: FormGroup;
submitted:boolean=false
loading_add_bl :boolean=false
constructor(private router:Router,private Auth:Authentification,private formBuilder: FormBuilder,public api:ApiService, private http:HttpClient) { }

ngOnInit(): void {
  // this.api.les_bl
    this.init_form()
}
init_form() {
    this.reactiveForm_add_bl  = this.formBuilder.group({
      Id_Bl: ["", Validators.required],
      Date1: [""],
      Numero_Camion: ["", Validators.required],
      Poids_Brut: [""],
      Tare: [""],
      Poids_Net: [""],
      Nbre_Sacs: ["", Validators.required],
      Transporteur: ["", Validators.required],
      Numero_Bl_Tc: ["", Validators.required],
      Destination: ["", Validators.required],
      Nom_Chauffeur: ["", Validators.required],
      Observation: ["", Validators.required],
      Heure_Depart: ["", ],
      Numero_Permis: ["", Validators.required],
      Date_Permis: ["", Validators.required],
      Cumul_Nbre: [""],
      Cumul_Poids: [""]
    });
}

// acces facile au champs de votre formulaire
get f(): any { return this.reactiveForm_add_bl .controls; }
// validation du formulaire
onSubmit_add_bl () {
    this.submitted = true;
    console.log(this.reactiveForm_add_bl .value)
    // stop here if form is invalid
    if (this.reactiveForm_add_bl .invalid) {
        return;
    }
    var bl =this.reactiveForm_add_bl .value
    this.add_bl (bl )
}

add_bl(bl: any) {
  this.loading_add_bl = true;
  this.api.taf_post("bl/add", bl, (reponse: any) => {
  this.loading_add_bl = false;
  if (reponse.status) {
      console.log("Opération effectuée avec succés sur la table bl. Réponse= ", reponse);
      this.onReset_add_bl()
      alert("Contenu ajouté avec succés")
      this.router.navigate(['./bl']);
  } else {
      console.log("L\'opération sur la table bl a échoué. Réponse= ", reponse);
      alert("L'opération a echoué")
  }
}, (error: any) => {
  this.loading_add_bl = false;
})
}

// vider le formulaire
onReset_add_bl () {
this.submitted = false;
this.reactiveForm_add_bl .reset();
}

  AjoutBl() {
    // console.log('Bl', this.bl);
    // this.Auth.ajouterBl(this.bl);
    // this.router.navigate(['./bl']);
  }
}
