import { HttpClient } from '@angular/common/http';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Cargaison } from '../Cargaison';
import { ApiService } from '../services/api.service';
import { Authentification } from '../services/authentification';

@Component({
  selector: 'app-cargaison',
  templateUrl: './cargaison.component.html',
  styleUrls: ['./cargaison.component.css']
})
export class CargaisonComponent implements OnInit {
cargaisons:any[];
filterTerm:any;
  constructor(private http:HttpClient, private router: Router,public api:ApiService) {
    this.cargaisons=[];
   }

  ngOnInit(): void {
  this.get_cargaison()
  }
  get_cargaison(){

    this.api.taf_get("cargaison/get",(reponse:any)=>{
        //when success
        if(reponse.status){
          this.cargaisons=reponse.data
            console.log("Opération effectuée avec succés sur la table cargaison. Réponse= ",reponse);
        }else{
            console.log("L\'opération sur la table cargaison a échoué. Réponse= ",reponse);
        }
    },
    (error:any)=>{
        //when error
        console.log("Erreur inconnue! ",error);
    })
    }
  ajouterCargaison(){
    this.router.navigate(['./ajouterCargaison']);
  }

  delete_cargaison (cargaison : any){
    //transformation des parametres à envoyer
    let formdata = new FormData()
    for (const key in cargaison ) {
        formdata.append(key, cargaison [key])
    }

    let api_url="http://localhost/gs_back_php/cargaison/delete" 
    this.http.post(api_url, formdata).subscribe((reponse: any)=>{
        //when success
        if(reponse.status){
        console.log("Opération effectuée avec succés sur la table cargaison . Réponse = ",reponse)
        this.router.navigate(['./cargaison']);
      }
        else{
        console.log("L\'opération sur la table cargaison  a échoué. Réponse = ",reponse)
        }
    },
    (error: any)=>{
        //when error
        console.log("Erreur inconnue! ",error)
    })
    }
    updateCargaison(Id_Cargaison:any) {
      let url = './updateCargaison/';
      url=url+Id_Cargaison;
      this.router.navigate([url]);
  }
}
