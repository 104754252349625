import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Client } from '../client';
import { ApiService } from '../services/api.service';
import { Authentification } from '../services/authentification';

@Component({
  selector: 'app-client',
  templateUrl: './client.component.html',
  styleUrls: ['./client.component.css']
})
export class ClientComponent implements OnInit {
  clients: any[];
  filterTerm:any;
  constructor(private http:HttpClient, private router: Router,public api:ApiService) { 
    this.clients=[];
  }

  ngOnInit(): void {
    this.get_client()
  }
  ajouterClients(){
    this.router.navigate(['./ajouterClients']);
  }
  
  get_client(){

    this.api.taf_get("client/get",(reponse:any)=>{
        //when success
        if(reponse.status){
          this.clients=reponse.data
            console.log("Opération effectuée avec succés sur la table client. Réponse= ",reponse);
        }else{
            console.log("L\'opération sur la table client a échoué. Réponse= ",reponse);
        }
    },
    (error:any)=>{
        //when error
        console.log("Erreur inconnue! ",error);
    })
    }

    delete_client (client : any){
      //transformation des parametres à envoyer
      let formdata = new FormData()
      for (const key in client ) {
          formdata.append(key, client [key])
      }

      let api_url="http://localhost/gs_back_php/client/delete" 
      this.http.post(api_url, formdata).subscribe((reponse: any)=>{
          //when success
          if(reponse.status){
          console.log("Opération effectuée avec succés sur la table client . Réponse = ",reponse)
          this.router.navigate(['./client']);
        }
          else{
          console.log("L\'opération sur la table client  a échoué. Réponse = ",reponse)
          }
      },
      (error: any)=>{
          //when error
          console.log("Erreur inconnue! ",error)
      })
      }
    updateClient(Id_Client:number) {
      let url = './updateClient/';
      url=url+Id_Client;
      this.router.navigate([url]);
  }
 
}
