  <app-navbar></app-navbar><br/><br/><br/>
  <form (ngSubmit)=" AjoutCamion()"  class="sign-in-form" #CamionForm="ngForm"class="form">
    <div class="form-group">
      <label for="exampleInputIdCamion">idCamion</label>
      <input type="number" name="idCamion"  class="form-control" id="exampleInputidCamion" placeholder="idCamion" [(ngModel)]=camion.idCamion min="1">
    </div>
    <div class="form-group">
      <label for="exampleInputNomNavire">Matriculation</label>
      <input type="text" name ="matriculation" class="form-control" id="exampleInputMatriculation" placeholder="Matriculation"[(ngModel)]=camion.matriculation min="1">
    </div>
    <div class="form-group">
      <label for="exampleInputdateArrivee">Transporteur</label>
      <input type="text" name="transporteur" class="form-control" id="exampleInputtranspoteur" placeholder="Transporteur"[(ngModel)]=camion.transporteur min="1" >
    </div>
    <div class="form-group">
      <label for="exampleInputLargeurNavire">Chauffeur</label>
      <input type="text" name ="chauffeur" class="form-control" id="exampleInputchauffeur" placeholder="Chauffeur" [(ngModel)]=camion.chauffeur min="1" >
    </div>
    <div class="form-group">
      <label for="exampleInputNumeroPermis">Numero Permis</label>
      <input type="number" name ="numeroPermis" class="form-control" id="exampleInputnumeroPermis" placeholder="Numero Permis" [(ngModel)]=camion.numeroPermis  >
    </div>
    <div class="form-group">
      <label for="exampleInputLargeurNavire">Date Permis</label>
      <input type="date" name ="datePermis" class="form-control" id="exampleInputdatePermis" placeholder="Date Permis" [(ngModel)]=camion.datePermis min="1" >
    </div>
   

    <button type="submit" class="btn btn-primary" [disabled]="!CamionForm.form.valid">Ajouter</button>

</form><br/><br/>
<app-footer></app-footer>
