import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../services/api.service';
import { Authentification } from '../services/authentification';
import { HttpClient } from '@angular/common/http';
@Component({
  selector: 'app-update-client',
  templateUrl: './update-client.component.html',
  styleUrls: ['./update-client.component.css']
})
export class UpdateClientComponent implements OnInit {
  reactiveForm_edit_client !: FormGroup;
  submitted: boolean = false
  loading_edit_client: boolean = false
  Id_Client:number=0;
  client_to_edit: any = {}
  constructor(private formBuilder: FormBuilder,private route:Router, public api: ApiService, private http: HttpClient,private a_route:ActivatedRoute) { 
      // récupération des paramètres depuis l'URL
      a_route.params.subscribe((params:any)=>{
      if (params["Id_Client"]) {// si le prametre id_client est présent sur l'URL
          this.Id_Client=params["Id_Client"]
          this.get_client(this.Id_Client)
      } 
      else {// en cas d'absence
          alert("L'URL est incorrect. Il manque l'information 'id_client'")
      }
      })
  }
  ngOnInit(): void {
      this.init_form()
  }
  // initialisation du formulaire
  init_form() {
      this.reactiveForm_edit_client = this.formBuilder.group({
      Id_Client: ["", Validators.required],
      Nom_Client: ["", Validators.required],
      Adresse: ["", Validators.required],
      Numero_Navire: ["", Validators.required],
      Nom_Navire: ["", Validators.required],
      Id_Produit: ["", Validators.required],
      Nature_Produit:["", Validators.required],
      Telephone:["", Validators.required]
      });
  }
  // récupération des details de l'élément à modifier
  get_client(Id_Client: number) {
      this.api.taf_get("client/get?Id_Client=" + Id_Client, (reponse: any) => {
      //when success
      if (reponse.status) {
          console.log("Opération effectuée avec succés sur la table client. Réponse= ", reponse);
          if (reponse.data.length>0) {
          this.client_to_edit = reponse.data[0]
          this.update_form(this.client_to_edit)
          } else {
          alert("Détails de l'élément à modifier introuvable")
          }
      } else {
          console.log("L\'opération sur la table client a échoué. Réponse= ", reponse);
      }
      },
  (error: any) => {
      //when error
      console.log("Erreur inconnue! ", error);
  })
  }
  // mise à jour du formulaire
  update_form(client_to_edit:any) {
      this.reactiveForm_edit_client = this.formBuilder.group({
      Id_Client:[client_to_edit.Id_Client, Validators.required],
      Nom_Client: [client_to_edit.Nom_Client, Validators.required],
      Adresse: [client_to_edit.Adresse, Validators.required],
      Numero_Navire: [client_to_edit.Numero_Navire, Validators.required],
      Nom_Navire: [client_to_edit.Nom_Navire, Validators.required],
      Id_Produit: [client_to_edit.Id_Produit, Validators.required],
      Nature_Produit:[client_to_edit.Nature_Produit, Validators.required],
      Telephone:[client_to_edit.Telephone, Validators.required]
      });
  }
  // acces facile au champs de votre formulaire
  get f(): any { return this.reactiveForm_edit_client.controls; }
  // validation du formulaire
  onSubmit_edit_client() {
      this.submitted = true;
      console.log(this.reactiveForm_edit_client.value)
      // stop here if form is invalid
      if (this.reactiveForm_edit_client.invalid) {
      return;
      }
      var client = this.reactiveForm_edit_client.value
      this.edit_client({
      condition:JSON.stringify({id_client:this.Id_Client}),
      data:JSON.stringify(client)
      })
  }
  // vider le formulaire
  onReset_edit_client() {
      this.submitted = false;
      this.reactiveForm_edit_client.reset();
  }
  edit_client(client: any) {
      this.loading_edit_client = true;
      this.api.taf_post("client/edit", client, (reponse: any) => {
      this.loading_edit_client = false;
      if (reponse.status) {
          console.log("Opération effectuée avec succés sur la table client. Réponse= ", reponse);
          this.onReset_edit_client()
          alert("Contenu modifié avec succés")
          this.route.navigate(['./client'])
      } else {
          console.log("L\'opération sur la table client a échoué. Réponse= ", reponse);
          alert("L'opération a echoué")
      }
      }, (error: any) => {
      this.loading_edit_client = false;
      })
  }

}
