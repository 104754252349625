<app-navbar></app-navbar><br/><br/><br/><br/><br/>
<div class="container"> 
  <form [formGroup]="reactiveForm_edit_magasin " (ngSubmit)="onSubmit_edit_magasin ()" #form_edit_magasin ="ngForm" class="row"> 
    <!-- Id_Magasin field avec un control de validite --> 
    <div class="form-group col-sm-6">
      <label >Id_Magasin</label>
      <input class="form-control" type="text" formControlName="Id_Magasin" placeholder="Id_Magasin" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Id_Magasin.errors }"/>
      <div *ngIf="submitted && f.Id_Magasin.errors" class="invalid-feedback">
        <div *ngIf="f.Id_Magasin.errors.required"> Id_Magasin est obligatoire </div>
      </div>
    </div>
    <!-- Nom_Magasin field avec un control de validite -->
    <div class="form-group col-sm-6">
      <label >Nom_Magasin</label>
      <input class="form-control" type="text" formControlName="Nom_Magasin" placeholder="Nom_Magasin" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Nom_Magasin.errors }"/>
      <div *ngIf="submitted && f.Nom_Magasin.errors" class="invalid-feedback">
        <div *ngIf="f.Nom_Magasin.errors.required"> Nom_Magasin est obligatoire </div>
      </div>
    </div>
    <!-- Adresse field avec un control de validite -->
    <div class="form-group col-sm-6">
      <label >Adresse</label>
      <input class="form-control" type="text" formControlName="Adresse" placeholder="Adresse" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Adresse.errors }"/>
      <div *ngIf="submitted && f.Adresse.errors" class="invalid-feedback">
        <div *ngIf="f.Adresse.errors.required"> Adresse est obligatoire </div>
      </div>
    </div>
    <!-- Contact field avec un control de validite -->
    <div class="form-group col-sm-6">
      <label >Contact</label>
      <input class="form-control" type="text" formControlName="Contact" placeholder="Contact" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Contact.errors }"/>
      <div *ngIf="submitted && f.Contact.errors" class="invalid-feedback">
        <div *ngIf="f.Contact.errors.required"> Contact est obligatoire </div>
      </div>
    </div>
    <!-- Proprietaire field avec un control de validite -->
    <div class="form-group col-sm-6">
      <label >Proprietaire</label>
      <input class="form-control" type="text" formControlName="Proprietaire" placeholder="Proprietaire" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Proprietaire.errors }"/>
      <div *ngIf="submitted && f.Proprietaire.errors" class="invalid-feedback">
        <div *ngIf="f.Proprietaire.errors.required"> Proprietaire est obligatoire </div>
      </div>
    </div>
    <!-- Magasinien field avec un control de validite -->
    <div class="form-group col-sm-6">
      <label >Magasinien</label>
      <input class="form-control" type="text" formControlName="Magasinien" placeholder="Magasinien" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Magasinien.errors }"/>
      <div *ngIf="submitted && f.Magasinien.errors" class="invalid-feedback">
        <div *ngIf="f.Magasinien.errors.required"> Magasinien est obligatoire </div>
      </div>
    </div>
    <!-- Numero_Navire field avec un control de validite -->
    <div class="form-group col-sm-6">
      <label >Numero_Navire</label>
      <input class="form-control" type="text" formControlName="Numero_Navire" placeholder="Numero_Navire" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Numero_Navire.errors }"/>
      <div *ngIf="submitted && f.Numero_Navire.errors" class="invalid-feedback">
        <div *ngIf="f.Numero_Navire.errors.required"> Numero_Navire est obligatoire </div>
      </div>
    </div>
    <!-- Nom_Navire field avec un control de validite -->
    <div class="form-group col-sm-6">
      <label >Nom_Navire</label>
      <input class="form-control" type="text" formControlName="Nom_Navire" placeholder="Nom_Navire" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Nom_Navire.errors }"/>
      <div *ngIf="submitted && f.Nom_Navire.errors" class="invalid-feedback">
        <div *ngIf="f.Nom_Navire.errors.required"> Nom_Navire est obligatoire </div>
      </div>
    </div>
    <!-- Id_Produit field avec un control de validite -->
    <div class="form-group col-sm-6">
      <label >Id_Produit</label>
      <input class="form-control" type="text" formControlName="Id_Produit" placeholder="Id_Produit" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Id_Produit.errors }"/>
      <div *ngIf="submitted && f.Id_Produit.errors" class="invalid-feedback">
        <div *ngIf="f.Id_Produit.errors.required"> Id_Produit est obligatoire </div>
      </div>
    </div>
    <!-- Nature_Produit field avec un control de validite -->
    <div class="form-group col-sm-6"> 
       <label >Nature_Produit</label>
       <input class="form-control" type="text" formControlName="Nature_Produit" placeholder="Nature_Produit" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Nature_Produit.errors }"/>
      <div *ngIf="submitted && f.Nature_Produit.errors" class="invalid-feedback">
      <div *ngIf="f.Nature_Produit.errors.required"> Nature_Produit est obligatoire </div>
    </div>
     </div>
  </form>
  <!-- vous pouvez valider votre formulaire n\'importe ou -->
  <div class="text-center m-2">
    <button type="button" class="btn btn-primary m-2" [disabled]="loading_edit_magasin " (click)="form_edit_magasin .ngSubmit.emit()">{{loading_edit_magasin ?"En cours ...":"Modifier"}}</button>
    <button class="btn btn-secondary m-2" type="reset" (click)="onReset_edit_magasin ()">Vider</button>
  </div> 
  <br/><br/>
</div> 
<app-footer></app-footer>

