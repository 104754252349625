import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../services/api.service';
import { Authentification } from '../services/authentification';
import { HttpClient } from '@angular/common/http';
@Component({
  selector: 'app-update-declaration',
  templateUrl: './update-declaration.component.html',
  styleUrls: ['./update-declaration.component.css']
})
export class UpdateDeclarationComponent implements OnInit {
reactiveForm_edit_declaration !: FormGroup; 
submitted: boolean = false
loading_edit_declaration: boolean = false 
Id_Declaration:number=0; 
declaration_to_edit: any = {} 
constructor(private formBuilder: FormBuilder, public api: ApiService, private http: HttpClient,private a_route:ActivatedRoute,private route:Router) {
 // récupération des paramètres depuis l'URL 
 a_route.params.subscribe((params:any)=>{
 if (params["Id_Declaration"]) {
 // si le prametre id_declaration est présent sur l'URL 
 this.Id_Declaration=params["Id_Declaration"] 
 this.get_declaration(this.Id_Declaration) }
  else {// en cas d'absence 
  alert("L'URL est incorrect. Il manque l'information 'id_declaration'") } }) } 
  ngOnInit(): void { this.init_form() } 
  init_form() { this.reactiveForm_edit_declaration = this.formBuilder.group({
  Id_Declaration: ["", Validators.required],
  Numero_Declaration: ["", Validators.required],
  Id_Cargaison: ["", Validators.required],
  Quantite_Debarquee: ["", Validators.required],
  Poids_Debarque: ["", Validators.required],
  Quantite_Restante: ["", Validators.required],
  Poids_Restant: ["", Validators.required],
  Date_Jour: ["", Validators.required] });
  }
  // récupération des details de l'élément à modifier 
  get_declaration(Id_Declaration: number) {
  this.api.taf_get("declaration/get?Id_Declaration=" + Id_Declaration, (reponse: any) => { 
  //when success 
  if (reponse.status) {
  console.log("Opération effectuée avec succés sur la table declaration. Réponse= ", reponse);
  if (reponse.data.length>0) {
  this.declaration_to_edit = reponse.data[0]
  this.update_form(this.declaration_to_edit) }
  else {
  alert("Détails de l'élément à modifier introuvable") } }
  else {
  console.log("L\'opération sur la table declaration a échoué. Réponse= ", reponse); } }, (error: any) => {
   //when error 
   console.log("Erreur inconnue! ", error); }) }
   // mise à jour du formulaire 
   update_form(declaration_to_edit:any) { this.reactiveForm_edit_declaration = this.formBuilder.group({ 
   Id_Declaration: [declaration_to_edit.Id_Declaration, Validators.required],
   Numero_Declaration: [declaration_to_edit.Numero_Declaration, Validators.required],
   Id_Cargaison: [declaration_to_edit.Id_Cargaison, Validators.required],
   Quantite_Debarquee: [declaration_to_edit.Quantite_Debarquee, Validators.required],
   Poids_Debarque: [declaration_to_edit.Poids_Debarque, Validators.required],
   Quantite_Restante: [declaration_to_edit.Quantite_Restante, Validators.required],
   Poids_Restant: [declaration_to_edit.Poids_Restant, Validators.required],
   Date_Jour: [declaration_to_edit.Date_Jour, Validators.required] });
   }
   // acces facile au champs de votre formulaire 
   get f(): any { 
   return this.reactiveForm_edit_declaration .controls;
   }
   // validation du formulaire 
   onSubmit_edit_declaration() {
   this.submitted = true;
   console.log(this.reactiveForm_edit_declaration.value)
   // stop here if form is invalid 
   if (this.reactiveForm_edit_declaration.invalid) { return; }
   var declaration = this.reactiveForm_edit_declaration.value 
   this.edit_declaration({ condition:JSON.stringify({id_declaration:this.Id_Declaration}), 
   data:JSON.stringify(declaration) }) 
} 
   // vider le formulaire 
onReset_edit_declaration() {
 this.submitted = false; this.reactiveForm_edit_declaration.reset(); 
} 
edit_declaration(declaration: any) { 
	this.loading_edit_declaration = true;
	this.api.taf_post("declaration/edit", declaration, (reponse: any) => {
		this.loading_edit_declaration = false; if (reponse.status) {
			console.log("Opération effectuée avec succés sur la table declaration. Réponse= ", reponse);
			this.onReset_edit_declaration() 
      alert("Contenu modifié avec succés sur la table declaration") 
      this.route.navigate(['./declaration'])
    } 
			else { console.log("L\'opération sur la table declaration a échoué. Réponse= ", reponse); 
			alert("L'opération a echoué") } }, (error: any) => { 
				this.loading_edit_declaration = false; }) }
}
