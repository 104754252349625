import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Route, Router } from '@angular/router';
import { Produit } from '../produit';
import { ApiService } from '../services/api.service';
import { Authentification } from '../services/authentification';

@Component({
  selector: 'app-produit',
  templateUrl: './produit.component.html',
  styleUrls: ['./produit.component.css']
})
export class ProduitComponent implements OnInit {
 produits:any[];
 filterTerm:any;
  constructor(private http:HttpClient,private router:Router,public api:ApiService) {
    this.produits=[];
   }

  ngOnInit(): void {
   this.get_produit()

  }
  get_produit(){
    this.api.taf_get("produit/get",(reponse:any)=>{
        //when success
        if(reponse.status){
          this.produits=reponse.data
            console.log("Opération effectuée avec succés sur la table produit. Réponse= ",reponse);
        }else{
            console.log("L\'opération sur la table produit a échoué. Réponse= ",reponse);
        }
    },
    (error:any)=>{
        //when error
        console.log("Erreur inconnue! ",error);
    })
    }
  ajouterProduits(){
    this.router.navigate(['./ajouterProduits']);
  }
  delete_produit (produit : any){
    //transformation des parametres à envoyer
    let formdata = new FormData()
    for (const key in produit ) {
        formdata.append(key, produit [key])
    }

    let api_url="http://localhost/gs_back_php/produit/delete" 
    this.http.post(api_url, formdata).subscribe((reponse: any)=>{
        //when success
        if(reponse.status){
        console.log("Opération effectuée avec succés sur la table produit . Réponse = ",reponse)
        this.router.navigate(['./produit']);
      }
        else{
        console.log("L\'opération sur la table produit  a échoué. Réponse = ",reponse)
        }
    },
    (error: any)=>{
        //when error
        console.log("Erreur inconnue! ",error)
    })
    }
      // tslint:disable-next-line:typedef
  updateProduit(Id_produit:number) {
    let url = './updateProduit/';
    url=url+Id_produit;
    this.router.navigate([url]);
}



}
