import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Authentification } from '../services/authentification';

@Component({
  selector: 'app-update-camion',
  templateUrl: './update-camion.component.html',
  styleUrls: ['./update-camion.component.css']
})
export class UpdateCamionComponent implements OnInit {
  camion: any;
  cam:any;
  private id: number;
  constructor(private router : Router ,private route: ActivatedRoute,private auth:Authentification) {

   }

  ngOnInit(): void {

    this.camion=+this.route.snapshot.params['camion'];
    console.log(this.camion);

    this.getCamionById(this.camion);

  }
  getCamionById(id:any){
    this.auth.getCamionById(id).subscribe(data=> {
      this.cam = data;
      console.log(this.cam);
  });
}
modifierCamion(){
  console.log("nouveau",this.cam)
    this.auth.modifierCamion(this.cam,this.camion);
   this.router.navigate(['./camion'])
  }

}
