<app-navbar></app-navbar>
<br><br><br><br><br>
<div class="container">
    <form  [formGroup]="reactiveForm_add_acheteur " (ngSubmit)="onSubmit_add_acheteur ()" #form_add_acheteur ="ngForm" class="row">
        
        <!-- Id_Acheteur field avec un control de validite -->
        <div class="form-group col-sm-6">
          <label >Id Acheteur</label>
          <input class="form-control" type="text"  formControlName="Id_Acheteur"  placeholder="Id Acheteur" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Id_Acheteur.errors }"/>
          <div *ngIf="submitted && f.Id_Acheteur.errors" class="invalid-feedback">
              <div *ngIf="f.Id_Acheteur.errors.required"> Id_Acheteur est obligatoire </div>
          </div>
        </div>		
        <!-- Nom_Acheteur field avec un control de validite -->
        <div class="form-group col-sm-6">
          <label >Nom Acheteur</label>
          <input class="form-control" type="text"  formControlName="Nom_Acheteur"  placeholder="Nom Acheteur" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Nom_Acheteur.errors }"/>
          <div *ngIf="submitted && f.Nom_Acheteur.errors" class="invalid-feedback">
              <div *ngIf="f.Nom_Acheteur.errors.required"> Nom_Acheteur est obligatoire </div>
          </div>
        </div>		
        <!-- Bl_Acheteur field avec un control de validite -->
        <div class="form-group col-sm-6">
          <label >Bl Acheteur</label>
          <input class="form-control" type="text"  formControlName="Bl_Acheteur"  placeholder="Bl Acheteur" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Bl_Acheteur.errors }"/>
          <div *ngIf="submitted && f.Bl_Acheteur.errors" class="invalid-feedback">
              <div *ngIf="f.Bl_Acheteur.errors.required"> Bl_Acheteur est obligatoire </div>
          </div>
        </div>		
        <!-- Numero_Navire field avec un control de validite -->
        <div class="form-group col-sm-6">
          <label >Numero Navire</label>
          <input class="form-control" type="text"  formControlName="Numero_Navire"  placeholder="Numero Navire" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Numero_Navire.errors }"/>
          <div *ngIf="submitted && f.Numero_Navire.errors" class="invalid-feedback">
              <div *ngIf="f.Numero_Navire.errors.required"> Numero_Navire est obligatoire </div>
          </div>
        </div>		
        <!-- Nom_Navire field avec un control de validite -->
        <div class="form-group col-sm-6">
          <label >Nom Navire</label>
          <input class="form-control" type="text"  formControlName="Nom_Navire"  placeholder="Nom Navire" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Nom_Navire.errors }"/>
          <div *ngIf="submitted && f.Nom_Navire.errors" class="invalid-feedback">
              <div *ngIf="f.Nom_Navire.errors.required"> Nom_Navire est obligatoire </div>
          </div>
        </div>		
        <!-- Id_Magasin field avec un control de validite -->
        <div class="form-group col-sm-6">
          <label >Id Magasin</label>
          <input class="form-control" type="text"  formControlName="Id_Magasin"  placeholder="Id Magasin" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Id_Magasin.errors }"/>
          <div *ngIf="submitted && f.Id_Magasin.errors" class="invalid-feedback">
              <div *ngIf="f.Id_Magasin.errors.required"> Id_Magasin est obligatoire </div>
          </div>
        </div>		
        <!-- Nom_Magasin field avec un control de validite -->
        <div class="form-group col-sm-6">
          <label >Nom Magasin</label>
          <input class="form-control" type="text"  formControlName="Nom_Magasin"  placeholder="Nom Magasin" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Nom_Magasin.errors }"/>
          <div *ngIf="submitted && f.Nom_Magasin.errors" class="invalid-feedback">
              <div *ngIf="f.Nom_Magasin.errors.required"> Nom_Magasin est obligatoire </div>
          </div>
        </div>		
        <!-- Quantite field avec un control de validite -->
        <div class="form-group col-sm-6">
          <label >Quantite</label>
          <input class="form-control" type="text"  formControlName="Quantite"  placeholder="Quantite" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Quantite.errors }"/>
          <div *ngIf="submitted && f.Quantite.errors" class="invalid-feedback">
              <div *ngIf="f.Quantite.errors.required"> Quantite est obligatoire </div>
          </div>
        </div>		
        <!-- Poids field avec un control de validite -->
        <div class="form-group col-sm-6">
          <label >Poids</label>
          <input class="form-control" type="text"  formControlName="Poids"  placeholder="Poids" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Poids.errors }"/>
          <div *ngIf="submitted && f.Poids.errors" class="invalid-feedback">
              <div *ngIf="f.Poids.errors.required"> Poids est obligatoire </div>
          </div>
        </div>		
        <!-- Variete field avec un control de validite -->
        <div class="form-group col-sm-6">
          <label >Variete</label>
          <input class="form-control" type="text"  formControlName="Variete"  placeholder="Variete" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Variete.errors }"/>
          <div *ngIf="submitted && f.Variete.errors" class="invalid-feedback">
              <div *ngIf="f.Variete.errors.required"> Variete est obligatoire </div>
          </div>
        </div>
</form>
<!-- vous pouvez valider votre formulaire n\'importe ou -->

<div class="text-center m-2">
    <button type="button" class="btn btn-primary m-2" [disabled]="loading_add_acheteur "
        (click)="form_add_acheteur .ngSubmit.emit()">{{loading_add_acheteur ?"En cours ...":"Ajouter"}}</button>
    <button class="btn btn-secondary m-2" type="reset" (click)="onReset_add_acheteur ()">Vider</button>
</div>

</div>
