import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Client } from '../client';
import { ApiService } from '../services/api.service';
import { Authentification } from '../services/authentification';

@Component({
  selector: 'app-ajouter-client',
  templateUrl: './ajouter-client.component.html',
  styleUrls: ['./ajouter-client.component.css']
})
export class AjouterClientComponent implements OnInit {
  reactiveForm_add_client !: FormGroup;
  submitted:boolean=false
  loading_add_client :boolean=false
  constructor(private router:Router,public api:ApiService,private formBuilder:FormBuilder,private http:HttpClient) {
   }

  ngOnInit(): void {
    this.init_form()
  }
  init_form() {
      this.reactiveForm_add_client  = this.formBuilder.group({
          
  Id_Client: ["", Validators.required],
  Nom_Client: ["", Validators.required],
  Adresse: ["", Validators.required],
  Numero_Navire: ["", Validators.required],
  Nom_Navire: ["", Validators.required],
  Id_Produit: ["", Validators.required],
  Nature_Produit: ["", Validators.required],
  Telephone: ["", Validators.required]
      });
  }
 // acces facile au champs de votre formulaire
 get f(): any { return this.reactiveForm_add_client .controls; }
 // validation du formulaire
 onSubmit_add_client () {
     this.submitted = true;
     console.log(this.reactiveForm_add_client .value)
     // stop here if form is invalid
     if (this.reactiveForm_add_client .invalid) {
         return;
     }
     var client =this.reactiveForm_add_client .value
     this.add_client (client )
  }
  add_client(client: any) {
    this.loading_add_client = true;
    this.api.taf_post("client/add", client, (reponse: any) => {
    this.loading_add_client = false;
    if (reponse.status) {
        console.log("Opération effectuée avec succés sur la table client. Réponse= ", reponse);
        this.onReset_add_client()
        alert("Contenu ajouté avec succés")
        this.router.navigate(['./client']);
    } else {
        console.log("L\'opération sur la table client a échoué. Réponse= ", reponse);
        alert("L'opération a echoué")
    }
}, (error: any) => {
    this.loading_add_client = false;
})
}

 // vider le formulaire
  onReset_add_client () {
  this.submitted = false;
  this.reactiveForm_add_client .reset();
  }
 /* AjoutClient() {
    console.log('Client', this.client);
    this.Auth.ajouterClient(this.client);
    this.router.navigate(['./client']);
  }*/

}
