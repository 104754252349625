
<app-navbar></app-navbar>
  <!--<form (ngSubmit)="AjoutNavire()"  class="sign-in-form" #navireForm="ngForm"class="form">
      <div class="form-group">
        <label for="exampleInputNumeroNavire">numeroNavire</label>
        <input type="number" name="numeroNavire"  class="form-control" id="exampleInputNumeroNavire" placeholder="numeroNavire" [(ngModel)]=navire.numeroNavire min="1">
      </div>
      <div class="form-group">
        <label for="exampleInputNomNavire">nomNavire</label>
        <input type="text" name ="nomNavire" class="form-control" id="exampleInputNomNavire" placeholder="nomNavire"[(ngModel)]=navire.nomNavire min="1">
      </div>
      <div class="form-group">
        <label for="exampleInputdateArrivee">dateArrivee</label>
        <input type="date" name="dateArrivee" class="form-control" id="exampleInputdateArrivee" placeholder="dateArrivee"[(ngModel)]=navire.dateArrivee min="1" >
      </div>
      <div class="form-group">
        <label for="exampleInputLargeurNavire">posteAquai</label>
        <input type="text" name ="posteAquai" class="form-control" id="exampleInputposteAquai" placeholder="posteAquai" [(ngModel)]=navire.posteAquai min="1" >
      </div>
      <div class="form-group">
        <label for="exampleInputvolumeNavire">mole</label>
        <input type="text"name ="mole" class="form-control" id="exampleInputmole" placeholder="mole" [(ngModel)]=navire.mole min="1" >
      </div>
      <div class="form-group">
        <label for="exampleInputTirantEauNavire">provenance</label>
        <input type="text" name="provenance" class="form-control" id="exampleInputprovenance" placeholder="provenance" [(ngModel)]=navire.provenance min="1" >
      </div>
        <button type="submit" class="btn btn-primary" [disabled]="!navireForm.form.valid">Ajouter</button>
        
  </form>-->
  <br>
<br>
<br>
<br>
<br>
<div class="container">
  <form  [formGroup]="reactiveForm_add_navire " (ngSubmit)="onSubmit_add_navire ()" #form_add_navire ="ngForm" class="row">
        
    <!-- Numero_Navire field avec un control de validite -->
    <div class="form-group col-sm-6">
      <label >Numero Navire</label>
      <input class="form-control" type="number"  formControlName="Numero_Navire"  placeholder="Numero Navire" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Numero_Navire.errors }"/>
      <div *ngIf="submitted && f.Numero_Navire.errors" class="invalid-feedback">
          <div *ngIf="f.Numero_Navire.errors.required"> Numero_Navire est obligatoire </div>
      </div>
    </div>		
    <!-- Nom_Navire field avec un control de validite -->
    <div class="form-group col-sm-6">
      <label >Nom Navire</label>
      <input class="form-control" type="text"  formControlName="Nom_Navire"  placeholder="Nom Navire" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Nom_Navire.errors }"/>
      <div *ngIf="submitted && f.Nom_Navire.errors" class="invalid-feedback">
          <div *ngIf="f.Nom_Navire.errors.required"> Nom_Navire est obligatoire </div>
      </div>
    </div>		
    <!-- Date_Arrivee field avec un control de validite -->
    <div class="form-group col-sm-6">
      <label >Date Arrivée</label>
      <input class="form-control" type="date"  formControlName="Date_Arrivee"  placeholder="Date Arrivée" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Date_Arrivee.errors }"/>
      <div *ngIf="submitted && f.Date_Arrivee.errors" class="invalid-feedback">
          <div *ngIf="f.Date_Arrivee.errors.required"> Date_Arrivee est obligatoire </div>
      </div>
    </div>		
    <!-- Poste_Aquai field avec un control de validite -->
    <div class="form-group col-sm-6">
      <label >Poste Aquai</label>
      <input class="form-control" type="text"  formControlName="Poste_Aquai"  placeholder="Poste Aquai" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Poste_Aquai.errors }"/>
      <div *ngIf="submitted && f.Poste_Aquai.errors" class="invalid-feedback">
          <div *ngIf="f.Poste_Aquai.errors.required"> Poste_Aquai est obligatoire </div>
      </div>
    </div>		
    <!-- Mole field avec un control de validite -->
    <div class="form-group col-sm-6">
      <label >Mole</label>
      <input class="form-control" type="text"  formControlName="Mole"  placeholder="Mole" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Mole.errors }"/>
      <div *ngIf="submitted && f.Mole.errors" class="invalid-feedback">
          <div *ngIf="f.Mole.errors.required"> Mole est obligatoire </div>
      </div>
    </div>		
    <!-- Provenance field avec un control de validite -->
    <div class="form-group col-sm-6">
      <label >Provenance</label>
      <input class="form-control" type="text"  formControlName="Provenance"  placeholder="Provenance" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Provenance.errors }"/>
      <div *ngIf="submitted && f.Provenance.errors" class="invalid-feedback">
          <div *ngIf="f.Provenance.errors.required"> Provenance est obligatoire </div>
      </div>
    </div>
</form>
<!-- vous pouvez valider votre formulaire n\'importe ou -->

<div class="text-center m-2">
<button type="button" class="btn btn-primary m-2" [disabled]="loading_add_navire "
    (click)="form_add_navire .ngSubmit.emit()">{{loading_add_navire ?"En cours ...":"Ajouter"}}</button>
<button class="btn btn-secondary m-2" type="reset" (click)="onReset_add_navire ()">Vider</button>
</div>
</div>
  <br/><br/>
  <app-footer></app-footer>

