import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../services/api.service';
import { Authentification } from '../services/authentification';
import { HttpClient } from '@angular/common/http';
@Component({
  selector: 'app-update-cargaison',
  templateUrl: './update-cargaison.component.html',
  styleUrls: ['./update-cargaison.component.css']
})
export class UpdateCargaisonComponent implements OnInit {
reactiveForm_edit_cargaison !: FormGroup;
submitted: boolean = false 
loading_edit_cargaison: boolean = false 
Id_Cargaison:number=0; 
cargaison_to_edit: any = {} 
constructor(private formBuilder: FormBuilder, public api: ApiService, private http: HttpClient,private a_route:ActivatedRoute,private route:Router) {
 // récupération des paramètres depuis l'URL 
 a_route.params.subscribe((params:any)=>{
  if (params["Id_Cargaison"]) {
  // si le prametre id_cargaison est présent sur l'URL 
  this.Id_Cargaison=params["Id_Cargaison"]
  this.get_cargaison(this.Id_Cargaison) 
  }
  else {
  // en cas d'absence 
  alert("L'URL est incorrect. Il manque l'information 'id_cargaison'") 
  }
  }) 
  }
   ngOnInit(): void { 
   this.init_form() 
   } 
   init_form() {
    this.reactiveForm_edit_cargaison = this.formBuilder.group({
    Id_Cargaison: ["", Validators.required],
    Numero_Declaration: ["", Validators.required],
    Quantite: ["", Validators.required],
    Poids: ["", Validators.required],
    Date_Declaration: ["", Validators.required],
    Numero_Navire: ["", Validators.required],
    Nom_Navire: ["", Validators.required],
    Id_Produit: ["", Validators.required],
    Nature_Produit: ["", Validators.required] });
    }
    // récupération des details de l'élément à modifier
     get_cargaison(Id_Cargaison: number) {
     this.api.taf_get("cargaison/get?Id_Cargaison=" + Id_Cargaison, (reponse: any) => {
      //when success
      if (reponse.status) {
      console.log("Opération effectuée avec succés sur la table cargaison. Réponse= ", reponse);
      if (reponse.data.length>0) {
      this.cargaison_to_edit = reponse.data[0] 
      this.update_form(this.cargaison_to_edit)
       } 
       else {
       alert("Détails de l'élément à modifier introuvable")
       }
       }
       else {
       console.log("L\'opération sur la table cargaison a échoué. Réponse= ", reponse); }
       },
       (error: any) => {
       //when error
       console.log("Erreur inconnue! ", error); }) 
       } // mise à jour du formulaire
       update_form(cargaison_to_edit:any) {
       this.reactiveForm_edit_cargaison = this.formBuilder.group({
       Id_Cargaison: [cargaison_to_edit.Id_Cargaison, Validators.required],
       Numero_Declaration: [cargaison_to_edit.Numero_Declaration,Validators.required],
       Quantite: [cargaison_to_edit.Quantite, Validators.required],
       Poids: [cargaison_to_edit.Poids, Validators.required],
       Date_Declaration: [cargaison_to_edit.Date_Declaration, Validators.required],
       Numero_Navire: [cargaison_to_edit.Numero_Navire, Validators.required],
       Nom_Navire: [cargaison_to_edit.Nom_Navire, Validators.required],
       Id_Produit: [cargaison_to_edit.Id_Produit, Validators.required],
       Nature_Produit: [cargaison_to_edit.Nature_Produit, Validators.required] }); }
       // acces facile au champs de votre formulaire 
       get f(): any { 
       return this.reactiveForm_edit_cargaison .controls; } 
       // validation du formulaire 
       onSubmit_edit_cargaison() { 
       this.submitted = true; console.log(this.reactiveForm_edit_cargaison.value) 
       // stop here if form is invalid
        if (this.reactiveForm_edit_cargaison.invalid) { return; }
        var cargaison = this.reactiveForm_edit_cargaison.value 
        this.edit_cargaison({ condition:JSON.stringify({id_cargaison:this.Id_Cargaison}), 
        data:JSON.stringify(cargaison) }) } 
        // vider le formulaire 
        onReset_edit_cargaison() { 
        	this.submitted = false; 
        	this.reactiveForm_edit_cargaison.reset(); } 
        	edit_cargaison(cargaison: any) {
        		this.loading_edit_cargaison = true; 
        		this.api.taf_post("cargaison/edit", cargaison, (reponse: any) => {
        			this.loading_edit_cargaison = false; 
        			if (reponse.status) { 
        				console.log("Opération effectuée avec succés sur la table cargaison. Réponse= ", reponse); 
        				this.onReset_edit_cargaison() 
        				alert("Contenu modifié avec succés sur la table cargaison")
                this.route.navigate(['./cargaison'])
               } 
        				else { console.log("L\'opération sur la table cargaison a échoué. Réponse= ", reponse);
        				 alert("L'opération a echoué") } }, (error: any) => {
        				 	this.loading_edit_cargaison = false; }) 
        	}
}
