import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Camion } from '../camion';
import { Authentification } from '../services/authentification';

@Component({
  selector: 'app-camion',
  templateUrl: './camion.component.html',
  styleUrls: ['./camion.component.css']
})
export class CamionComponent implements OnInit {
camions: Camion[];
  constructor(private auth: Authentification, private router: Router) { }

  ngOnInit(): void {
    this.auth.listerCamion().subscribe(data => {
      this.camions = data;
      console.log(this.camions);
    });
  }
  ajouterCamion(){
    this.router.navigate(['./ajouterCamions']);
  }
  
deleteCamion(idCamion:number){
  this.auth.supprimerCamion(idCamion);
  this.router.navigate(['./camion']);
  }
  updateCamion(camion:any) {
    let url = './updateCamion/';
    url=url+camion;
    this.router.navigate([url]);
}

}
