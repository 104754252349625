<app-navbar></app-navbar><br/><br/><br/><br/><br/><br/><br/><br/>

<div class="container">

  <form  [formGroup]="reactiveForm_edit_navire " (ngSubmit)="onSubmit_edit_navire ()" #form_edit_navire ="ngForm" class="row">   
    <!-- Numero_Navire field avec un control de validite -->
    <div class="form-group col-sm-6">
      <label >Numero_Navire</label>
      <input class="form-control" type="text"  formControlName="Numero_Navire"  placeholder="Numero_Navire" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Numero_Navire.errors }"/>
      <div *ngIf="submitted && f.Numero_Navire.errors" class="invalid-feedback">
          <div *ngIf="f.Numero_Navire.errors.required"> Numero_Navire est obligatoire </div>
      </div>
    </div>		
    <!-- Nom_Navire field avec un control de validite -->
    <div class="form-group col-sm-6">
      <label >Nom_Navire</label>
      <input class="form-control" type="text"  formControlName="Nom_Navire"  placeholder="Nom_Navire" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Nom_Navire.errors }"/>
      <div *ngIf="submitted && f.Nom_Navire.errors" class="invalid-feedback">
          <div *ngIf="f.Nom_Navire.errors.required"> Nom_Navire est obligatoire </div>
      </div>
    </div>		
    <!-- Date_Arrivee field avec un control de validite -->
    <div class="form-group col-sm-6">
      <label >Date_Arrivee</label>
      <input class="form-control" type="text"  formControlName="Date_Arrivee"  placeholder="Date_Arrivee" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Date_Arrivee.errors }"/>
      <div *ngIf="submitted && f.Date_Arrivee.errors" class="invalid-feedback">
          <div *ngIf="f.Date_Arrivee.errors.required"> Date_Arrivee est obligatoire </div>
      </div>
    </div>		
    <!-- Poste_Aquai field avec un control de validite -->
    <div class="form-group col-sm-6">
      <label >Poste_Aquai</label>
      <input class="form-control" type="text"  formControlName="Poste_Aquai"  placeholder="Poste_Aquai" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Poste_Aquai.errors }"/>
      <div *ngIf="submitted && f.Poste_Aquai.errors" class="invalid-feedback">
          <div *ngIf="f.Poste_Aquai.errors.required"> Poste_Aquai est obligatoire </div>
      </div>
    </div>		
    <!-- Mole field avec un control de validite -->
    <div class="form-group col-sm-6">
      <label >Mole</label>
      <input class="form-control" type="text"  formControlName="Mole"  placeholder="Mole" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Mole.errors }"/>
      <div *ngIf="submitted && f.Mole.errors" class="invalid-feedback">
          <div *ngIf="f.Mole.errors.required"> Mole est obligatoire </div>
      </div>
    </div>		
    <!-- Provenance field avec un control de validite -->
    <div class="form-group col-sm-6">
      <label >Provenance</label>
      <input class="form-control" type="text"  formControlName="Provenance"  placeholder="Provenance" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Provenance.errors }"/>
      <div *ngIf="submitted && f.Provenance.errors" class="invalid-feedback">
          <div *ngIf="f.Provenance.errors.required"> Provenance est obligatoire </div>
      </div>
    </div>
</form>
<!-- vous pouvez valider votre formulaire n\'importe ou -->
<div class="text-center m-2">
<button type="button" class="btn btn-primary m-2" [disabled]="loading_edit_navire "
    (click)="form_edit_navire .ngSubmit.emit()">{{loading_edit_navire ?"En cours ...":"Modifier"}}</button>
<button class="btn btn-secondary m-2" type="reset" (click)="onReset_edit_navire ()">Vider</button>
</div>

</div>
<br/><br/>
<app-footer></app-footer>



