<app-navbar></app-navbar><br/><br/><br/>
<div class="container">
  <form  [formGroup]="reactiveForm_edit_client " (ngSubmit)="onSubmit_edit_client ()" #form_edit_client ="ngForm" class="row">
        
    <!-- Id_Client field avec un control de validite -->
    <div class="form-group col-sm-6">
      <label >Id_Client</label>
      <input class="form-control"  type="text"  formControlName="Id_Client"  placeholder="Id_Client" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Id_Client.errors }"/>
      <div *ngIf="submitted && f.Id_Client.errors" class="invalid-feedback">
          <div *ngIf="f.Id_Client.errors.required"> Id_Client est obligatoire </div>
      </div>
    </div>		
    <!-- Nom_Client field avec un control de validite -->
    <div class="form-group col-sm-6">
      <label >Nom_Client</label>
      <input class="form-control" type="text"  formControlName="Nom_Client"  placeholder="Nom_Client" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Nom_Client.errors }"/>
      <div *ngIf="submitted && f.Nom_Client.errors" class="invalid-feedback">
          <div *ngIf="f.Nom_Client.errors.required"> Nom_Client est obligatoire </div>
      </div>
    </div>		
    <!-- Adresse field avec un control de validite -->
    <div class="form-group col-sm-6">
      <label >Adresse</label>
      <input class="form-control" type="text"  formControlName="Adresse"  placeholder="Adresse" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Adresse.errors }"/>
      <div *ngIf="submitted && f.Adresse.errors" class="invalid-feedback">
          <div *ngIf="f.Adresse.errors.required"> Adresse est obligatoire </div>
      </div>
    </div>		
    <!-- Numero_Navire field avec un control de validite -->
    <div class="form-group col-sm-6">
      <label >Numero_Navire</label>
      <input class="form-control" type="text"  formControlName="Numero_Navire"  placeholder="Numero_Navire" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Numero_Navire.errors }"/>
      <div *ngIf="submitted && f.Numero_Navire.errors" class="invalid-feedback">
          <div *ngIf="f.Numero_Navire.errors.required"> Numero_Navire est obligatoire </div>
      </div>
    </div>		
    <!-- Nom_Navire field avec un control de validite -->
    <div class="form-group col-sm-6">
      <label >Nom_Navire</label>
      <input class="form-control" type="text"  formControlName="Nom_Navire"  placeholder="Nom_Navire" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Nom_Navire.errors }"/>
      <div *ngIf="submitted && f.Nom_Navire.errors" class="invalid-feedback">
          <div *ngIf="f.Nom_Navire.errors.required"> Nom_Navire est obligatoire </div>
      </div>
    </div>		
    <!-- Id_Produit field avec un control de validite -->
    <div class="form-group col-sm-6">
      <label >Id_Produit</label>
      <input class="form-control" type="text"  formControlName="Id_Produit"  placeholder="Id_Produit" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Id_Produit.errors }"/>
      <div *ngIf="submitted && f.Id_Produit.errors" class="invalid-feedback">
          <div *ngIf="f.Id_Produit.errors.required"> Id_Produit est obligatoire </div>
      </div>
    </div>		
    <!-- Nature_Produit field avec un control de validite -->
    <div class="form-group col-sm-6">
      <label >Nature_Produit</label>
      <input class="form-control" type="text"  formControlName="Nature_Produit"  placeholder="Nature_Produit" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Nature_Produit.errors }"/>
      <div *ngIf="submitted && f.Nature_Produit.errors" class="invalid-feedback">
          <div *ngIf="f.Nature_Produit.errors.required"> Nature_Produit est obligatoire </div>
      </div>
    </div>		
    <!-- Telephone field avec un control de validite -->
    <div class="form-group col-sm-6">
      <label >Telephone</label>
      <input class="form-control" type="text"  formControlName="Telephone"  placeholder="Telephone" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Telephone.errors }"/>
      <div *ngIf="submitted && f.Telephone.errors" class="invalid-feedback">
          <div *ngIf="f.Telephone.errors.required"> Telephone est obligatoire </div>
      </div>
    </div>
</form>
<!-- vous pouvez valider votre formulaire n\'importe ou -->

<div class="text-center m-2">
  <button type="button" class="btn btn-primary m-2" [disabled]="loading_edit_client "
      (click)="form_edit_client.ngSubmit.emit()">{{loading_edit_client ?"En cours ...":"Modifier"}}</button>
  <button class="btn btn-secondary m-2" type="reset" (click)="onReset_edit_client ()">Vider</button>
  </div> 
</div> 

<br/><br/>
<app-footer></app-footer>
