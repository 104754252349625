import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../services/api.service';
import { Authentification } from '../services/authentification';
import { HttpClient } from '@angular/common/http';
@Component({
  selector: 'app-update-bl',
  templateUrl: './update-bl.component.html',
  styleUrls: ['./update-bl.component.css']
})
export class UpdateBLComponent implements OnInit {
  reactiveForm_edit_bl !: FormGroup; 
  submitted: boolean = false 
  loading_edit_bl: boolean = false 
  Id_Bl:number=0; 
  bl_to_edit: any = {} 
  constructor(private formBuilder: FormBuilder, public api: ApiService, private http: HttpClient,private a_route:ActivatedRoute,private route:Router) { // récupération des paramètres depuis l'URL 
    a_route.params.subscribe((params:any)=>{
      if (params["Id_Bl"]) { // si le prametre id_bl est présent sur l'URL 
        this.Id_Bl=params["Id_Bl"] 
        this.get_bl(this.Id_Bl) 
      } 
      else {// en cas d'absence 
        alert("L'URL est incorrect. Il manque l'information 'id_bl'") 
      } 
    })
  }
  ngOnInit(): void { 
   this.init_form()
  } 
  init_form() { 
    this.reactiveForm_edit_bl = this.formBuilder.group({ 
      Id_Bl: ["", Validators.required], 
      Date1: ["", Validators.required], 
      Numero_Camion: ["", Validators.required], 
      Poids_Brut: ["", Validators.required],
      Tare: ["", Validators.required], 
      Poids_Net: ["", Validators.required],
      Nbre_Sacs: ["", Validators.required],
      Transporteur: ["", Validators.required],
      Numero_Bl_Tc: ["", Validators.required], 
      Destination: ["", Validators.required],
      Nom_Chauffeur: ["", Validators.required], 
      Observation: ["", Validators.required], 
      Heure_Depart: ["", Validators.required],
      Numero_Permis: ["", Validators.required],
      Date_Permis: ["", Validators.required],
      Cumul_Nbre: ["", Validators.required],
      Cumul_Poids: ["", Validators.required]
  });
  } 
  // récupération des details de l'élément à modifier 
  get_bl(Id_Bl: number) { 
    this.api.taf_get("bl/get?Id_Bl=" + Id_Bl, (reponse: any) => { //when success 
      if (reponse.status) { 
        console.log("Opération effectuée avec succés sur la table bl. Réponse= ", reponse); 
        if (reponse.data.length>0) { 
         this.bl_to_edit = reponse.data[0] 
         this.update_form(this.bl_to_edit) 
        } 
        else {
         alert("Détails de l'élément à modifier introuvable") 
        }
      } 
      else { 
       console.log("L\'opération sur la table bl a échoué. Réponse= ", reponse);
      } 
    }, 
    (error: any) => { //when error 
      console.log("Erreur inconnue! ", error); }) 
    } 
    // mise à jour du formulaire 
    update_form(bl_to_edit:any) {
      this.reactiveForm_edit_bl = this.formBuilder.group({ 
        Id_Bl: [bl_to_edit.Id_Bl, Validators.required],
        Date1: [bl_to_edit.Date1, Validators.required], 
        Numero_Camion: [bl_to_edit.Numero_Camion, Validators.required], 
        Poids_Brut: [bl_to_edit.Poids_Brut, Validators.required],
        Tare: [bl_to_edit.Tare, Validators.required],
        Poids_Net: [bl_to_edit.Poids_Net, Validators.required],
        Nbre_Sacs: [bl_to_edit.Nbre_Sacs, Validators.required],
        Transporteur: [bl_to_edit.Transporteur,Validators.required],
        Numero_Bl_Tc: [bl_to_edit.Numero_Bl_Tc, Validators.required],
        Destination: [bl_to_edit.Destination, Validators.required],
        Nom_Chauffeur: [bl_to_edit.Nom_Chauffeur, Validators.required],
        Observation: [bl_to_edit.Observation, Validators.required],
        Heure_Depart: [bl_to_edit.Heure_Depart, Validators.required],
        Numero_Permis: [bl_to_edit.Numero_Permis, Validators.required],
        Date_Permis: [bl_to_edit.Date_Permis, Validators.required],
        Cumul_Nbre: [bl_to_edit.Cumul_Nbre, Validators.required],
        Cumul_Poids: [bl_to_edit.Cumul_Poids, Validators.required] }); 
    } 
    // acces facile au champs de votre formulaire 
    get f(): any {
      return this.reactiveForm_edit_bl .controls;
    } 
    // validation du formulaire 
    onSubmit_edit_bl() 
    { 
      this.submitted = true; 
      console.log(this.reactiveForm_edit_bl.value) 
      // stop here if form is invalid 
      if (this.reactiveForm_edit_bl.invalid) { return; } 
      var bl = this.reactiveForm_edit_bl.value 
      this.edit_bl({ condition:JSON.stringify({Id_Bl:this.Id_Bl}), 
      data:JSON.stringify(bl) }) 
    } 
    // vider le formulaire 
    onReset_edit_bl() { 
      this.submitted = false;
      this.reactiveForm_edit_bl.reset(); 
    } 
    edit_bl(bl: any) {
      this.loading_edit_bl = true; 
      this.api.taf_post("bl/edit", bl, (reponse: any) => 
      {
        this.loading_edit_bl = false;
        if (reponse.status)
        { 
          console.log("Opération effectuée avec succés sur la table bl. Réponse= ", reponse);
          this.onReset_edit_bl() 
          alert("continu mofifié avec succés")
          this.route.navigate(['./bl'])
        }
  
        else { 
          console.log("L\'opération sur la table bl a échoué. Réponse= ", reponse);
          alert("L'opération a echoué")
        } 
      },
      (error: any) => 
      {
        this.loading_edit_bl = false; 
      }) 
    }
}
